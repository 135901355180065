export default class Ad {

    constructor(apiKey, accessToken) {
        this.apiKey = apiKey;
        this.accessToken = accessToken;
    }

    listWithVariants(page = 0, size = 30, filters = '') {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/ads/variations?page=' + (page + 1) + '&size=' + size + filters;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    list(page = 0, size = 30, filters = '') {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/ads?page=' + (page + 1) + '&size=' + size + filters;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    create(product) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            product.storeId = this.apiKey;

            const url = '/api/ads';
            const options = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(product)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    retrieve(parentId, marketplace, queryString) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            let url;

            if (queryString.includes('variants=true')) {
                queryString = queryString.replace('&variants=true', '');
                queryString = queryString.replace('?variants=true', '');
                url = '/api/ads/variations/?parent=' + parentId + '&marketplace=' + marketplace + queryString.replace('?', '&');
            } else {
                url = '/api/ads?parent=' + parentId + '&marketplace=' + marketplace + queryString.replace('?', '&');
            }

            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data.docs[0]);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    update(id, product) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/ads/' + id;
            const options = {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(product)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/ads/' + id;
            const options = {
                method: 'DELETE',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });

        })
    }
}