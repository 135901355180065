export default class AttributeMapping {

    constructor(apiKey, accessToken) {
        this.apiKey = apiKey;
        this.accessToken = accessToken;
    }

    list(page = 0, size = 30, filters = '') {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/attribute_mappings?page=' + (page + 1) + '&size=' + size + filters;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    listCategories(page = 0, size = 30, filters = '') {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/attribute_mappings/categories?page=' + (page + 1) + '&size=' + size + filters;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    create(attribute_mappings) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/attribute_mappings';
            const options = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(attribute_mappings)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    read(marketplace, category) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/attribute_mappings/'+marketplace+'/categories/'+category+'/attributes';
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    update(attribute_mappings) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/attribute_mappings';
            const options = {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(attribute_mappings)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    delete(attribute_mappings) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/attribute_mappings';
            const options = {
                method: 'DELETE',
                headers: headers,
                body: JSON.stringify(attribute_mappings)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }
}