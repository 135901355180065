import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Step,
    StepLabel,
    Typography,
    Button,
    Stepper,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    makeStyles,
    Snackbar,
    Box
} from '@material-ui/core'

import {
    Alert
} from '@material-ui/lab'

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';

import DataTable from '../../components/DataTable'
import Loading from '../../components/Loading'
import ConfirmationDialog from '../../components/ConfirmationDialog';
import MarketplaceLogo from '../../components/MarketplaceLogo';

import DialogHistory from './History';
import DialogEditAddress from './EditAddress';
import DialogEditInvoice from './EditInvoice';
import DialogEditShipment from './EditShipment';
import DialogEditDelivery from './EditDelivery';

import OrderService from '../../services/Order';
import LabelService from '../../services/Label';

const useStyles = makeStyles({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
    },
    cardContent: {
        overflow: 'auto'
    }
});

export default function EditOrder() {
    const classes = useStyles();
    const orderId = useParams().id;
    const auth = useAuth();
    const { t } = useTranslation();
    const OrderModel = useMemo(() => new OrderService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const LabelModel = useMemo(() => new LabelService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState({});
    const [dialogHistory, setDialogHistory] = useState({
        data: {},
        open: false
    });
    const [dialogAddress, setDialogAddress] = useState({
        data: {},
        type: '',
        open: false
    });
    const [dialogInvoice, setDialogInvoice] = useState({
        data: {},
        open: false
    });
    const [dialogShipment, setDialogShipment] = useState({
        data: {},
        open: false
    });
    const [dialogDelivery, setDialogDelivery] = useState({
        data: {},
        open: false
    });
    const [remove, setRemove] = useState({
        open: false,
        type: "",
        confirm: () => { }
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const [locale, setLocale] = useState('en-US');

    const retrieveOrder = useCallback((id) => {
        OrderModel.retrieve(id).then(response => {
            setOrder(response);
            setLoading(false);
        })

    }, [OrderModel])

    const saveOrder = useCallback((id, order) => {
        setLoading(true);

        OrderModel.update(order._id, order).then(response => {
            setOrder(response);

            setDialogAddress({
                data: {},
                type: '',
                open: false
            })

            setDialogInvoice({
                data: {},
                open: false
            })

            setDialogShipment({
                data: {},
                open: false
            })

            setDialogDelivery({
                data: {},
                open: false
            })

            setRemove({
                open: false,
                type: "",
                confirm: () => { }
            });

            setLoading(false);

            setResponseStatus({
                open: true,
                severity: "success",
                message: t("Order updated")
            });
        }).catch(error => {
            setLoading(false);

            let prim = true;
            let message = '';

            if (error.errors) {
                Object.values(error.errors).forEach(field => {
                    message += (!prim ? ' | ' : '') + field.message;
                    prim = false;
                });
            } else {
                message = error.message;
            }

            setResponseStatus({
                open: true,
                severity: "error",
                message: message
            });
        })
    }, [OrderModel, t])

    useEffect(() => {
        auth.appTitle(t('Edit order'));
        setLocale(localStorage.getItem('i18nextLng'));
        retrieveOrder(orderId);
    }, [t, retrieveOrder, orderId, auth]);

    const handleChangeAddressField = (event) => {
        const updateAddress = { ...dialogAddress };
        updateAddress.data[event.target.name] = event.target.dataset?.value ? event.target.dataset?.value : event.target.value;
        setDialogAddress(updateAddress);
    }

    const handleConfirmAddress = (event) => {
        const updateOrder = { ...order }

        if (dialogAddress.type === 'billing') {
            updateOrder.billing = { ...dialogAddress.data };
        } else {
            updateOrder.shipping = { ...dialogAddress.data };
        }

        updateOrder.history.push({
            date: new Date().toISOString(),
            message: (dialogAddress.type === 'billing' ? 'Billing' : 'Shipping') + ' address was changed',
            user: auth.user.name
        })

        saveOrder(order._id, updateOrder);
    }

    const handleChangeInvoiceField = (event) => {
        const updateInvoice = { ...dialogInvoice };
        updateInvoice.data[event.target.name] = event.target.dataset?.value ? event.target.dataset?.value : event.target.value;
        setDialogInvoice(updateInvoice);
    }

    const handleConfirmInvoice = (event) => {
        const updateOrder = { ...order }

        updateOrder.history.push({
            date: new Date().toISOString(),
            message: t('Invoice added'),
            user: auth.user.name
        })

        updateOrder.status = 2;
        updateOrder.invoice = dialogInvoice.data;

        delete updateOrder.statusUpdateDate;

        saveOrder(order._id, updateOrder);
    }

    const handleChangeShipmentField = (event) => {
        const updateShipment = { ...dialogShipment };
        updateShipment.data[event.target.name] = event.target.dataset?.value ? event.target.dataset?.value : event.target.value;
        setDialogShipment(updateShipment);
    }

    const handleConfirmShipment = (event) => {
        const updateOrder = { ...order }

        updateOrder.history.push({
            date: new Date().toISOString(),
            message: t('Shipment added'),
            user: auth.user.name
        });

        updateOrder.status = 3;
        updateOrder.sentDate = new Date().toISOString();
        updateOrder.shipments = [];
        updateOrder.shipments.push({ ...dialogShipment.data });

        delete updateOrder.statusUpdateDate;

        saveOrder(order._id, updateOrder);
    }

    const handleChangeDeliveryField = (date, field) => {
        const updateDelivery = { ...dialogDelivery };
        updateDelivery.data[field] = date;
        setDialogDelivery(updateDelivery);
    }

    const handleConfirmDelivery = (event) => {
        const updateOrder = { ...order }

        updateOrder.history.push({
            date: new Date().toISOString(),
            message: t('Marked as delivered'),
            user: auth.user.name
        })

        updateOrder.status = 4;
        updateOrder.deliveryDate = dialogDelivery.data.deliveryDate;

        delete updateOrder.statusUpdateDate;

        saveOrder(order._id, updateOrder);
    }

    const handleDeleteStep = (event) => {
        const updateOrder = { ...order };

        if (order.status === 3) {
            updateOrder.status = 2;
            updateOrder.sentDate = null;
            updateOrder.shipments = [];
            updateOrder.history.push({
                date: new Date().toISOString(),
                message: t('Shipment removed'),
                user: auth.user.name
            })
        } else if (order.status === 2) {
            updateOrder.status = 1;
            updateOrder.invoice = null;
            updateOrder.history.push({
                date: new Date().toISOString(),
                message: t('Invoice removed'),
                user: auth.user.name
            })
        }

        saveOrder(order._id, updateOrder);
    }

    // const handleCancelOrder = (event) => {
    //     const updateOrder = { ...order };
    //     updateOrder.status = 9;
    //     updateOrder.cancelDate = new Date().toISOString();

    //     updateOrder.history.push({
    //         date: new Date().toISOString(),
    //         message: t('Order canceled'),
    //         user: auth.user.name
    //     })

    //     delete updateOrder.statusUpdateDate;

    //     saveOrder(order._id, updateOrder);
    // }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handlePrintLabel = (event) => {
        LabelModel.findBySellerOrderId(order.sellerOrderId).then(labels => {
            let label = '';
            for (let index = 0; index < labels.length; index++) {
                label += labels[index].printCode;
            }
            printLabelPDF(label).then(pdf => {
                saveFile(pdf);
            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
        })
    }

    const printLabelPDF = (label) => {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/pdf");
            headers.append("Content-Type", "application/x-www-form-urlencoded");

            const url = 'https://api.labelary.com/v1/printers/8dpmm/labels/4x6';
            const options = {
                method: 'POST',
                headers: headers,
                body: label
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.blob();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    const saveFile = async (blob) => {
        const a = document.createElement('a');
        a.download = 'label.pdf';
        a.href = URL.createObjectURL(blob);
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };

    const itemColumns = [
        {
            id: 'item',
            label: t('Item'),
            align: 'center'
        },
        {
            id: 'sku',
            label: t('SKU'),
            align: 'center'
        },
        {
            id: 'title',
            label: t('Title'),
            align: 'left'
        },
        {
            id: 'quantity',
            label: t('Quantity'),
            align: 'center',
            format: (quantity) => quantity.toLocaleString(locale, { minimumFractionDigits: 2 })
        },
        {
            id: 'price',
            label: t('Price'),
            align: 'right',
            format: (price) => price.toLocaleString(locale, { minimumFractionDigits: 2 })
        },
        {
            id: 'subtotal',
            label: t('Subtotal'),
            align: 'right',
            type: 'hybrid',
            format: (item) => (item.quantity * item.price).toLocaleString(locale, { minimumFractionDigits: 2 })
        },
        {
            id: 'freight',
            label: t('Freight'),
            align: 'right',
            format: (freight) => freight.toLocaleString(locale, { minimumFractionDigits: 2 })
        },
        {
            id: 'total',
            label: t('Total'),
            align: 'right',
            format: (total) => total.toLocaleString(locale, { minimumFractionDigits: 2 })
        }
    ]

    const itemFooter = [
        {
            column: 'subtotal',
            type: 'sum',
            value: order.orderItems?.map(item => item.quantity * item.price).reduce((sum, i) => sum + i, 0)
        },
        {
            column: 'freight',
            type: 'sum'
        },
        {
            column: 'total',
            type: 'sum'
        }
    ];

    return (!loading ?
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Card className={classes.card} style={{ textAlign: 'center' }}>
                        <CardContent>
                            <MarketplaceLogo marketplace={order.marketplace} />
                            <br /><br />
                            <Typography variant="h6">
                                {order.customOrderId ? order.customOrderId : order.marketplaceOrderId}
                            </Typography>
                            <Typography variant="body2">
                                {t('Your Number')}: {order.sellerOrderId}
                            </Typography>
                        </CardContent>
                        <CardActions dir="rtl">
                            <Button
                                size="small"
                                disabled={!order.history || order.history?.length <= 0}
                                onClick={() => setDialogHistory({
                                    data: order.history,
                                    open: true
                                })}
                            >
                                {t('History Of Changes')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Card className={classes.card}>
                        <CardContent style={{ overflow: 'auto', padding: '0px' }}>
                            <Stepper alternativeLabel activeStep={order.status} style={{ paddingTop: '40px' }}>
                                <Step key={0}>
                                    <StepLabel>
                                        {t('Pending')} <br />
                                        {order.statusUpdateDate.find(x => x.status === 0) &&
                                            <small>
                                                {new Date(order.statusUpdateDate.find(x => x.status === 0)?.updateDate).toLocaleDateString(locale)}
                                                <br />
                                                {new Date(order.statusUpdateDate.find(x => x.status === 0)?.updateDate).toLocaleTimeString(locale)}
                                            </small>
                                        }
                                    </StepLabel>
                                </Step>
                                {!order.statusUpdateDate.find(x => x.status === 9) &&
                                    <Step key={1}>
                                        <StepLabel>
                                            {t('Approved')} <br />
                                            {order.statusUpdateDate.find(x => x.status === 1) &&
                                                <small>
                                                    {new Date(order.statusUpdateDate.find(x => x.status === 1)?.updateDate).toLocaleDateString(locale)}
                                                    <br />
                                                    {new Date(order.statusUpdateDate.find(x => x.status === 1)?.updateDate).toLocaleTimeString(locale)}
                                                </small>
                                            }
                                        </StepLabel>
                                    </Step>
                                }
                                {!order.statusUpdateDate.find(x => x.status === 9) &&
                                    <Step key={2}>
                                        <StepLabel>
                                            {t('Invoiced')} <br />
                                            {order.statusUpdateDate.find(x => x.status === 2) &&
                                                <small>
                                                    {new Date(order.statusUpdateDate.find(x => x.status === 2)?.updateDate).toLocaleDateString(locale)}
                                                    <br />
                                                    {new Date(order.statusUpdateDate.find(x => x.status === 2)?.updateDate).toLocaleTimeString(locale)}
                                                </small>
                                            }
                                        </StepLabel>
                                    </Step>
                                }
                                {!order.statusUpdateDate.find(x => x.status === 9) &&
                                    <Step key={3}>
                                        <StepLabel>
                                            {t('Shipped')} <br />
                                            {order.statusUpdateDate.find(x => x.status === 3) &&
                                                <small>
                                                    {new Date(order.statusUpdateDate.find(x => x.status === 3)?.updateDate).toLocaleDateString(locale)}
                                                    <br />
                                                    {new Date(order.statusUpdateDate.find(x => x.status === 3)?.updateDate).toLocaleTimeString(locale)}
                                                </small>
                                            }
                                        </StepLabel>
                                    </Step>
                                }
                                {!order.statusUpdateDate.find(x => x.status === 9) &&
                                    <Step key={4}>
                                        <StepLabel>
                                            {t('Delivered')} <br />
                                            {order.statusUpdateDate.find(x => x.status === 4) &&
                                                <small>
                                                    {new Date(order.statusUpdateDate.find(x => x.status === 4)?.updateDate).toLocaleDateString(locale)}
                                                    <br />
                                                    {new Date(order.statusUpdateDate.find(x => x.status === 4)?.updateDate).toLocaleTimeString(locale)}
                                                </small>
                                            }
                                        </StepLabel>
                                    </Step>
                                }
                                {order.statusUpdateDate.find(x => x.status === 9) &&
                                    <Step key={9}>
                                        <StepLabel>
                                            {t('Canceled')} <br />
                                            <small>
                                                {new Date(order.statusUpdateDate.find(x => x.status === 9)?.updateDate).toLocaleDateString(locale)}
                                                <br />
                                                {new Date(order.statusUpdateDate.find(x => x.status === 9)?.updateDate).toLocaleTimeString(locale)}
                                            </small>

                                        </StepLabel>
                                    </Step>
                                }
                            </Stepper>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">
                                {t('Billing Address')}
                            </Typography>
                            <br /><br />
                            <Grid container spacing={2} className={classes.cardContent}>
                                <Grid item xs={12} md={4}>{t('Name')}:</Grid>
                                <Grid item xs={12} md={8}>{order.billing?.name}</Grid>
                                <Grid item xs={12} md={4}>{t('VAT Number')}:</Grid>
                                <Grid item xs={12} md={8}>{order.billing?.vatNumber}</Grid>
                                <Grid item xs={12} md={4}>{t('Phone')}:</Grid>
                                <Grid item xs={12} md={8}>{order.billing?.phone}</Grid>
                                <Grid item xs={12} md={4}>{t('EMail')}:</Grid>
                                <Grid item xs={12} md={8}>{order.billing?.email}</Grid>
                                <Grid item xs={12} md={4}>{t('Address')}:</Grid>
                                <Grid item xs={12} md={8}>
                                    <Grid container>
                                        <Grid item xs={12}>{order.billing?.streetName}{order.billing?.streetName && ', '}{order.billing?.streetNumber}</Grid>
                                        <Grid item xs={12}>{order.billing?.streetComplement}</Grid>
                                        <Grid item xs={12}>{order.billing?.district}</Grid>
                                        <Grid item xs={12}>{order.billing?.city}{order.billing?.streetName && '/'}{order.billing?.state}</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex' }}>
                            {(order.status < 2) && auth.user.accessControl["orders-Edit"] === true &&
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setDialogAddress({
                                        data: { ...order.billing },
                                        type: 'billing',
                                        open: true
                                    })}
                                    startIcon={<EditIcon />}
                                    style={{ marginLeft: 'auto' }}
                                >
                                    {t('Edit')}
                                </Button>
                            }
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">
                                {t('Shipping Address')}
                            </Typography>
                            <br /><br />
                            <Grid container spacing={2} className={classes.cardContent}>
                                <Grid item xs={12} md={4}>{t('Name')}:</Grid>
                                <Grid item xs={12} md={8}>{order.shipping?.name}</Grid>
                                <Grid item xs={12} md={4}>{t('Phone')}:</Grid>
                                <Grid item xs={12} md={8}>{order.shipping?.phone}</Grid>
                                <Grid item xs={12} md={4}>{t('EMail')}:</Grid>
                                <Grid item xs={12} md={8}>{order.shipping?.email}</Grid>
                                <Grid item xs={12} md={4}>{t('Address')}:</Grid>
                                <Grid item xs={12} md={8}>
                                    <Grid container>
                                        <Grid item xs={12}>{order.shipping?.streetName}{order.shipping?.streetName && ', '}{order.shipping?.streetNumber}</Grid>
                                        <Grid item xs={12}>{order.shipping?.streetComplement}</Grid>
                                        <Grid item xs={12}>{order.shipping?.district}</Grid>
                                        <Grid item xs={12}>{order.shipping?.city}{order.shipping?.city && '/'}{order.shipping?.state}</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex' }}>
                            {(order.status < 2) && auth.user.accessControl["orders-Edit"] === true &&
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setDialogAddress({
                                        data: order.shipping,
                                        type: 'shipping',
                                        open: true
                                    })}
                                    startIcon={<EditIcon />}
                                    style={{ marginLeft: 'auto' }}
                                >
                                    {t('Edit')}
                                </Button>
                            }
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">
                                {t('Payment Details')}
                            </Typography>
                            <br />
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t('Method')}
                                        </TableCell>
                                        <TableCell align="center">
                                            {t('Installments')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {t('Paid Value')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order.payments?.map((payment, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {payment.method}
                                            </TableCell>
                                            <TableCell align="center">
                                                {payment.installments}
                                            </TableCell>
                                            <TableCell align="right">
                                                {payment.value.toLocaleString(locale, { minimumFractionDigits: 2 })}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">
                                {t('Shipping Info')}
                            </Typography>
                            <br />
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t('Method')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {t('Freight')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key="shippingInfo">
                                        <TableCell>
                                            {order.shippingMethod}
                                        </TableCell>
                                        <TableCell align="right">
                                            {order.freight.toLocaleString(locale, { minimumFractionDigits: 2 })}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                        <CardActions style={{ display: 'flex' }}>
                            {order.status >= 3 && auth.user.accessControl["orders-Print-Labels"] === true &&
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={handlePrintLabel}
                                    startIcon={<PrintIcon />}
                                    style={{ marginLeft: 'auto' }}
                                >
                                    {t('Print label')}
                                </Button>
                            }
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">
                                {t('Invoice')}
                            </Typography>
                            <br /><br />
                            <Grid container spacing={2} className={classes.cardContent}>
                                <Grid item xs={12} md={3}>{t('Serial Number')}:</Grid>
                                <Grid item xs={12} md={9}>{order.invoice?.serial} {order.invoice?.number}</Grid>
                                <Grid item xs={12} md={3}>{t('Serial Key')}:</Grid>
                                <Grid item xs={12} md={9}>{order.invoice?.key}</Grid>
                                <Grid item xs={12} md={3}>{t('Date')}:</Grid>
                                <Grid item xs={12} md={9}>{order.invoice?.date && new Date(order.invoice?.date).toLocaleString(locale)}</Grid>
                            </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex' }}>
                            {(order.status === 1 || order.status === 2) && auth.user.accessControl["orders-Edit"] === true &&
                                <>
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => setDialogInvoice({
                                            data: {
                                                ...order.invoice,
                                                date: new Date().toISOString()
                                            },
                                            open: true
                                        })}
                                        startIcon={order.invoice ? <EditIcon /> : <AddIcon />}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        {order.invoice ? t('Edit') : t('Add')}
                                    </Button>
                                    {order.invoice && auth.user.accessControl["orders-Edit"] === true &&
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => setRemove({
                                                open: true,
                                                title: t('Are you sure you want to remove invoice information?'),
                                                type: 'invoice',
                                                confirm: handleDeleteStep
                                            })}
                                            startIcon={<DeleteIcon />}
                                        >
                                            {t('Remove')}
                                        </Button>
                                    }
                                </>
                            }
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">
                                {t('Shipment')}
                            </Typography>
                            <br /><br />
                            <Grid container spacing={2} className={classes.cardContent}>
                                <Grid item xs={12} md={4}>{t('Method')}:</Grid>
                                <Grid item xs={12} md={8}>{order.shipments[0]?.method}</Grid>
                                <Grid item xs={12} md={4}>{t('Tracking Number')}:</Grid>
                                <Grid item xs={12} md={8}>{order.shipments[0]?.trackingNumber}</Grid>
                                <Grid item xs={12} md={4}>{t('Tracking URL')}:</Grid>
                                <Grid item xs={12} md={8}>{order.shipments[0]?.trackingUrl}</Grid>
                                <Grid item xs={12} md={4}>{t('Date Sent')}:</Grid>
                                <Grid item xs={12} md={8}>{order.sentDate && new Date(order.sentDate).toLocaleString(locale)}</Grid>
                                <Grid item xs={12} md={4}>{t('Date Delivered')}:</Grid>
                                <Grid item xs={12} md={8}>{order.deliveryDate && new Date(order.deliveryDate).toLocaleString(locale)}</Grid>
                            </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex' }}>
                            {(order.status === 2 || order.status === 3) && auth.user.accessControl["orders-Edit"] === true &&
                                <>
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => setDialogShipment({
                                            data: {
                                                ...order.shipments[0],
                                                date: new Date().toISOString()
                                            },
                                            open: true
                                        })}
                                        startIcon={order.shipments.length > 0 ? <EditIcon /> : <AddIcon />}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        {order.shipments.length > 0 ? t('Edit') : t('Add')}
                                    </Button>
                                    {order.shipments.length > 0 && auth.user.accessControl["orders-Edit"] === true &&
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => setRemove({
                                                open: true,
                                                title: t('Are you sure you want to remove shipment information?'),
                                                type: 'shipment',
                                                confirm: handleDeleteStep
                                            })}
                                            startIcon={<DeleteIcon />}
                                        >
                                            {t('Remove')}
                                        </Button>
                                    }
                                </>
                            }
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        columns={itemColumns}
                        docs={order.orderItems}
                        footer={itemFooter}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex">
                        {order.status === 3 && auth.user.accessControl["orders-Edit"] === true &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setDialogDelivery({
                                    data: {},
                                    open: true
                                })}
                                style={{ marginLeft: 'auto', marginRight: '5px' }}
                            >
                                {t('Mark as Delivered')}
                            </Button>
                        }
                        {/* {(order.status < 2) &&
                            <Button
                                variant="contained"
                                color="secondary"
                                width={100}
                                onClick={() => setRemove({
                                    open: true,
                                    title: t('Are you sure you want to cancel the order?'),
                                    type: 'order',
                                    confirm: handleCancelOrder
                                })}
                                style={{ marginLeft: 'auto', marginRight: '5px' }}
                            >
                                {t('Cancel Order')}
                            </Button>
                        } */}
                    </Box>
                </Grid>
            </Grid>
            <DialogHistory
                open={dialogHistory.open}
                title={t('History Of Changes')}
                onClose={() => setDialogHistory({
                    data: {},
                    open: false
                })}
                data={dialogHistory.data}
                confirm={() => setDialogHistory({
                    data: {},
                    open: false
                })}
            />
            <DialogEditAddress
                open={dialogAddress.open}
                type={dialogAddress.type}
                onClose={() => setDialogAddress({
                    data: {},
                    open: false
                })}
                data={dialogAddress.data}
                handleChangeField={handleChangeAddressField}
                confirm={handleConfirmAddress}
                cancel={() => setDialogAddress({
                    data: {},
                    open: false
                })}
            />
            <DialogEditInvoice
                open={dialogInvoice.open}
                onClose={() => setDialogInvoice({
                    data: {},
                    open: false
                })}
                data={dialogInvoice.data}
                handleChangeField={handleChangeInvoiceField}
                confirm={handleConfirmInvoice}
                cancel={() => setDialogInvoice({
                    data: {},
                    open: false
                })}
            />
            <DialogEditShipment
                open={dialogShipment.open}
                onClose={() => setDialogShipment({
                    data: {},
                    open: false
                })}
                data={dialogShipment.data}
                handleChangeField={handleChangeShipmentField}
                confirm={handleConfirmShipment}
                cancel={() => setDialogShipment({
                    data: {},
                    open: false
                })}
            />
            <DialogEditDelivery
                open={dialogDelivery.open}
                onClose={() => setDialogDelivery({
                    data: {},
                    open: false
                })}
                data={dialogDelivery.data}
                handleChangeField={handleChangeDeliveryField}
                confirm={handleConfirmDelivery}
                cancel={() => setDialogDelivery({
                    data: {},
                    open: false
                })}
            />
            <ConfirmationDialog
                title={remove.title}
                confirmTitle={t('Yes')}
                confirm={remove.confirm}
                cancelTitle={t('No')}
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
            >

            </ConfirmationDialog>
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={3000}
                onClose={handleCloseResponseStatus}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </>
        : <Loading open={loading} />
    )
}