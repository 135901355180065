import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText
} from '@material-ui/core';

import NumberFormat from 'react-number-format';
import PropTypes from "prop-types";

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

function CurrencyFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onFocus={e => e.target.select()}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                    },
                });
            }}
            type="text"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            isNumericString
        />
    );
}

CurrencyFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function IntegerFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onFocus={e => e.target.select()}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                    },
                });
            }}
            type="text"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={0}
            isNumericString
        />
    );
}

IntegerFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function Field(props) {
    
    const selectField = (props) => {
        const inputProps = { ...props };
        
        delete inputProps.error;
        delete inputProps.helperText;
        delete inputProps.required;
        delete inputProps.onBlur;
        
        return (
        <FormControl
            margin="dense"
            variant="outlined"
            fullWidth={typeof (props.fullWidth) !== 'undefined' ? props.fullWidth : true}
            onBlur={props.onBlur}
            error={props.error}
            required={!!props.required}
        >
            <InputLabel
                shrink
                style={{
                    marginLeft: '-4px',
                    background: 'white',
                    paddingLeft: '0.3rem',
                    paddingRight: '0.3rem'
                }}
            >
                {props.label}
            </InputLabel>
            <Select
                { ...inputProps}
            >
                {props.options?.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )}

    const multipleSelectField = (props) => (
        <FormControl
            margin="dense"
            variant="outlined"
            fullWidth={typeof (props.fullWidth) !== 'undefined' ? props.fullWidth : true}
        >
            <InputLabel
                shrink
                style={{
                    marginLeft: '-4px',
                    background: 'white',
                    paddingLeft: '0.3rem',
                    paddingRight: '0.3rem'
                }}
            >
                {props.label}
            </InputLabel>
            <Select
                {...props}
                multiple
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 250
                        },
                    },
                }}
            >
                {props.options?.map(option => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={props.value.indexOf(option) > -1} />
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )

    const textField = (props) => (
        <TextField
            {...props}
            type="text"
            margin="dense"
            variant="outlined"
            fullWidth={typeof (props.fullWidth) !== 'undefined' ? props.fullWidth : true}
            InputLabelProps={{
                shrink: true
            }}
            inputProps={{
                readOnly: typeof (props.readOnly) !== 'undefined' ? props.readOnly : false,
                "data-attribute": typeof (props.attribute) !== 'undefined' ? props.attribute : null
            }}
        />
    )

    const fileField = (props) => (
        <TextField
            {...props}
            type="file"
            margin="dense"
            variant="outlined"
            fullWidth={typeof (props.fullWidth) !== 'undefined' ? props.fullWidth : true}
            InputLabelProps={{
                shrink: true
            }}
            inputProps={{
                readOnly: typeof (props.readOnly) !== 'undefined' ? props.readOnly : false,
                "data-attribute": typeof (props.attribute) !== 'undefined' ? props.attribute : null
            }}
        />
    )

    const passwordField = (props) => (
        <>
            <TextField
                {...props}
                type="text"
                margin="dense"
                variant="outlined"
                fullWidth={typeof (props.fullWidth) !== 'undefined' ? props.fullWidth : true}
                InputLabelProps={{
                    shrink: true
                }}
                onChange={(e) => {
                    e.target.type = (e.target.value !== "" ? "password" : "text");
                    props.onChange(e);
                }}
            />
        </>
    )

    const numberField = (props) => {
        return (
            <TextField
                {...props}
                type="text"
                margin="dense"
                variant="outlined"
                fullWidth={typeof (props.fullWidth) !== 'undefined' ? props.fullWidth : true}
                InputLabelProps={{
                    shrink: true
                }}
                onChange={props.onChange}
                InputProps={{
                    readOnly: props.readOnly,
                    inputComponent: props.format === 'float' ? CurrencyFormat : IntegerFormat
                }}
            />
        )
    }

    const dateTimeField = (props) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
                {...props}
                value={typeof (props.value) !== 'undefined' ? props.value : null}
                ampm={false}
                format="DD/MM/YYYY HH:mm"
                inputVariant="outlined"
                onChange={e => props.onChange(e, props.name)}
                showTodayButton
                fullWidth={typeof (props.fullWidth) !== 'undefined' ? props.fullWidth : true}
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    readOnly: props.readOnly
                }}
            />
        </MuiPickersUtilsProvider>
    )

    const dateField = (props) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                {...props}
                value={typeof (props.value) !== 'undefined' ? props.value : null}
                type="text"
                ampm={false}
                format="DD/MM/YYYY"
                inputVariant="outlined"
                onChange={e => props.onChange(e, props.name)}
                showTodayButton
                fullWidth={typeof (props.fullWidth) !== 'undefined' ? props.fullWidth : true}
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </MuiPickersUtilsProvider>
    )

    const getType = (type) => {
        let element;
        switch (type) {
            case 'select': element = selectField(props); break;
            case 'select-multiple': element = multipleSelectField(props); break;
            case 'text': element = textField(props); break;
            case 'password': element = passwordField(props); break;
            case 'number': element = numberField(props); break;
            case 'datetime': element = dateTimeField(props); break;
            case 'date': element = dateField(props); break;
            case 'file': element = fileField(props); break;
            default: element = selectField(props); break;
        }

        return element;
    }

    return getType(props.type);
}

export default Field;