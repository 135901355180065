import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext';

import {
    IconButton,
    Grid,
    Snackbar
} from '@material-ui/core'

import {
    Alert,
} from '@material-ui/lab'

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading'
import MarketplaceLogo from '../../components/MarketplaceLogo';
import { CustomChip } from '../../components/CustomUI';

import ViewIcon from '@material-ui/icons/Visibility';

import DialogHistory from './History';

import OrderService from '../../services/Order';

export default function OrderList() {
    const auth = useAuth();
    const OrderModel = useMemo(() => new OrderService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const { t } = useTranslation();

    const [filters, setFilters] = useState({
        search: '',
        createdFrom: null,
        createdTo: null,
        approvedFrom: null,
        approvedTo: null,
        status: [],
        marketplace: [],
        syncStoreStatus: []
    });
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: ''
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success",
    });

    const [dialogHistory, setDialogHistory] = useState({
        data: {},
        open: false
    });

    const orderList = useCallback((page = 0, size = 30, filters = '') => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        OrderModel.list(page, size, filters).then(result => {
            setData({
                isLoading: false,
                error: null,
                docs: result.docs,
                page: result.page - 1,
                size: result.limit,
                count: result.totalDocs,
                filters: filters
            })
        })

    }, [OrderModel])

    useEffect(() => {
        auth.appTitle(t('Orders'));
        orderList(data.page, data.size, data.filters)
    }, [t, orderList, data.page, data.size, data.filters, auth]);

    const handleKeyUpSearch = (event) => {
        setFilters({
            ...filters,
            search: event.target.value,
        });

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = "";

        if (filters.search !== "") {
            qs += "&$or[0][marketplaceOrderId]=" + filters.search;
            qs += "&$or[1][customOrderId]=" + filters.search;
            qs += "&$or[2][sellerOrderId]=" + filters.search;
            qs += "&$or[3][billing.vatNumber]=" + filters.search;
            qs += "&$or[4][orderItems.sku][$regex]=" + filters.search;
            qs += "&$or[4][orderItems.sku][$options]=gi";
            qs += "&$or[5][billing.name][$regex]=" + filters.search;
            qs += "&$or[5][billing.name][$options]=gi";
            qs += "&$or[6][shipping.name][$regex]=" + filters.search;
            qs += "&$or[6][shipping.name][$options]=gi";
        }

        if (filters.createdFrom) qs += "&marketplaceCreateDate[$gte]=" + filters.createdFrom;
        if (filters.createdTo) qs += "&marketplaceCreateDate[$lte]=" + filters.createdTo;
        if (filters.approvedFrom) qs += "&approvedDate[$gte]=" + filters.approvedFrom;
        if (filters.approvedTo) qs += "&approvedDate[$lte]=" + filters.approvedTo;
        if (filters.marketplace) qs += "&marketplace=" + filters.marketplace;

        for (let index = 0; index < filters.status.length; index++) {
            let status = 0;

            switch (filters.status[index]) {
                case t('Pending'): status = 0; break;
                case t('Approved'): status = 1; break;
                case t('Invoiced'): status = 2; break;
                case t('Shipped'): status = 3; break;
                case t('Delivered'): status = 4; break;
                case t('Canceled'): status = 9; break;
                default: status = 0; break;
            }
            qs += "&status=" + status;
        }

        for (let index = 0; index < filters.syncStoreStatus.length; index++) {
            let status = "PENDING";

            switch (filters.syncStoreStatus[index]) {
                case t('Pending'): status = "PENDING"; break;
                case t('Processing'): status = "PROCESSING"; break;
                case t('Complete'): status = "COMPLETE"; break;
                case t('Error'): status = "ERROR"; break;
                default: status = "PENDING"; break;
            }
            qs += "&syncStoreStatus=" + status;
        }

        setData({
            ...data,
            filters: qs
        });
    }

    const handleDismissFilters = () => {

        document.getElementById('search').value = '';

        setFilters({
            search: '',
            createdFrom: null,
            createdTo: null,
            approvedFrom: null,
            approvedTo: null,
            status: [],
            marketplace: null,
            syncStoreStatus: []
        })
        setData({
            ...data,
            filters: ''
        });
    }

    const filterOptions = [
        {
            type: "text",
            id: "search",
            name: "search",
            label: t('Search'),
            defaultValue: filters.search,
            onKeyUp: handleKeyUpSearch,
            onBlur: (e) => {
                setFilters({ ...filters, search: e.target.value });
            }
        },
        {
            type: "select-multiple",
            dataSelect: "marketplace",
            id: "marketplace",
            name: "marketplace",
            label: t('Marketplace'),
            value: filters.marketplace,
            onChange: (e, marketplace) => {
                setFilters({ ...filters, marketplace: (marketplace ? marketplace.marketplace : null) });
            }
        },
        {
            type: "select-multiple",
            id: "status",
            name: "status",
            label: t('Status'),
            value: filters.status,
            options: [
                t('Pending'),
                t('Approved'),
                t('Invoiced'),
                t('Shipped'),
                t('Delivered'),
                t('Canceled')
            ],
            onChange: (e) => {
                setFilters({ ...filters, status: e.target.value });
            }
        },
        {
            type: "select-multiple",
            id: "syncStoreStatus",
            name: "syncStoreStatus",
            label: t('Sync'),
            value: filters.syncStoreStatus,
            options: [
                t('Pending'),
                t('Complete'),
                t('Error')
            ],
            onChange: (e) => {
                setFilters({ ...filters, syncStoreStatus: e.target.value });
            }
        },
        {
            type: "datetime",
            id: "createdFrom",
            name: "createdFrom",
            label: t('Created from'),
            value: filters.createdFrom,
            onChange: (date) => {
                if (date) setFilters({ ...filters, createdFrom: date.toISOString() });
            }
        },
        {
            type: "datetime",
            id: "createdTo",
            name: "createdTo",
            label: t('Created to'),
            value: filters.createdTo,
            onChange: (date) => {
                if (date) setFilters({ ...filters, createdTo: date.toISOString() });
            }
        },
        {
            type: "datetime",
            id: "approvedFrom",
            name: "approvedFrom",
            label: t('Approved from'),
            value: filters.approvedFrom,
            onChange: (date) => {
                if (date) setFilters({ ...filters, approvedFrom: date.toISOString() });
            }
        },
        {
            type: "datetime",
            id: "approvedTo",
            name: "approvedTo",
            label: t('Approved to'),
            value: filters.approvedTo,
            onChange: (date) => {
                if (date) setFilters({ ...filters, approvedTo: date.toISOString() });
            }
        }
    ];

    const columns = [
        {
            id: 'marketplaceCreateDate',
            label: t('Date'),
            align: 'center',
            type: 'hybrid',
            format: (order) => (
                order.marketplace === 7 ? <>{new Date(order.approvedDate).toLocaleDateString()}<br /><small>{new Date(order.approvedDate).toLocaleTimeString()}</small></>
                : <>{new Date(order.marketplaceCreateDate).toLocaleDateString()}<br /><small>{new Date(order.marketplaceCreateDate).toLocaleTimeString()}</small></>
            )
        },
        {
            id: 'order',
            label: t('Order') + ' #',
            align: 'center',
            type: 'hybrid',
            width: '150',
            format: (order) => (
                <>
                    {order.customOrderId ? order.customOrderId : order.marketplaceOrderId}<br />
                    <small style={{ color: 'grey' }}>{t('Your Number')}: {order.sellerOrderId}<br /></small>
                </>
            )
        },
        {
            id: 'customer',
            label: t('Customer'),
            align: 'left',
            type: 'hybrid',
            format: (order) => (
                <>
                    {order.billing?.name}<br />
                    <small style={{ color: 'grey' }}>{t('Shipping to')}: {order.shipping?.name}</small>
                </>
            )
        },
        {
            id: 'total',
            label: t('Order Total'),
            align: 'right',
            format: (total) => total.toLocaleString(localStorage.getItem('i18nextLng'), { style: 'currency', minimumFractionDigits: 2, currency: 'BRL' })
        },
        {
            id: 'freight',
            label: t('Freight Cost'),
            align: 'right',
            type: 'hybrid',
            format: (order) => (
                <>
                    {order.freight.toLocaleString(localStorage.getItem('i18nextLng'), { style: 'currency', minimumFractionDigits: 2, currency: 'BRL' })}<br />

                </>
            )
        },
        {
            id: 'marketplace',
            label: t('Marketplace'),
            align: 'center',
            type: 'hybrid',
            format: (order) => <MarketplaceLogo marketplace={order.marketplace} sellerName={order.sellerName} />
        },
        {
            id: 'status',
            label: t('Status'),
            align: 'center',
            format: (value) => {
                let status = ''
                switch (value) {
                    case 0: status = 'Pending'; break;
                    case 1: status = 'Approved'; break;
                    case 2: status = 'Invoiced'; break;
                    case 3: status = 'Shipped'; break;
                    case 4: status = 'Delivered'; break;
                    case 9: status = 'Canceled'; break;
                    default: status = 'Pending'; break;
                }

                return t(status);
            }
        },
        {
            id: 'syncStoreStatus',
            label: t('Sync'),
            align: 'center',
            type: 'hybrid',
            format: (order) => {
                let syncStatus;

                if (order.syncStoreStatus) {
                    let color = 'secondary';

                    switch (order.syncStoreStatus) {
                        case 'PENDING': color = 'primary'; break;
                        case 'PROCESSING': color = 'warning'; break;
                        case 'COMPLETE': color = 'success'; break;
                        case 'ERROR': color = 'secondary'; break;
                        default: color = 'primary'; break;
                    }

                    if (order.syncStoreStatus !== 'ERROR') {
                        syncStatus = <CustomChip size="small" customcolor={color} label={t(order.syncStoreStatus)} />
                    } else {
                        syncStatus = <CustomChip size="small" customcolor={color} label={t(order.syncStoreStatus)} onClick={() => setDialogHistory({
                            open: true,
                            data: order.orderErrors
                        })} />
                    }
                } else {
                    if (order.pendingStoreSync === true) {
                        syncStatus = <CustomChip size="small" customcolor="primary" label={t('Pending')} />
                    } else {
                        syncStatus = <CustomChip size="small" customcolor="success" label={t('Synced')} />
                    }
                }

                return syncStatus;
            }
        },
        {
            id: 'id',
            label: t('Actions'),
            align: 'center',
            type: 'hybrid',
            format: (order, index) => (
                <div>
                    <IconButton color="primary" size="small" aria-label={t('View')} title={t('View')} href={'/orders/' + order._id}>
                        <ViewIcon fontSize="small" />
                    </IconButton>
                </div>
            )
        }
    ];

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success",
        });
    };

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            page: 0,
            size: +event.target.value
        })
    }

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <FilterContainer
                    title={t('Filters')}
                    filters={filterOptions}
                    handleApplyFilters={handleApplyFilters}
                    handleDismissFilters={handleDismissFilters}
                />
            </Grid>
            {!isLoading && docs ?
                <Grid item xs={12}>
                    <DataTable
                        columns={columns}
                        docs={docs}
                        page={page}
                        size={size}
                        count={count}
                        error={error}
                        handleChangePage={handleChangePage}
                        handleChangeSize={handleChangeSize}
                    />
                </Grid>
                :
                <Loading open={isLoading} />
            }
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={3000}
                onClose={handleCloseResponseStatus}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseResponseStatus}
                    severity={responseStatus.severity}
                >
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <DialogHistory
                open={dialogHistory.open}
                title={t('Sync errors')}
                onClose={() => setDialogHistory({
                    data: {},
                    open: false
                })}
                data={dialogHistory.data}
                confirm={() => setDialogHistory({
                    data: {},
                    open: false
                })}
            />
        </Grid>
    );
}