import React, { useState } from 'react';
import { useAuth } from '../AuthContext';

import {
    Container,
    Grid,
    Link,
    Typography,
    TextField,
    Button,
    Box,
    Paper,
    makeStyles,
    CircularProgress,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        left: '0',
        top: '0',
        right: '0',
        bottom: '0'
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        backgroundColor: '#ffffff9c'
    },
    title: {
        fontSize: '2rem',
        fontWeight: 600,
        marginBottom: theme.spacing(1)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    login: {
        marginTop: theme.spacing(3),
    },
    register: {
        color: 'white',
        background: theme.palette.success.main,
        margin: theme.spacing(1, 0),
        '&:hover': {
            background: theme.palette.success.dark
        },
    }
}));


function Login() {
    const classes = useStyles();
    const { t } = useTranslation();

    const { loading, login } = useAuth();

    const [user, setUser] = useState({
        email: '',
        password: ''
    })

    const [errors, setErrors] = useState({});
    const [termsAndConditions, setTermsAndConditions] = useState(false);

    const handleChangeField = (event) => {

        //Alteração dos valores
        const updatedUser = { ...user };
        updatedUser[event.target.name] = event.target.value;

        fieldValidation(event);
        setUser(updatedUser)
    }

    const fieldValidation = (event) => {
        //Validação dos campos
        const updatedErrors = { ...errors };
        delete updatedErrors[event.target.name];

        const validation = event.target.dataset.validation;
        const match = new RegExp(event.target.dataset.match);
        const message = event.target.dataset.message;

        if (event.target.required && event.target.value.trim() === '') {
            updatedErrors[event.target.name] = t("Required field");
        }

        switch (validation) {
            case "pattern":
                if (!match.test(event.target.value)) updatedErrors[event.target.name] = message;
                break;

            default:
                break;
        }

        setErrors(updatedErrors);
    }

    const handleLogin = (event) => {
        event.preventDefault();
        login(user).catch(error => {
            setErrors({
                login: t('Invalid email/password. Please try again')
            })
        })
    }

    return (
        <Container maxWidth="sm" classes={{ root: classes.container }}>
            <div>
                <Paper classes={{ root: classes.paper }}>
                    <Typography variant="h4" align="left" classes={{ root: classes.title }}>MARKETPLACES</Typography>
                    <Typography variant="h5" align="center">
                        {t("Sell your products with the tips of your fingers")}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            name="email"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("Email address")}
                            autoFocus
                            value={user.user}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.email}
                            helperText={errors?.email}
                        />
                        <TextField
                            type="password"
                            name="password"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("Password")}
                            value={user.password}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.password}
                            helperText={errors?.password}
                        />
                        {errors.login &&
                            <Box p={1}>
                                <small style={{ color: '#DD0000' }}>{errors.login}</small>
                            </Box>
                        }
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={termsAndConditions}
                                    onChange={() => { setTermsAndConditions(!termsAndConditions) }}
                                    name="termsAndConditions"
                                    color="primary"
                                />
                            }
                            label={<>{t("I declare that I have read and accept the")} <Link href="/terms-and-conditions" target='_blank'>{t("terms and conditions of use")}</Link></>}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.login}
                            onClick={handleLogin}
                            type="submit"
                            disabled={loading || !termsAndConditions}
                        >
                            {loading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                t("Sign In")
                            )}
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            className={classes.register}
                            href="/register"
                            disabled="disabled"
                        >
                            {t("Sign Up")}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Typography align="center">
                                    <Link href="/forgot" variant="body2">
                                        {t("Forgot password?")}
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Box mt={2} textAlign="center">
                    <Typography variant="body1" color="textSecondary" align="center">
                        from
                    </Typography>
                </Box>
                <Box mt={1} textAlign="center">
                    <Link href="https://www.compassio.com.br" target="_blank" variant="body2">
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Compassio" height="30" />
                    </Link>
                </Box>
            </div>
        </Container >
    )
}

export default Login;