import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    TextField,
    CircularProgress
} from "@material-ui/core";

import {
    Autocomplete
} from "@material-ui/lab";

import StoreService from "../../services/Store";
import MarketplaceService from "../../services/Marketplace";
import ProductService from "../../services/Product";

import { useAuth } from "../../AuthContext";

function MarketplaceSelect(props) {
    const auth = useAuth();
    const MarketplaceModel = useMemo(() => new MarketplaceService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);

    const { t } = useTranslation();

    const [marketplaces, setMarketplaces] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListMarketplaces = (event) => {
        let filters = "";

        if (event.target.value) {
            filters += "&$or[0][name][$regex]=" + event.target.value;
            filters += "&$or[0][name][$options]=gi";
        }

        setMarketplaces({ ...marketplaces, loading: true });

        MarketplaceModel.list(filters).then((response) => {
            setMarketplaces({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id={props.id}
            fullWidth
            blurOnSelect={true}
            style={props.style}
            noOptionsText={t("No records found")}
            open={marketplaces.open}
            onOpen={handleListMarketplaces}
            onClose={() => {
                setMarketplaces({ ...marketplaces, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListMarketplaces}
            onBlur={props.onBlur}
            getOptionSelected={(option, value) => option.marketplace === value.marketplace}
            getOptionLabel={(option) => option.name}
            filterOptions={x => x}
            options={marketplaces.options}
            loading={marketplaces.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={props.name}
                    required={props.required}
                    error={props.error}
                    helperText={props.helperText}
                    label={props.label}
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {marketplaces.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function AttributeSelect(props) {
    const { t } = useTranslation();
    const attributeList = [
        { title: "Sem mapeamento", name: "" },
        { title: "SKU", name: "sku" },
        { title: "Título", name: "title" },
        { title: "Descrição", name: "description" },
        { title: "Identificador", name: "customId" },
        { title: "Marca", name: "brand" },
        { title: "Ativo", name: "active" },
        { title: "Largura", name: "width" },
        { title: "Altura", name: "height" },
        { title: "Comprimento", name: "length" },
        { title: "Peso", name: "weight" },
        { title: "Unidades por pacote", name: "unitsPerPackage" },
        { title: "EAN", name: "ean" },
        { title: "Estoque", name: "stock" },
        { title: "Custo", name: "cost" },
        { title: "Preço", name: "price" },
        { title: "Preço de Oferta", name: "salePrice" },
        { title: "Início Oferta", name: "saleDateStart" },
        { title: "Fim Oferta", name: "saleDateEnd" }
    ];

    const [attributes, setAttributes] = useState({
        open: false,
        options: attributeList,
        loading: false
    });

    const handleListAttributes = (event) => {

        const updatedAttributes = { ...attributes };
        updatedAttributes.options = attributeList;

        if (event.target.value) {
            updatedAttributes.options = [];
            attributes.options.filter(x => x.title.toLowerCase().includes(event.target.value.toLowerCase()) || x.name.toLowerCase().includes(event.target.value.toLowerCase())).map(x => updatedAttributes.options.push(x));
        }

        setAttributes({ ...updatedAttributes, open: true });
    }

    return (
        <Autocomplete
            id={props.id}
            fullWidth
            blurOnSelect={true}
            style={props.style}
            noOptionsText={t("No records found")}
            open={attributes.open}
            onOpen={handleListAttributes}
            onClose={() => {
                setAttributes({ ...attributes, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListAttributes}
            onBlur={props.onBlur}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.title}
            filterOptions={x => x}
            options={attributes.options}
            loading={attributes.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={props.name}
                    required={props.required}
                    error={props.error}
                    helperText={props.helperText}
                    label={props.label}
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {attributes.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function SellerSelect(props) {
    const auth = useAuth();
    const StoreModel = useMemo(() => new StoreService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);

    const { t } = useTranslation();

    const [sellers, setSellers] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListSellers = (event) => {

        setSellers({ ...sellers, loading: true });

        StoreModel.retrieve(auth.user.stores[auth.user.selectedStore].storeId).then((response) => {
            const sellerList = response.marketplaceSettings.filter(x => x.marketplace === props.marketplace);
            setSellers({
                open: true,
                options: sellerList,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id={props.id}
            fullWidth
            blurOnSelect={true}
            style={props.style}
            noOptionsText={t("No records found")}
            open={sellers.open}
            onOpen={handleListSellers}
            onClose={() => {
                setSellers({ ...sellers, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListSellers}
            onBlur={props.onBlur}
            getOptionSelected={(option, value) => option.credentials.sellerId === value.credentials.sellerId}
            getOptionLabel={(option) => option.credentials.sellerName}
            filterOptions={x => x}
            options={sellers.options}
            loading={sellers.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={props.name}
                    required={props.required}
                    error={props.error}
                    helperText={props.helperText}
                    label={props.label}
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {sellers.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function ProductSelect(props) {
    const auth = useAuth();
    const ProductModel = useMemo(() => new ProductService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);

    const { t } = useTranslation();

    const [products, setProducts] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListProducts = (event) => {
        let filters = "&active=true";

        if (event.target.value) {
            filters += "&$or[0][title][$regex]=" + event.target.value;
            filters += "&$or[0][title][$options]=gi";
            filters += "&$or[1][sku][$regex]=" + event.target.value;
            filters += "&$or[1][sku][$options]=gi";
            filters += "&$or[2][customId][$regex]=" + event.target.value;
            filters += "&$or[2][customId][$options]=gi";
        }

        setProducts({ ...products, loading: true });

        ProductModel.list(0, 30, filters).then((response) => {
            setProducts({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id={props.id}
            fullWidth
            blurOnSelect={true}
            style={props.style}
            noOptionsText={t("No records found")}
            open={products.open}
            onOpen={handleListProducts}
            onClose={() => {
                setProducts({ ...products, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListProducts}
            onBlur={props.onBlur}
            getOptionSelected={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.title}
            filterOptions={x => x}
            options={products.options}
            loading={products.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={props.name}
                    required={props.required}
                    error={props.error}
                    helperText={props.helperText}
                    label={props.label}
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {products.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

export { MarketplaceSelect, AttributeSelect, ProductSelect, SellerSelect };