export default class Product {

    constructor(apiKey, accessToken) {
        this.apiKey = apiKey;
        this.accessToken = accessToken;
    }

    list(page = 0, size = 30, filters = '') {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/products?page=' + (page + 1) + '&size=' + size + filters;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    listMatches(productId) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/products/' + productId + '/matches';
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    create(product) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/products';
            const options = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(product)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    import(file) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const formData = new FormData();
            formData.append('filename', file);

            const url = '/api/products/import';
            const options = {
                method: 'POST',
                headers: headers,
                body: formData
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                    console.log(error);
                });
        })
    }

    retrieve(id) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/products/' + id;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    update(id, product) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/products/' + id;
            const options = {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(product)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/products/' + id;
            const options = {
                method: 'DELETE',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }
}