import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import UserService from './services/User';

const AuthContext = createContext({
	authenticated: false,
	user: null,
	loading: true,
	title: '',
	login: async () => { },
	logout: () => { }
});

const AuthProvider = (props) => {
	const UserModel = useMemo(() => new UserService(), []);
	const [user, setUser] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState('');

	useEffect(() => {
		function loadStorageData() {
			return new Promise((resolve, reject) => {
				const storedUser = JSON.parse(localStorage.getItem("@marketplacesCompassio/user"));

				if (storedUser) {
					if (!storedUser.user?.accessControl && !storedUser.accessControl) {
						logout();
					}
					setIsLoggedIn(true);
					setUser(storedUser);
					UserModel.apiKey = storedUser.stores[storedUser.selectedStore].storeId;
					UserModel.accessToken = storedUser.token;
					UserModel.refreshToken(storedUser._id).then((result) => {
						result.selectedStore = storedUser.selectedStore;
						localStorage.setItem("@marketplacesCompassio/user", JSON.stringify(result));
						setIsLoggedIn(true);
						setUser(result);
						setLoading(false);
						resolve();
					}).catch(error => {
						console.log(error);
						setIsLoggedIn(false);
						setLoading(false);
					})
				}
			})
		}

		loadStorageData();
	}, [UserModel])

	function login(userLogin) {
		return new Promise((resolve, reject) => {
			setLoading(true);
			UserModel.login(userLogin).then(response => {
				response.selectedStore = 0;
				localStorage.setItem("@marketplacesCompassio/user", JSON.stringify(response));
				if (response.accessControl && JSON.stringify(response.accessControl) !== '{}') {
					setUser(response);
					setIsLoggedIn(true);
					setLoading(false);
					resolve(true);
				} else {
					setLoading(false);
					reject({ message: "Access Control configurations not found" });
				}
			}).catch(error => {
				setLoading(false);
				reject(error);
			})
		})
	}

	function logout() {
		setIsLoggedIn(false);
		setUser(null);
		localStorage.removeItem("@marketplacesCompassio/user");
		window.location.reload();
	}

	function appTitle(title) {
		setTitle(title);
	}

	return (
		<AuthContext.Provider value={{ authenticated: isLoggedIn, user, loading, appTitle, title, login, logout }}>
			{props.children}
		</AuthContext.Provider>
	)
}

function useAuth() {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider.');
	}

	return context;
}

export { AuthProvider, useAuth };