import React, { useEffect, useState } from 'react';
import UserService from '../services/User';
import StoreService from '../services/Store';

import {
    Container,
    Grid,
    Link,
    Typography,
    TextField,
    Button,
    Box,
    Paper,
    makeStyles,
    CircularProgress
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        left: '0',
        top: '0',
        right: '0',
        bottom: '0'
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        backgroundColor: '#ffffff9c'
    },
    title: {
        fontSize: '2rem',
        fontWeight: 600,
        marginBottom: theme.spacing(1)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    signup: {
        margin: theme.spacing(1, 0),
    },
    agreement: {
        fontSize: '0.8rem'
    }
}));


function Register() {
    const classes = useStyles();
    const { t } = useTranslation();
    const User = new UserService();
    const Store = new StoreService();
    const token = new URLSearchParams(window.location.search).get("token")

    const [store, setStore] = useState({
        nickname: "",
        fullname: "",
        vatNumber: "",
        address: {
            street: "",
            number: "",
            complement: "",
            district: "",
            city: "",
            state: "",
            country: "",
            zipCode: ""
        }
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChangeField = (event) => {
        
        const updatedStore = { ...store };
        const field = event.target.name.split('.');

        if(field.length > 1){
            updatedStore[field[0]][field[1]] = event.target.value;
        } else {
            updatedStore[event.target.name] = event.target.value;
        }

        fieldValidation(event);
        setStore(updatedStore)
    }

    const fieldValidation = (event) => {
        //Validação dos campos
        const updatedErrors = { ...errors };
        delete updatedErrors[event.target.name];

        const validation = event.target.dataset.validation;
        const match = new RegExp(event.target.dataset.match);
        const message = event.target.dataset.message;

        if (event.target.required && event.target.value.trim() === '') {
            updatedErrors[event.target.name] = "Campo obrigatório";
        }

        switch (validation) {
            case "pattern":
                if (!match.test(event.target.value)) updatedErrors[event.target.name] = message;
                break;

            default:
                break;
        }

        setErrors(updatedErrors);
    }

    const createStore = (event) => {
        setLoading(true);

        Store.accessToken = token;
        Store.create(store).then(result => {
            getAuthorization(result);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }

    const getAuthorization = (store) => {
        User.apiKey = store._id;
        User.accessToken = token;
        User.authorize().then(result => {
            setLoading(false);
            window.location.href = '/';
        }).catch(error => {
            console.log(error)
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!token) window.location.href = '/register';
    })

    return (
        <Container maxWidth="sm" classes={{ root: classes.container }}>
            <div>
                <Paper classes={{ root: classes.paper }}>
                    <Typography variant="h4" classes={{ root: classes.title }}>E-COMMERCE</Typography>
                    <Typography variant="h5">
                        {t("Sell your products with the tips of your fingers")}
                    </Typography>
                    <Box mt={6} mb={3}>
                        <Typography variant="body2">
                            {t("To finish your registration, please fill in the form bellow")}
                        </Typography>
                    </Box>
                    <form className={classes.form} noValidate autoComplete="none">
                        <TextField
                            name="nickname"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("Nickname")}
                            autoFocus
                            value={store.nickname}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.nickname}
                            helperText={errors?.nickname}
                        />
                        <TextField
                            name="fullname"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("Full Name")}
                            value={store.fullname}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.fullname}
                            helperText={errors?.fullname}
                        />
                        <TextField
                            name="vatnumber"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("VAT Number")}
                            value={store.vatnumber}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.vatnumber}
                            helperText={errors?.vatnumber}
                        />
                        <TextField
                            name="address.street"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("Street Address")}
                            value={store.address?.street}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.address?.street}
                            helperText={errors?.address?.street}
                        />
                        <TextField
                            name="address.number"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("Street Number")}
                            value={store.address?.number}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.address?.number}
                            helperText={errors?.address?.number}
                        />
                        <TextField
                            name="address.complement"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("Street Complement")}
                            value={store.address?.complement}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.address?.complement}
                            helperText={errors?.address?.complement}
                        />
                        <TextField
                            name="address.district"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("District")}
                            value={store.address?.district}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.address?.district}
                            helperText={errors?.address?.district}
                        />
                        <TextField
                            name="address.city"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("City")}
                            value={store.address?.city}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.address?.city}
                            helperText={errors?.address?.city}
                        />
                        <TextField
                            name="address.state"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("State")}
                            value={store.address?.state}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.address?.state}
                            helperText={errors?.address?.state}
                        />
                        <TextField
                            name="address.country"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("Country")}
                            value={store.address?.country}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.address?.country}
                            helperText={errors?.address?.country}
                        />
                        <TextField
                            name="address.zipCode"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t("ZIP Code")}
                            value={store.address?.zipCode}
                            onBlur={fieldValidation}
                            onChange={handleChangeField}
                            error={!!errors?.address?.zipCode}
                            helperText={errors?.address?.zipCode}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.signup}
                            disabled={store.fullname === '' || store.nickname === '' || JSON.stringify(errors) !== "{}"}
                            onClick={createStore}
                        >
                            {loading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                t("Confirm registration")
                            )}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Typography align="center">
                                    <Link href="/" variant="body2">
                                        {t("Already have an account? Sign in")}
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Box mt={1} textAlign="center">
                    <Typography variant="body1" color="textSecondary" align="center">
                        from
                </Typography>
                </Box>
                <Box mt={1} textAlign="center">
                    <Link href="https://www.compassio.com.br" target="_blank" variant="body2">
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Compassio" height="30" />
                    </Link>
                </Box>
            </div>
        </Container >
    )
}

export default Register;