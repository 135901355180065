import React from 'react';
import {
	ThemeProvider,
	CssBaseline
} from '@material-ui/core'

import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faBoxOpen,
	faGauge,
	faBoxesStacked,
	faNewspaper,
	faCartShopping,
	faFileLines,
	faTags,
	faGear,
	faUsers,
	faStore,
	faList,
	faObjectGroup
} from '@fortawesome/free-solid-svg-icons';

import { createTheme } from '@material-ui/core';

import { ptBR } from '@material-ui/core/locale';

import { AuthProvider } from './AuthContext';
import Routes from './Routes';

library.add(faBoxOpen,
	faGauge,
	faBoxesStacked,
	faNewspaper,
	faCartShopping,
	faFileLines,
	faTags,
	faGear,
	faUsers,
	faStore,
	faList,
	faObjectGroup
)

const lightMode = createTheme({
	typography: {
		fontFamily: '"Quicksand", sans-serif !important',
		fontSize: 14,
		fontWeightLight: 400,
		fontWeightRegular: 500,
		fontWeightMedium: 600,
		fontWeightBold: 700,
	},
	palette: {
		primary: {
			main: '#2e86de'
		},
		secondary: {
			contrastText: '#fff',
			main: '#ee5253'
		},
		warning: {
			contrastText: '#000',
			main: '#feca57'
		},
		success: {
			contrastText: '#fff',
			main: '#10ac84'
		},
		active: {
			contrastText: '#fff',
			main: '#1dd1a1'
		},
		inactive: {
			contrastText: '#fff',
			main: '#ee5253'
		},
		background: {
			default: 'rgb(232, 254, 250, 0.7)'
		},
		header: {
			contrastText: '#fff',
			main: '#008261'
		},
		tonalOffset: 0.2
	},
	overrides: {
		MuiGrid: {
			item: {
				alignItems: 'center'
			}
		},
		MuiTableCell: {
			root: {
				fontSize: 13
			},
			sizeSmall: {
				padding: 14
			}
		},
		MuiTab: {
			root: {
				textTransform: 'none'
			}
		},
		MuiInputBase: {
			input: {
				fontSize: 14
			}
		},
		MuiButton: {
			root: {
				textTransform: 'none'
			}
		},
		MuiTypography: {
			body1: {
				fontSize: 14
			},
			h6: {
				fontSize: 16
			}
		},
		MuiCssBaseline: {
			'@global': {
				html: {
					height: '100%',
				},
				body: {
					backgroundColor: 'rgb(232, 254, 250, 0.7)',
					height: '100%'
				},
				ul: {
					listStyle: 'none'
				}
			}
		},
		MuiDropzonePreviewList: {
			removeButton: {
				top: '10px',
				right: '50px'
			}
		},
		MuiAccordionSummary: {
			content: {
				alignItems: 'center'
			}
		}
	}
}, ptBR);

const App = () => {
	return (
		<AuthProvider>
			<ThemeProvider theme={lightMode}>
				<CssBaseline />
				<Routes />
			</ThemeProvider>
		</AuthProvider>
	)
}

export default App;
