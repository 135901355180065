import React from 'react';

import {
    Container,
    Link,
    Typography,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        left: '0',
        top: '40px',
        right: '0',
        bottom: '40px'
    },
    body: {
        lineHeight: '2rem',
        fontSize: '1rem'
    }
}));


function TermsAndConditions() {
    const classes = useStyles();

    return (
        <Container classes={{ root: classes.container }}>
            <Typography variant="h6" align="center">TERMOS E CONDIÇÕES DE USO DO MARKETPLACE COMPASSIO</Typography>
            <br /><br />
            <Typography variant="body1" align="justify" className={classes.body} style={{paddingBottom: '40px'}}>
                <p>Bem-vindo ao Marketplace Compassio!</p>
                <p>Ao acessar e utilizar nosso site (marketplaces.compassio.com.br), você concorda em cumprir e aceitar os seguintes termos e condições de uso.
                    Se você não concordar com algum aspecto destes termos, por favor, não continue a usar o site.
                </p>
                <Typography variant="subtitle2" className={classes.body}>
                    1. CADASTRO E CONTA
                </Typography>
                1.1. Ao se cadastrar no Marketplace Compassio, você concorda em fornecer informações precisas e atualizadas.<br />
                1.2. É responsabilidade do usuário manter a confidencialidade das informações da conta, incluindo senha.<br />
                1.3. Você é responsável por todas as atividades que ocorram sob sua conta.<br />
                <br />
                <Typography variant="subtitle2" className={classes.body}>
                    2. USO DO SITE
                </Typography>
                2.1. Ao utilizar nosso site, você concorda em cumprir todas as leis e regulamentos aplicáveis.<br />
                2.2. Você concorda em não utilizar o site para atividades ilegais, prejudiciais, invasivas, difamatórias ou que violem os direitos de terceiros.<br />
                2.3. O Marketplace Compassio se isenta de qualquer responsabilidade em relação ao conteúdo fornecido pelos usuários.<br />
                2.4. A empresa não é responsável por monitorar ativamente o conteúdo dos usuários, mas reserva-se o direito de remover ou modificar qualquer conteúdo que viole estes termos.<br />
                2.5. A empresa não endossa, garante ou assume responsabilidade pelo conteúdo gerado pelos usuários.<br />
                <br />
                <Typography variant="subtitle2" className={classes.body}>
                    3. TRANSAÇÕES E PAGAMENTOS
                </Typography>
                3.1. O Marketplace Compassio facilita transações entre compradores e vendedores, mas não é responsável por garantir a qualidade dos produtos ou serviços.<br />
                3.2. Pagamentos serão processados de acordo com os termos estabelecidos entre as partes envolvidas na transação.<br />
                3.3. O direito de uso do site poderá ser suspenso caso não seja constatado o pagamento devido.<br />
                <br />
                <Typography variant="subtitle2" className={classes.body}>
                    4. POLÍTICA DE PRIVACIDADE E LGPD
                </Typography>
                {/* 4.1. Ao utilizar o site, você concorda com a nossa Política de Privacidade, disponível [link para a política de privacidade].<br /> */}
                4.1. Comprometemo-nos a cumprir integralmente as diretrizes da Lei Geral de Proteção de Dados (LGPD) e a proteger a privacidade dos usuários.<br />
                4.2. A coleta, o uso e o processamento de dados pessoais dos usuários serão realizados de acordo com as disposições legais e com a nossa Política de Privacidade.<br />
                <br />
                <Typography variant="subtitle2" className={classes.body}>
                    5. RESERVA DE DIREITO DE INTERRUPÇÃO DE SERVIÇO
                </Typography>
                5.1. A Compassio reserva-se o direito de interromper unilateralmente o serviço mediante comunicação prévia de 30 dias.<br />
                5.2. A comunicação sobre a interrupção do serviço será realizada através dos meios de contato fornecidos pelo usuário.<br />
                <br />
                <Typography variant="subtitle2" className={classes.body}>
                    6. ISENÇÃO DE RESPONSABILIDADE POR CONTEÚDO INCORRETO
                </Typography>
                6.1. A Compassio não se responsabiliza por conteúdo incorreto, impreciso ou enganoso fornecido pelos usuários.<br />
                6.2. A empresa não endossa a precisão do conteúdo gerado pelos usuários e recomenda a verificação independente de informações importantes.<br />
                <br />
                <Typography variant="subtitle2" className={classes.body}>
                    7. SUSPENSÃO DO DIREITO DE USO POR FALTA DE PAGAMENTO
                </Typography>
                7.1. O direito de uso do site poderá ser suspenso caso não seja constatado o pagamento devido.<br />
                7.2. A Compassio reserva-se o direito de retomar o acesso ao serviço após o pagamento ser regularizado.<br />
                <br />
                <Typography variant="subtitle2" className={classes.body}>
                    8. PROPRIEDADE INTELECTUAL
                </Typography>
                8.1. Todo o conteúdo presente no site é de propriedade do Marketplace Compassio ou licenciado para uso.<br />
                8.2. Você concorda em não copiar, reproduzir, distribuir ou criar obras derivadas a partir do conteúdo do site sem permissão.<br />
                <br />
                <Typography variant="subtitle2" className={classes.body}>
                    9. SUPORTE AO USUÁRIO
                </Typography>
                9.1. O suporte ao usuário está disponível através do e-mail <Link href="mailto:suporte@compassio.com.br">suporte@compassio.com.br</Link>.<br />
                9.2. Também é possível contatar o suporte pelo link <Link href="https://suporte.compassio.com.br" target='_blank'>https://suporte.compassio.com.br</Link>.<br />
                9.3. O horário do suporte para efeitos de SLA é de segunda à sexta das 09:00 às 18:00.<br />
                <br />
                <Typography variant="subtitle2" className={classes.body}>
                    10. TERMOS DE USO DO SITE
                </Typography>
                <Typography variant="subtitle2" className={classes.body}>
                    10. ALTERAÇÕES NOS TERMOS E CONDIÇÕES
                </Typography>
                10.1. O Marketplace Compassio reserva-se o direito de modificar estes termos a qualquer momento.<br />
                10.2. Notificaremos os usuários sobre alterações significativas nos termos e condições.<br />

                <p>
                    Ao continuar a usar o Marketplace Compassio, você indica sua aceitação das alterações.
                </p>
                <p>
                    Estes termos e condições são um contrato legal entre você e o Marketplace Compassio. Ao utilizar nosso site, você concorda com esses termos. Se tiver dúvidas, entre em contato conosco.
                </p>
                <br />
                <Typography variant="subtitle2" className={classes.body} align='right'>
                    Data da última atualização: 20/03/2024.
                </Typography>
            </Typography>
        </Container >
    )
}

export default TermsAndConditions;