import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Card,
    CardHeader,
    Divider
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
        textAlign: 'center',
        fontSize: '1.2rem',
        backgroundColor: theme.palette.grey[200],
        '& .MuiCardHeader-title': {
            fontSize: '1rem'
        }
    },
    list: {
        height: 400,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

export default function AttributeMapping(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { data, handleChangeField, confirm, cancel } = props;

    const fields = [
        { title: "SKU", name: "sku", value: data.values?.sku},
        { title: "Título", name: "title", value: data.values?.title},
        { title: "Descrição", name: "description", value: data.values?.description},
        { title: "Identificador", name: "customId", value: data.values?.customId},
        { title: "Marca", name: "brand", value: data.values?.brand},
        { title: "Ativo", name: "active", value: data.values?.active ? 'SIM' : 'NÃO'},
        { title: "Largura", name: "width", value: data.values?.width},
        { title: "Altura", name: "height", value: data.values?.height},
        { title: "Comprimento", name: "length", value: data.values?.length},
        { title: "Peso", name: "weight", value: data.values?.weight},
        { title: "Unidades por pacote", name: "unitsPerPackage", value: data.values?.unitsPerPackage},
        { title: "EAN", name: "ean", value: data.values?.ean},
        { title: "Estoque", name: "stock", value: data.values?.stock},
        { title: "Custo", name: "cost", value: data.values?.cost},
        { title: "Preço", name: "price", value: data.values?.price},
        { title: "Preço de Oferta", name: "salePrice", value: data.values?.salePrice},
        { title: "Início Oferta", name: "saleDateStart", value: data.values?.saleDateStart},
        { title: "Fim Oferta", name: "saleDateEnd", value: data.values?.saleDateEnd}
    ]
    
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
            id="edit-variant"
        >
            <DialogTitle id="form-dialog-title">{t('Attribute Mapping') + ' - ' + data.attribute?.name}</DialogTitle>
            <DialogContent>
                <Typography variant='subtitle2' style={{ marginBottom: '1.5rem'}}>
                    Atributo no canal: {data.attribute?.id}
                </Typography>
                <Card>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Atributos disponíveis"
                    />
                    <Divider />
                    <List className={classes.list} dense component="div" role="list">
                        {fields.map((field) => {
                            const labelId = `transfer-list-all-item-${field.name}-label`;

                            return (
                                <ListItem key={field.name} role="listitem" button>
                                    <ListItemIcon>
                                        <Checkbox
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            color="primary"
                                            onChange={handleChangeField}
                                            value={field.value}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={field.title} secondary={field.value} />
                                </ListItem>
                            );
                        })}
                        <ListItem />
                    </List>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={confirm}
                    disabled={props.canEdit === false}
                >
                    {t('Confirm')}
                </Button>
                <Button onClick={cancel}>{t('Cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}