import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    Drawer,
    Box,
    Typography
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import MainMenu from '../MainMenu';
import UserMenu from '../UserMenu';
import StoreSelect from '../StoreSelect';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        }
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        },
        background: theme.palette.header.main
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        background: theme.palette.header.main,
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    search: {
        backgroundColor: 'white',
        width: '30%',
        marginLeft: 'auto',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },

    },
    searchDrawer: {
        padding: '10px',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },

    },
    userMenu: {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    }
}));


const MainContainer = (props) => {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const auth = useAuth();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const history = useHistory();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar}
                style={
                    {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }
                }>
                <img src={process.env.PUBLIC_URL + '/logo_branco.png'} alt="Compassio" style={{ height: '32px' }} />
            </div>
            <MainMenu />
        </div >
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box className={classes.userMenu}>
                        <IconButton onClick={() => { history.goBack() }}>
                            <ArrowBackIcon style={{ color: 'white' }} />
                        </IconButton>
                        <Typography variant="h6">
                            {auth.title}
                        </Typography>
                    </Box>
                    <Box className={classes.userMenu}>
                        <div style={{ minWidth: 300, textAlign: 'end' }}>
                            <StoreSelect />
                        </div>
                        <UserMenu />
                    </Box>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </>
    )
}

export default MainContainer;