import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext';

import {
    Tabs,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    FormControlLabel,
    Box,
    Button,
    Snackbar
} from '@material-ui/core';

import { Alert } from '@material-ui/lab'

import { makeStyles } from '@material-ui/core/styles';

import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';

import Switch from '../../components/Switch';
import { Tab, TabPanel } from '../../components/Tabs';
import Field from '../../components/Field';
import Loading from '../../components/Loading';

import StoreService from '../../services/Store';

const useStyles = makeStyles({
    textBold: {
        fontWeight: 'bold'
    },
    active: {
        paddingLeft: '3px'
    }
});

export default function EditStore() {
    const classes = useStyles();
    const { t } = useTranslation();
    const auth = useAuth();
    const StoreModel = useMemo(() => new StoreService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const [loading, setLoading] = useState(true);
    const [store, setStore] = useState({});
    const [errors, setErrors] = useState({});

    const [tab, setTab] = useState(0);
    const [storeExpanded, setStoreExpanded] = useState(true);
    const [adsExpanded, setAdsExpanded] = useState(true);
    const [vacationsExpanded, setVacationsExpanded] = useState(true);
    const [billingAddressExpanded, setBillingAddressExpanded] = useState(true);
    const [shippingAddressExpanded, setShippingAddressExpanded] = useState(true);
    const [marketplacesExpanded, setMarketplacesExpanded] = useState([]);

    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const handleChangeField = (event) => {
        const updateStore = { ...store };

        if (event.target.dataset?.attribute) {
            updateStore[event.target.dataset.attribute][event.target.name] = event.target.dataset?.value ? event.target.dataset?.value : event.target.value;
        } else {
            updateStore[event.target.name] = event.target.dataset?.value ? event.target.dataset?.value : event.target.value;
        }

        setStore(updateStore);
    }

    const handleCheckAdsField = (event) => {
        const updateStore = { ...store };

        if (typeof (updateStore.storeSettings.ads) === 'undefined') updateStore.storeSettings.ads = {};
        updateStore.storeSettings.ads[event.target.name] = event.target.checked;
        setStore(updateStore);
    }
    
    const handleChangeAdsField = (event) => {
        const updateStore = { ...store };
        
        if (typeof (updateStore.storeSettings.ads) === 'undefined') updateStore.storeSettings.ads = {};
        
        updateStore.storeSettings.ads[event.target.name] = event.target.value;
        setStore(updateStore);
    }

    const handleCheckVacationsField = (event) => {
        const updateStore = { ...store };

        if (typeof (updateStore.storeSettings.vacations) === 'undefined') updateStore.storeSettings.vacations = {};
        updateStore.storeSettings.vacations[event.target.name] = event.target.checked;
        setStore(updateStore);
    }

    const handleChangeVacationsDateField = (date, field) => {
        const updateStore = { ...store };

        if (typeof (updateStore.storeSettings.vacations) === 'undefined') updateStore.storeSettings.vacations = {};
        updateStore.storeSettings.vacations[field] = date.toISOString();
        setStore(updateStore);
    }

    const handleChangeMarketplaceField = (event, index) => {
        const updateStore = { ...store };
        updateStore.marketplaceSettings[index][event.target.name] = event.target.value;
        setStore(updateStore);
    }

    const handleChangeMarketplaceExpanded = (event, marketplace, sellerId) => {
        const updateMarketplacesExpanded = [...marketplacesExpanded];
        const index = updateMarketplacesExpanded.findIndex(x => x.marketplace === marketplace && x.sellerId === (typeof (sellerId) !== 'undefined' ? sellerId : null));

        if (index >= 0) {
            updateMarketplacesExpanded[index].expanded = !updateMarketplacesExpanded[index].expanded;
        }

        setMarketplacesExpanded(updateMarketplacesExpanded);
    }

    const fieldValidation = (event) => {
        const validation = event.target.dataset.validation ? JSON.parse(event.target.dataset.validation) : null;
        const updatedErrors = { ...errors };
        let error = '';

        if (event.target.required && event.target.value.trim() === '') {
            error = t('Required field');
        }

        if (validation) {
            switch (validation.type) {
                case "pattern":
                    const match = new RegExp(validation.match);
                    if (!match.test(event.target.value)) error = validation.message;
                    break;

                default:
                    break;
            }
        }

        if (event.target.dataset?.attribute) {
            if (updatedErrors[event.target.dataset.attribute]) {
                delete updatedErrors[event.target.dataset.attribute][event.target.name];
            }
            if (error !== '') {
                if (!updatedErrors[event.target.dataset.attribute]) {
                    updatedErrors[event.target.dataset.attribute] = {};
                }
                updatedErrors[event.target.dataset.attribute][event.target.name] = error;
            }
        } else {
            delete updatedErrors[event.target.name];
            if (error !== '') {
                updatedErrors[event.target.name] = error;
            }
        }

        setErrors(updatedErrors);
    }

    const handleSaveStore = (event) => {
        setLoading(true);
        StoreModel.update(store._id, store).then(result => {
            setLoading(false);
            setResponseStatus({
                open: true,
                severity: "success",
                message: "Cadastro atualizado com sucesso"
            });

            window.location.reload();
        }).catch(error => {
            setLoading(false);

            console.log(error);

            let prim = true;
            let message = '';

            if (error.errors) {
                Object.values(error.errors).forEach(field => {
                    message += (!prim ? ' | ' : '') + field.message;
                    prim = false;
                });
            } else {
                message = JSON.stringify(error);
            }

            setResponseStatus({
                open: true,
                severity: "error",
                message: message
            });
        })
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })

        window.location.reload();
    }

    useEffect(() => {
        auth.appTitle(t('Settings'));
        StoreModel.retrieve(auth.user.stores[auth.user.selectedStore].storeId).then(store => {

            const updateMarketplacesExpanded = [];

            for (let index = 0; index < store.marketplaceSettings.length; index++) {
                const marketplace = store.marketplaceSettings[index];
                updateMarketplacesExpanded.push({
                    marketplace: marketplace.marketplace,
                    sellerId: marketplace.credentials.sellerId || null,
                    expanded: false
                })
            }

            setMarketplacesExpanded(updateMarketplacesExpanded);
            setStore(store);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            //window.location.href = '/';
        });
    }, [StoreModel, auth, t])

    return (!loading ?
        <React.Fragment>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab label={t('Store')} index={0} />
                <Tab label={t('Settings')} index={1} />
                <Tab label={t('Marketplaces')} index={2} />
            </Tabs>
            <TabPanel value={tab} index={0}>
                <Accordion expanded={storeExpanded}>
                    <AccordionSummary
                        expandIcon={<ArrowDownIcon />}
                        onClick={() => setStoreExpanded(!storeExpanded)}
                        className={classes.textBold}
                    >
                        {t('Store Info')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                                <Field
                                    type="text"
                                    required
                                    name="vatNumber"
                                    label={t("VAT Number")}
                                    value={store.vatNumber}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.vatNumber}
                                    helperText={errors?.vatNumber}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}></Grid>
                            <Grid item xs={12} lg={6}>
                                <Field
                                    type="text"
                                    required
                                    name="name"
                                    label={t("Legal Name")}
                                    defaultValue={store.name}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.name}
                                    helperText={errors?.name}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}></Grid>
                            <Grid item xs={12} lg={6}>
                                <Field
                                    type="text"
                                    required
                                    name="nickname"
                                    label={t("Nickname")}
                                    defaultValue={store.nickname}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.nickname}
                                    helperText={errors?.nickname}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={billingAddressExpanded}>
                    <AccordionSummary
                        expandIcon={<ArrowDownIcon />}
                        onClick={() => setBillingAddressExpanded(!billingAddressExpanded)}
                        className={classes.textBold}
                    >
                        {t('Billing Address')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={8}>
                                <Field
                                    type="text"
                                    required
                                    attribute="billing"
                                    name="streetName"
                                    label={t("Street")}
                                    defaultValue={store.billing?.streetName}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.billing?.streetName}
                                    helperText={errors?.billing?.streetName}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Field
                                    type="text"
                                    required
                                    attribute="billing"
                                    name="streetNumber"
                                    label={t("Number")}
                                    defaultValue={store.billing?.streetNumber}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.billing?.streetNumber}
                                    helperText={errors?.billing?.streetNumber}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Field
                                    type="text"
                                    attribute="billing"
                                    name="streetComplement"
                                    label={t("Street Complement")}
                                    defaultValue={store.billing?.streetComplement}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.billing?.streetComplement}
                                    helperText={errors?.billing?.streetComplement}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Field
                                    type="text"
                                    required
                                    attribute="billing"
                                    name="district"
                                    label={t("District")}
                                    defaultValue={store.billing?.district}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.billing?.district}
                                    helperText={errors?.billing?.district}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Field
                                    type="text"
                                    required
                                    attribute="billing"
                                    name="city"
                                    label={t("City")}
                                    defaultValue={store.billing?.city}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.billing?.city}
                                    helperText={errors?.billing?.city}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Field
                                    type="text"
                                    required
                                    attribute="billing"
                                    name="state"
                                    label={t("State")}
                                    defaultValue={store.billing?.state}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.billing?.state}
                                    helperText={errors?.billing?.state}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Field
                                    type="text"
                                    required
                                    attribute="billing"
                                    name="country"
                                    label={t("Country")}
                                    defaultValue={store.billing?.country}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.billing?.country}
                                    helperText={errors?.billing?.country}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Field
                                    type="text"
                                    required
                                    attribute="billing"
                                    name="zipCode"
                                    label={t("Zip Code")}
                                    defaultValue={store.billing?.zipCode}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.billing?.zipCode}
                                    helperText={errors?.billing?.zipCode}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={shippingAddressExpanded}>
                    <AccordionSummary
                        expandIcon={<ArrowDownIcon />}
                        onClick={() => setShippingAddressExpanded(!shippingAddressExpanded)}
                        className={classes.textBold}
                    >
                        {t('Shipment Address')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={8}>
                                <Field
                                    type="text"
                                    required
                                    attribute="shipping"
                                    name="streetName"
                                    label={t("Street")}
                                    defaultValue={store.shipping?.streetName}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.shipping?.streetName}
                                    helperText={errors?.shipping?.streetName}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Field
                                    type="text"
                                    required
                                    attribute="shipping"
                                    name="streetNumber"
                                    label={t("Number")}
                                    defaultValue={store.shipping?.streetNumber}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.shipping?.streetNumber}
                                    helperText={errors?.shipping?.streetNumber}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Field
                                    type="text"
                                    attribute="shipping"
                                    name="streetComplement"
                                    label={t("Street Complement")}
                                    defaultValue={store.shipping?.streetComplement}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.shipping?.streetComplement}
                                    helperText={errors?.shipping?.streetComplement}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Field
                                    type="text"
                                    required
                                    attribute="shipping"
                                    name="district"
                                    label={t("District")}
                                    defaultValue={store.shipping?.district}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.shipping?.district}
                                    helperText={errors?.shipping?.district}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Field
                                    type="text"
                                    required
                                    attribute="shipping"
                                    name="city"
                                    label={t("City")}
                                    defaultValue={store.shipping?.city}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.shipping?.city}
                                    helperText={errors?.shipping?.city}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Field
                                    type="text"
                                    required
                                    attribute="shipping"
                                    name="state"
                                    label={t("State")}
                                    defaultValue={store.shipping?.state}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.shipping?.state}
                                    helperText={errors?.shipping?.state}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Field
                                    type="text"
                                    required
                                    attribute="shipping"
                                    name="country"
                                    label={t("Country")}
                                    defaultValue={store.shipping?.country}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.shipping?.country}
                                    helperText={errors?.shipping?.country}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Field
                                    type="text"
                                    required
                                    attribute="shipping"
                                    name="zipCode"
                                    label={t("Zip Code")}
                                    defaultValue={store.shipping?.zipCode}
                                    onChange={handleChangeField}
                                    onBlur={fieldValidation}
                                    error={!!errors?.shipping?.zipCode}
                                    helperText={errors?.shipping?.zipCode}
                                    readOnly={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Accordion expanded={adsExpanded} key="ads">
                    <AccordionSummary
                        expandIcon={<ArrowDownIcon />}
                        onClick={() => setAdsExpanded(!adsExpanded)}
                        className={classes.textBold}
                    >
                        {t('Ads')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} style={{ marginBottom: '1rem'}}>
                                <FormControlLabel
                                    className={classes.active}
                                    control={
                                        <Switch
                                            checked={store.storeSettings.ads?.inactiveWhenNoStock}
                                            onChange={handleCheckAdsField}
                                            name="inactiveWhenNoStock"
                                            disabled={auth.user.accessControl["store-Edit"] === false}
                                        />
                                    }
                                    label={t('Mark ad as inactive when there is no stock available')}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Field
                                    type="number"
                                    format="float"
                                    name="minimumValue"
                                    label={t("If ad has price bellow minimum value")}
                                    value={store.storeSettings.ads?.minimumValue}
                                    onChange={handleChangeAdsField}
                                    disabled={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Field
                                    type="number"
                                    format="float"
                                    name="minimumValueAddition"
                                    label={t("Add this rate ($)")}
                                    value={store.storeSettings.ads?.minimumValueAddition}
                                    onChange={handleChangeAdsField}
                                    disabled={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={vacationsExpanded} key="vacations">
                    <AccordionSummary
                        expandIcon={<ArrowDownIcon />}
                        onClick={() => setVacationsExpanded(!vacationsExpanded)}
                        className={classes.textBold}
                    >
                        {t('Vacations')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    className={classes.active}
                                    control={
                                        <Switch
                                            checked={store.storeSettings.vacations?.active}
                                            onChange={handleCheckVacationsField}
                                            name="active"
                                            disabled={auth.user.accessControl["store-Edit"] === false}
                                        />
                                    }
                                    label={t('Active')}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Field
                                    type="date"
                                    name="dateFrom"
                                    label={t("Date from")}
                                    value={store.storeSettings.vacations?.dateFrom}
                                    onChange={handleChangeVacationsDateField}
                                    disabled={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Field
                                    type="date"
                                    name="dateTo"
                                    label={t("Date to")}
                                    value={store.storeSettings.vacations?.dateTo}
                                    onChange={handleChangeVacationsDateField}
                                    disabled={auth.user.accessControl["store-Edit"] === false}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                {store.marketplaceSettings.map((marketplace, index) => (
                    <Accordion expanded={marketplacesExpanded.find(x => x.marketplace === marketplace.marketplace && x.sellerId === (typeof (marketplace.credentials.sellerId) !== 'undefined' ? marketplace.credentials.sellerId : null)).expanded} key={index}>
                        <AccordionSummary
                            expandIcon={<ArrowDownIcon />}
                            onClick={e => handleChangeMarketplaceExpanded(e, marketplace.marketplace, marketplace.credentials.sellerId)}
                            className={classes.textBold}
                        >
                            {marketplace.marketplaceName + (marketplace.credentials.sellerName ? ' - ' + marketplace.credentials.sellerName : '')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid item xs={12} className={classes.active}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={marketplace.active}
                                                onChange={() => { }}
                                                name="active"
                                                disabled={auth.user.accessControl["store-Edit"] === false}
                                            />
                                        }
                                        label={t('Active')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        type="text"
                                        required
                                        name="url"
                                        label={t("URL")}
                                        defaultValue={marketplace.url}
                                        readOnly
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        type="number"
                                        format="float"
                                        required
                                        name="markup"
                                        label={t("Markup")}
                                        defaultValue={marketplace.markup}
                                        onChange={e => handleChangeMarketplaceField(e, index)}
                                        readOnly={auth.user.accessControl["store-Edit"] === false}
                                    />
                                </Grid>
                                {Object.keys(marketplace.credentials).map((field, index) => (
                                    <Grid item xs={12} key={field}>
                                        <Field
                                            type="text"
                                            required
                                            name={field}
                                            label={field}
                                            defaultValue={marketplace.credentials[field]}
                                            readOnly
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </TabPanel>
            <Box textAlign="center" mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    width={100}
                    mr={5}
                    onClick={handleSaveStore}
                    disabled={JSON.stringify(errors) !== '{}' || errors.length > 0 || auth.user.accessControl["store-Edit"] === false}
                >
                    {t('Save')}
                </Button>
            </Box>
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={1000}
                onClose={handleCloseResponseStatus}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
        : <Loading open={loading} />
    );
}