import React, { useState } from 'react';
import UserService from '../services/User';

import {
    Container,
    Grid,
    Link,
    Typography,
    TextField,
    Checkbox,
    Button,
    Box,
    Paper,
    makeStyles,
    CircularProgress
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        left: '0',
        top: '0',
        right: '0',
        bottom: '0'
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
        backgroundColor: '#ffffff9c'
    },
    title: {
        fontSize: '2rem',
        fontWeight: 600,
        marginBottom: theme.spacing(3)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    signup: {
        margin: theme.spacing(1, 0),
    },
    agreement: {
        fontSize: '0.8rem'
    }
}));


function Register() {
    const User = new UserService();
    const classes = useStyles();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [newUser, setNewUser] = useState({
        fullname: '',
        email: '',
        password: '',
        agree: false
    })

    const [errors, setErrors] = useState({});

    const handleChangeField = (event) => {

        //Alteração dos valores
        const updatedUser = { ...newUser };
        updatedUser[event.target.name] = event.target.value;

        fieldValidation(event);
        setNewUser(updatedUser)
    }

    const fieldValidation = (event) => {
        //Validação dos campos
        const updatedErrors = { ...errors };
        delete updatedErrors[event.target.name];

        const validation = event.target.dataset.validation;
        const match = new RegExp(event.target.dataset.match);
        const message = event.target.dataset.message;

        if (event.target.required && event.target.value.trim() === '') {
            updatedErrors[event.target.name] = "Campo obrigatório";
        }

        switch (validation) {
            case "pattern":
                if (!match.test(event.target.value)) updatedErrors[event.target.name] = message;
                break;

            default:
                break;
        }

        setErrors(updatedErrors);
    }

    const registerUser = (event) => {
        setLoading(true);
        User.register(newUser).then(result => {
            setLoading(false);
            setSuccess(true);
        }).catch(error => console.log(error));
    }

    return (
        <Container maxWidth="sm" classes={{ root: classes.container }}>
            <div>
                {success ? (
                    <Paper classes={{ root: classes.paper }}>
                        <Box>
                            <Typography variant="h4" align="center">
                                {t("Welcome")}!
                            </Typography>
                        </Box>
                        <Box my={6}>
                            <Typography variant="h5">
                                {t("To complete your registration, please verify your email")}.
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            *{t("Make sure to check your spam")}
                        </Typography>
                    </Paper>
                ) : (
                    <Paper classes={{ root: classes.paper }}>
                        <Typography variant="h4" align="center" classes={{ root: classes.title }}>E-COMMERCE</Typography>
                        <Typography variant="h5" align="center">
                            {t("Sell your products with the tips of your fingers")}
                        </Typography>

                        <form className={classes.form} noValidate autoComplete="none">
                            <TextField
                                name="fullname"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label={t("Full Name")}
                                autoFocus
                                value={newUser.fullname}
                                onBlur={fieldValidation}
                                onChange={handleChangeField}
                                inputProps={{
                                    "data-validation": "pattern",
                                    "data-match": "^[a-zA-Z\\s]*$",
                                    "data-message": t("Only letters and spaces are allowed")
                                }}
                                error={!!errors?.fullname}
                                helperText={errors?.fullname}
                                disabled={loading}
                            />
                            <TextField
                                name="email"
                                type="email"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label={t("Email Address")}
                                value={newUser.email}
                                onBlur={fieldValidation}
                                onChange={handleChangeField}
                                inputProps={{
                                    "data-validation": "pattern",
                                    "data-match": '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                                    "data-message": t("Not a valid email address")
                                }}
                                error={!!errors?.email}
                                helperText={errors?.email}
                                disabled={loading}
                            />
                            <TextField
                                name="password"
                                type="password"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label={t("Password")}
                                value={newUser.password}
                                onBlur={fieldValidation}
                                onChange={handleChangeField}
                                inputProps={{
                                    "data-validation": "pattern",
                                    "data-match": '.{8,}',
                                    "data-message": t("Password must have at least 8 characters")
                                }}
                                error={!!errors?.password}
                                helperText={errors?.password}
                                disabled={loading}
                            />
                            <div className={classes.agreement}>
                                <Checkbox value="remember" color="primary" onChange={(e) => setNewUser({ ...newUser, agree: e.target.checked })} disabled={loading} />
                                {t("I agree to the")} &nbsp;
                            <Link href="/terms-and-conditions">{t("Terms and Conditions")}</Link>
                            &nbsp; {t("and")} &nbsp;
                            <Link href="/privacy-policy">{t("Privacy Policy")}</Link>
                            </div>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.signup}
                                disabled={!newUser.agree || JSON.stringify(errors) !== "{}" || loading}
                                onClick={registerUser}
                            >
                                {loading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    t("Sign Up")
                                )}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Typography align="center">
                                        <Link href="/" variant="body2">
                                            {t("Already have an account? Sign in")}
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                )}

                <Box mt={1} textAlign="center">
                    <Typography variant="body1" color="textSecondary" align="center">
                        from
                </Typography>
                </Box>
                <Box mt={1} textAlign="center">
                    <Link href="https://www.compassio.com.br" target="_blank" variant="body2">
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Compassio" height="30" />
                    </Link>
                </Box>
            </div>
        </Container >
    )
}

export default Register;