import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropzoneDialogBase } from 'material-ui-dropzone';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tabs,
    IconButton,
    //InputAdornment
} from '@material-ui/core';

//import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';

import { Tab, TabPanel } from '../../components/Tabs';
import Field from '../../components/Field';

import AttributeMapping from './AttributeMapping';

export default function EditVariant(props) {
    const { t } = useTranslation();
    const { data, confirm, cancel, handleChangeField, handleChangeDateField, handleChangeAttribute } = props;
    const [errors, setErrors] = useState({});
    const [tab, setTab] = useState(0);
    const [images, setImages] = useState([]);
    const [imagesToUpload, setImagesToUpload] = useState([]);
    const [openFileUpload, setOpenFileUpload] = useState(false);
    const [dialogAttributeMapping, setDialogAttributeMapping] = useState({
        open: false,
        data: {},
        selectedValues: []
    })

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const handleChangeAttributeField = (event) => {
        const updateAttribute = [ ...data.attributes ];
        const index = updateAttribute.length > 0 ? updateAttribute.findIndex(x => x.key === dialogAttributeMapping.data.attribute.key) : -1;

        if (event.target.checked === true) {
            if (index >= 0) {
                updateAttribute[index].value = event.target.value;
            } else {
                updateAttribute.push({ key: dialogAttributeMapping.data.attribute.key, value: event.target.value });
            }
        } else {
            if (index >= 0) {
                updateAttribute.splice(index, 1);
            }
        }

        setDialogAttributeMapping({
            ...dialogAttributeMapping
        })
    }

    const handleConfirmAttributes = () => {
        setDialogAttributeMapping({
            open: false,
            data: {},
            selectedValues: []
        })
    }

    const handleDeleteImage = (event, index) => {
        const updateImages = [...images];
        updateImages.splice(index, 1);
        setImages(updateImages);
        data.images = updateImages;
    }

    const handleUploadFiles = (newFiles) => {
        let updateImages = [...imagesToUpload];
        updateImages = updateImages.concat(newFiles);
        setImagesToUpload(updateImages);
        data.imagesToUpload = updateImages;
    }

    const handleDeleteFile = (file) => {
        const updateImages = [...imagesToUpload];
        updateImages.splice(updateImages.findIndex(x => x.file.name === file.file.name), 1);
        setImagesToUpload(updateImages);
        data.imagesToUpload = updateImages;
    }

    const fieldValidation = (event) => {
        const validation = event.target.dataset.validation ? JSON.parse(event.target.dataset.validation) : null;
        const updatedErrors = { ...errors };
        delete updatedErrors[event.target.name];

        if (event.target.required && event.target.value.trim() === '') {
            updatedErrors[event.target.name] = t('Required field');
        }

        if (validation) {
            switch (validation.type) {
                case "pattern":
                    const match = new RegExp(validation.match);
                    if (!match.test(event.target.value)) updatedErrors[event.target.name] = validation.message;
                    break;

                default:
                    break;
            }
        }

        setErrors(updatedErrors);
    }

    const handleSubmit = (event) => {
        const htmlFields = document.getElementById("edit-variant").getElementsByTagName('input');
        let isValid = true;
        for (let index = 0; index < htmlFields.length; index++) {
            const field = htmlFields[index];
            if (!!field.required && (field.value === '' || field.value === null)) {
                isValid = false;
                field.focus();
                field.blur();
                break;
            }
        }

        if (isValid) confirm();
    }

    const formatAttributes = (attributes) => {
        const formattedAttributes = [];
        attributes.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });

        for (let index = 0; index < attributes.length; index++) {
            const attribute = attributes[index];

            formattedAttributes.push({
                label: attribute.name,
                value: attribute.name
            })
        }

        return formattedAttributes;
    }

    const capitalize = (string) => {
        return string.substr(0, 1).toUpperCase() + string.substr(1);
    }

    useEffect(() => {
        setImagesToUpload(data.imagesToUpload || []);
        setImages(data.images);
    }, [data])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="lg"
            id="edit-variant"
        >
            <DialogTitle id="form-dialog-title">{t('Edit Variant') + ' - ' + data.title}</DialogTitle>
            <DialogContent>
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label={t('Product')} index={0} />
                    <Tab label={t('Variations')} index={1} />
                    <Tab label={t('Attributes')} index={2} />
                    <Tab label={t('Images')} index={3} />
                </Tabs>
                <TabPanel value={tab} index={0}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Field
                                required
                                type="text"
                                name="title"
                                label={t("Title")}
                                value={data.title}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.title}
                                helperText={errors?.title}
                                readOnly={data.marketplaceStatus === 2 || data.marketplaceStatus === 3 || props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                required
                                type="text"
                                name="description"
                                label={t("Description")}
                                value={data.description}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.description}
                                helperText={errors?.description}
                                multiline
                                rows={5}
                                readOnly={data.marketplaceStatus === 2 || data.marketplaceStatus === 3 || props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="text"
                                name="ean"
                                label={t("EAN")}
                                value={data.ean}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.ean}
                                helperText={errors?.ean}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                required
                                name="stock"
                                label={t("Stock")}
                                value={data.stock}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.stock}
                                helperText={errors?.stock}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                name="stockPercentage"
                                label={t("Stock Percentage")}
                                value={data.stockPercentage}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.stockPercentage}
                                helperText={errors?.stockPercentage}
                                inputProps={{
                                    "data-validation": JSON.stringify({
                                        type: "maxValue",
                                        match: 100,
                                        message: t("Maximum value exceeded")
                                    })
                                }}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                name="crossdocking"
                                label={t("Crossdocking Days")}
                                value={data.crossdocking}
                                onChange={handleChangeField}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                required
                                name="height"
                                label={t("Height")}
                                value={data.height}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.height}
                                helperText={errors?.height}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                required
                                name="width"
                                label={t("Width")}
                                value={data.width}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.width}
                                helperText={errors?.width}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                required
                                name="length"
                                label={t("Length")}
                                value={data.length}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.length}
                                helperText={errors?.length}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                required
                                name="weight"
                                label={t("Weight")}
                                value={data.weight}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.weight}
                                helperText={errors?.weight}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="float"
                                required
                                name="price"
                                label={t("Price")}
                                value={data.price}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.price}
                                helperText={errors?.price}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="float"
                                name="salePrice"
                                label={t("Sale Price")}
                                value={data.salePrice}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.salePrice}
                                helperText={errors?.salePrice}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="datetime"
                                name="saleDateStart"
                                label={t("Sale Start Date")}
                                value={data.saleDateStart}
                                onChange={handleChangeDateField}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="datetime"
                                name="saleDateEnd"
                                label={t("Sale End Date")}
                                value={data.saleDateEnd}
                                onChange={handleChangeDateField}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="float"
                                name="markup"
                                label={t("Markup")}
                                value={data.markup}
                                onChange={handleChangeField}
                                readOnly={props.canEdit === false}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Grid container spacing={1}>
                        {data?.category?.attributes?.filter(x => x.tags.allow_variations === true).map((attribute, index) => (
                            <Grid item xs={12} md={6} key={attribute.id}>
                                {!attribute.values &&
                                    <Field
                                        type="text"
                                        required={!!attribute.tags?.required}
                                        name={attribute.id}
                                        label={t(attribute.name)}
                                        value={data.attributes.find(x => x.key === attribute.id)?.value || ''}
                                        onChange={handleChangeAttribute}
                                        onBlur={fieldValidation}
                                        readOnly={props.canEdit === false}
                                    />
                                }
                                {attribute.values &&
                                    <Field
                                        type="select"
                                        required={!!attribute.tags?.required}
                                        name={attribute.id}
                                        label={t(attribute.name)}
                                        options={formatAttributes(attribute.values)}
                                        value={capitalize(data.attributes.find(x => x.key === attribute.id)?.value) || ''}
                                        onChange={handleChangeAttribute}
                                        readOnly={props.canEdit === false}
                                    />
                                }
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <Grid container spacing={1}>
                        {data?.category?.attributes?.filter(x => (typeof (x.tags.allow_variations) === 'undefined' && x.tags.variation_attribute === true && !x.tags?.read_only)).map((attribute, index) => (
                            <Grid item xs={12} md={6} key={attribute.id}>
                                {!attribute.values &&
                                    <Field
                                        type="text"
                                        required={!!attribute.tags?.required}
                                        name={attribute.id}
                                        label={t(attribute.name)}
                                        value={data.attributes.find(x => x.key === attribute.id)?.value || ''}
                                        onChange={handleChangeAttribute}
                                        onBlur={fieldValidation}
                                        readOnly={props.canEdit === false}
                                    />
                                }
                                {attribute.values &&
                                    <Field
                                        type="select"
                                        required={!!attribute.tags?.required}
                                        name={attribute.id}
                                        label={t(attribute.name)}
                                        options={formatAttributes(attribute.values)}
                                        value={data.attributes.find(x => x.id === attribute.key)?.value || ''}
                                        onChange={handleChangeAttribute}
                                        readOnly={props.canEdit === false}
                                    />
                                }
                            </Grid>
                        ))}
                        {/* <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ width: '100%', marginTop: '10px' }}
                                onClick={() => setDialogAttributeMapping({
                                    open: true,
                                    data: {}
                                })}
                            >
                                {t('Add new attribute')}
                            </Button>
                        </Grid> */}
                    </Grid>
                </TabPanel>
                <TabPanel value={tab} index={3}>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                    }}>
                        {data.images?.length > 0 && data.images?.map((image, index) => (
                            <div key={index} style={{
                                flex: '0 1 200px',
                                margin: '5px',
                                alignSelf: 'center',
                                border: '2px solid #ccc'
                            }}>
                                <img src={image} alt={t('Image')} style={{ maxWidth: '100%', display: 'block' }} />
                                {props.canEdit === true &&
                                    <IconButton
                                        size="small"
                                        style={{
                                            position: 'relative',
                                            top: '-10px',
                                            right: '3px',
                                            float: 'right',
                                            backgroundColor: '#f53b57',
                                            color: 'rgb(232 254 250)',
                                            borderRadius: 0
                                        }}
                                        onClick={e => handleDeleteImage(e, index)}
                                    ><DeleteIcon fontSize="small" /></IconButton>
                                }
                            </div>
                        ))}
                        {imagesToUpload?.length > 0 && imagesToUpload?.map((image, index) => (
                            <div key={index} style={{
                                flex: '0 1 300px',
                                margin: '5px',
                                alignSelf: 'center'
                            }}>
                                <img src={image.data} alt={t('Image')} style={{ maxWidth: '100%', display: 'block' }} />
                            </div>
                        ))}
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ width: '100%', marginTop: '10px' }}
                            onClick={() => setOpenFileUpload(true)}
                            disabled={props.canEdit === false}
                        >
                            {t('Add new image')}
                        </Button>
                    </div>
                </TabPanel>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                    disabled={props.canEdit === false}
                >
                    {t('Confirm')}
                </Button>
                <Button onClick={cancel}>{t('Cancel')}</Button>

            </DialogActions>
            <AttributeMapping
                open={dialogAttributeMapping.open}
                data={dialogAttributeMapping.data}
                title={t('Add new attribute')}
                handleChangeField={handleChangeAttributeField}
                confirm={handleConfirmAttributes}
                cancel={() => setDialogAttributeMapping({
                    open: false,
                    data: {},
                    selectedValues: []
                })}
            />
            <DropzoneDialogBase
                acceptedFiles={['image/png', 'image/jpeg']}
                filesLimit={10}
                fileObjects={imagesToUpload}
                submitButtonText={t('Confirm')}
                cancelButtonText={t('Cancel')}
                dialogTitle={t('Import images')}
                dropzoneText={t('Click or drag images')}
                maxFileSize={200000000}
                open={openFileUpload}
                onAdd={handleUploadFiles}
                onDelete={handleDeleteFile}
                onClose={() => {
                    setOpenFileUpload(false);
                }}
                onSave={() => {
                    setOpenFileUpload(false);
                }}
                showAlerts={['error']}
                showPreviews={false}
                showPreviewsInDropzone
                maxWidth="lg"
                fullWidth
                previewGridClasses={{
                    item: 'MuiGrid-grid-xs-12 MuiGrid-grid-md-4'
                }}
            />
        </Dialog>
    )
}