import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination
} from '@material-ui/core';

function DataTable(props) {
    const { t } = useTranslation();

    const footer = (field) => {
        let ret = '';

        if (field) {
            if (field.value >= 0) {
                ret = field.value;
            } else {
                if (field.type === 'count') {
                    ret = props.docs?.map(doc => doc[field.column]).length;
                } else {
                    ret = props.docs?.map(doc => doc[field.column]).reduce((sum, i) => sum + i, 0);
                }
            }

            if (ret) ret = ret.toLocaleString(localStorage.getItem('i18nextLng'), { minimumFractionDigits: 2 });
        }

        return ret;
    }

    return (
        <Paper style={{ width: '100%' }}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow key="datatable-header">
                            {props.columns.map((column, index) => (
                                <TableCell key={column.id + '-' + index} align={column.align}>{column.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.docs?.length > 0 && props.docs?.map((doc, index) => (
                            <TableRow key={doc._id + "-" + index} hover>
                                {props.columns.map((column, columnIndex) => {
                                    let value;

                                    if (column.type === 'hybrid') {
                                        value = doc;
                                    } else {
                                        if (column.index >= 0) {
                                            const columnName = column.id.split('.');
                                            value = doc[columnName[0]][column.index][columnName[1]];
                                        } else {
                                            value = doc[column.id];
                                        }
                                    }

                                    return (
                                        <TableCell key={index + '-' + columnIndex} align={column.align} style={{ minWidth: column.width + 'px', maxWidth: column.maxWidth + 'px' }}>
                                            {column.format ? column.format(value, index) : value}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                        {(!props.docs || props.docs.length <= 0) && (
                            <TableRow>
                                <TableCell colSpan={props.columns.length} align="center">
                                    {t('No records found')}
                                </TableCell>
                            </TableRow>
                        )}
                        {props.footer &&
                            <TableRow>
                                {props.columns.map((column, index) => (
                                    <TableCell key={index} align={column.align} style={{
                                        fontWeight: 'bold'
                                    }}>
                                        {
                                            footer(props.footer.find(x => x.column === column.id))
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.count > 0 &&
                <TablePagination
                    rowsPerPageOptions={[30, 50, 100]}
                    component="div"
                    count={props.count}
                    rowsPerPage={props.size}
                    page={props.page}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeSize}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('from')} ${count}`}
                    labelRowsPerPage={t('Rows per page')}
                    nextIconButtonText={t('Next page')}
                    backIconButtonText={t('Previous page')}
                />
            )}
        </Paper>
    )
}

export default DataTable;