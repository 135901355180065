import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext';

import {
    Grid,
    Card,
    CardContent,
    Typography,
    makeStyles
} from '@material-ui/core';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import FilterContainer from '../../components/FilterContainer';
import DashboardService from '../../services/Dashboard';
import moment from 'moment';

ChartJS.register(ArcElement, Tooltip, Legend);

const useStyles = makeStyles({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    cardContent: {
        overflow: 'auto'
    },
    chartContainer: {
        position: 'relative',
        margin: 'auto',
        maxHeight: '400px',
        maxWidth: '400px',
        width: '100%'
    }
});

const statusColors = [
    {
        status: 'Novo',
        color: "rgba(200,200,200,1)",
        hover: "rgba(200,200,200,0.8)"
    },
    {
        status: 'Aprovado',
        color: "rgba(251,202,001,1)",
        hover: "rgba(251,202,001,0.8)",
    },
    {
        status: 'Faturado',
        color: "rgba(000,100,255,1)",
        hover: "rgba(000,100,255,0.8)"
    },
    {
        status: 'Enviado',
        color: "rgba(000,050,200,1)",
        hover: "rgba(000,050,200,0.8)"
    },
    {
        status: 'Entregue',
        color: "rgba(042,117,046,1)",
        hover: "rgba(042,117,046,0.8)"
    },
    {
        status: 'Cancelado',
        color: "rgba(255,000,076,1)",
        hover: "rgba(255,000,076,0.8)"
    },
]

export default function Dashboard() {
    const classes = useStyles();
    const auth = useAuth();
    const { t } = useTranslation();
    const DashboardModel = useMemo(() => new DashboardService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);

    const [filters, setFilters] = useState({
        dateFrom: null,
        dateTo: null,
        marketplace: [],
        qs: "?dateFrom="+moment().format("YYYY-MM-01")+"&dateTo="+moment().format("YYYY-MM-DD")
    });

    const [ordersByStatus, setOrdersByStatus] = useState([]);

    const retrieveOrders = useCallback(() => {
        DashboardModel.orders(filters.qs).then(orders => {
            setOrdersByStatus(orders.ordersByStatus);
        }).catch(error => {
            console.log(error);
        })
    }, [DashboardModel, filters])

    const filterOptions = [
        {
            type: "date",
            id: "dateFrom",
            name: "dateFrom",
            label: t('Date from'),
            value: filters.dateFrom,
            onChange: (date) => {
                if (date) setFilters({ ...filters, dateFrom: date.toISOString() });
            }
        },
        {
            type: "date",
            id: "dateTo",
            name: "dateTo",
            label: t('Date to'),
            value: filters.dateTo,
            onChange: (date) => {
                if (date) setFilters({ ...filters, dateTo: date.toISOString() });
            }
        },
        {
            type: "select-multiple",
            dataSelect: "marketplace",
            id: "marketplace",
            name: "marketplace",
            label: t('Marketplace'),
            value: filters.marketplace,
            onChange: (e, marketplace) => {
                setFilters({ ...filters, marketplace: (marketplace ? marketplace.marketplace : null) });
            }
        }
    ];

    const handleApplyFilters = () => {
        let qs = "";

        if (filters.dateFrom) qs += "&marketplaceCreateDate[$gte]=" + filters.dateFrom;
        if (filters.dateTo) qs += "&marketplaceCreateDate[$lte]=" + filters.dateTo;
        if (filters.marketplace) qs += "&marketplace=" + filters.marketplace;

        setFilters({
            ...filters,
            qs: qs
        });
    }

    const handleDismissFilters = () => {

        setFilters({
            dateFrom: null,
            dateTo: null,
            marketplace: [],
            qs: "?dateFrom=2022-01-01&dateTo=2022-10-31"
        })
    }

    useEffect(() => {
        auth.appTitle(t('Dashboard'));
        retrieveOrders();
    }, [t, auth, retrieveOrders]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <FilterContainer
                    title={t('Filters')}
                    filters={filterOptions}
                    handleApplyFilters={handleApplyFilters}
                    handleDismissFilters={handleDismissFilters}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h6" align="center">
                            {t('Orders by Status')}
                        </Typography>
                        <br />
                        <div className={classes.chartContainer}>
                            {ordersByStatus && (
                                <Pie
                                    data={{
                                        labels: ordersByStatus.map(x => x._id),
                                        datasets: [{
                                            label: t('Orders by Status'),
                                            data: ordersByStatus.map(x => x.quantity),
                                            backgroundColor: statusColors.filter(x => ordersByStatus.map(y => y._id).includes(x.status)).map(a => a.color),
                                            hoverBackgroundColor: statusColors.filter(x => ordersByStatus.map(y => y._id).includes(x.status)).map(a => a.hover),
                                            borderWidth: 2
                                        }]
                                    }}
                                    options={{
                                        plugins: {
                                            legend: {
                                                position: 'bottom',
                                            }
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
                {/* <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h6" align="center">
                            {t('Sales Indicators')}
                        </Typography>
                        <br /><br />

                    </CardContent>
                    <CardActions>

                    </CardActions>
                </Card> */}
            </Grid>
        </Grid>
    )
}