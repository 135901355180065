import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import {
    IconButton,
    Grid,
    Snackbar,
    Chip
} from '@material-ui/core'

import {
    Alert,
} from '@material-ui/lab'

import {
    Button
} from '../../components/CustomUI';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import Loading from '../../components/Loading';
import MarketplaceLogo from '../../components/MarketplaceLogo';
import AdStatus from '../../components/AdStatus';

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import Errors from './Errors';

import AdService from '../../services/Ad';
import StoreService from '../../services/Store';

export default function AdList() {
    const auth = useAuth();
    const storeId = auth.user.stores[auth.user.selectedStore].storeId;
    const AdModel = useMemo(() => new AdService(storeId, auth.user.token), [storeId, auth]);
    const StoreModel = useMemo(() => new StoreService(storeId, auth.user.token), [storeId, auth]);
    const { t } = useTranslation();
    const [filters, setFilters] = useState({
        search: '',
        marketplace: null,
        marketplaceStatus: []
    });

    const [dialogErrors, setDialogErrors] = useState({
        open: false,
        data: []
    })

    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: ''
    });

    const [store, setStore] = useState({});

    const [remove, setRemove] = useState({
        open: false,
        name: "",
        id: "",
    });

    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success",
    });

    const retrieveStore = useCallback((storeId) => {
        StoreModel.retrieve(storeId).then(result => {
            setStore(result);
        }).catch(error => {
            console.log(error);
        })
    }, [StoreModel])

    const productList = useCallback((page = 0, size = 30, filters = '') => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        AdModel.listWithVariants(page, size, filters).then(result => {
            setData({
                isLoading: false,
                error: null,
                docs: result.docs,
                page: result.page - 1,
                size: result.limit,
                count: result.totalDocs,
                filters: filters
            })
        })

    }, [AdModel])

    useEffect(() => {
        auth.appTitle(t('Ads'));
        retrieveStore(storeId);
        productList(data.page, data.size, data.filters)
    }, [t, productList, retrieveStore, storeId, data.page, data.size, data.filters, auth]);

    const handleKeyUpSearch = (event) => {
        setFilters({
            ...filters,
            search: event.target.value,
        });

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = "";

        if (filters.search) {
            qs += "&$or[0][title][$regex]=" + filters.search;
            qs += "&$or[0][title][$options]=gi";
            qs += "&$or[1][parent][$regex]=" + filters.search;
            qs += "&$or[1][parent][$options]=gi";
            qs += "&$or[2][sku][$regex]=" + filters.search;
            qs += "&$or[2][sku][$options]=gi";
        }
        
        if (filters.marketplace) qs += "&marketplace=" + filters.marketplace;

        for (let index = 0; index < filters.marketplaceStatus.length; index++) {
            let status = 1;

            switch (filters.marketplaceStatus[index]) {
                case t('Pending'): status = 1; break;
                case t('Analysing'): status = 2; break;
                case t('Selling'): status = 3; break;
                case t('Error'): status = 9; break;
                default: status = 1; break;
            }
            qs += "&marketplaceStatus=" + status;
        }

        setData({
            ...data,
            filters: qs
        });
    }

    const handleDismissFilters = () => {
        setFilters({
            search: '',
            marketplace: null,
            marketplaceStatus: []
        })
        setData({
            ...data,
            filters: ''
        });
    }

    const filterOptions = [
        {
            type: "text",
            id: "search",
            name: "search",
            label: t('Search by title or SKU'),
            defaultValue: filters.search,
            onKeyUp: handleKeyUpSearch,
            onBlur: (e) => {
                setFilters({ ...filters, search: e.target.value });
            }
        },
        {
            type: "select",
            dataSelect: "marketplace",
            id: "marketplace",
            name: "marketplace",
            label: t('Marketplace'),
            value: filters.marketplace,
            onChange: (e, marketplace) => {
                setFilters({ ...filters, marketplace: (marketplace ? marketplace.marketplace : null) });
            }
        },
        {
            type: "select-multiple",
            id: "marketplaceStatus",
            name: "marketplaceStatus",
            label: t('Status'),
            value: filters.marketplaceStatus,
            options: [
                t('Pending'),
                t('Analysing'),
                t('Selling'),
                t('Error')
            ],
            onChange: (e) => {
                setFilters({ ...filters, marketplaceStatus: e.target.value });
            }
        }
    ];

    const columns = [
        {
            id: 'thumbnail',
            label: t('Image'),
            align: 'center',
            format: (thumbnail) => thumbnail ? <img src={thumbnail} alt="Miniatura" height={40} /> : <img src={process.env.PUBLIC_URL + '/no-image.png'} alt="Miniatura" height={40} />
        },
        {
            id: 'productTitle',
            label: t('Title'),
            align: 'left'
        },
        {
            id: 'active',
            label: t('Active'),
            align: 'center',
            type: 'hybrid',
            format: (doc) => doc.variations.find(variant => variant.active === true) ? (
                <Chip 
                    size="small" 
                    color="primary" 
                    icon={<DoneIcon />} 
                    label={t("Yes")} 
                    clickable={auth.user.accessControl["ads-Edit"] === true}
                    onClick={e => auth.user.accessControl["ads-Edit"] === true && handleChangeActive(e, doc, false)} 
                />
            ) : (
                <Chip 
                    size="small" 
                    color="secondary" 
                    icon={<CloseIcon />} 
                    label={t("No")} 
                    clickable={auth.user.accessControl["ads-Edit"] === true}
                    onClick={e => auth.user.accessControl["ads-Edit"] === true && handleChangeActive(e, doc, true)} 
                />
            )
        },
        {
            id: 'parent',
            label: t('SKU'),
            align: 'center'
        },
        {
            id: 'variations',
            label: t('Variants'),
            align: 'center',
            format: (variants) => variants.length > 1 ? variants.length : t('Unique')
        },
        {
            id: 'marketplace',
            label: t('Marketplace'),
            align: 'center',
            type: 'hybrid',
            format: (ad) => <MarketplaceLogo marketplace={ad.marketplace} sellerName={ad.sellerName} listingType={ad.listingTypeId} />
        },
        {
            id: 'stock',
            label: t('Stock'),
            align: 'right',
            type: 'hybrid',
            format: (product) => {
                return product.variations.reduce(function (a, b) {
                    return a + Math.ceil(b['stock'] * ((b['stockPercentage'] || 100)/100));
                }, 0);
            }
        },
        {
            id: 'price',
            label: t('Price'),
            align: 'right',
            type: 'hybrid',
            format: (product) =>
                <>
                    {Price(product, false)}<br />
                    {product.saleDateStart < moment().toISOString() && product.saleDateEnd > moment().toISOString() &&
                        <small style={{ color: 'gray' }}>{Price(product)}</small>
                    }
                </>
        },
        {
            id: 'marketplaceStatus',
            label: t('Sync'),
            align: 'center',
            type: 'hybrid',
            format: (ad) => ad.marketplaceStatus !== 9 ? <AdStatus status={ad.marketplaceStatus} /> : <AdStatus status={ad.marketplaceStatus} onClick={() => setDialogErrors({
                open: true,
                data: ad.marketplaceErrors
            })} />
        },
        {
            id: 'id',
            label: t('Actions'),
            align: 'center',
            type: 'hybrid',
            width: 90,
            format: (doc, index) => {
                let filters = '';

                if (doc.sellerId) filters += (filters === '' ? '?' : '&') + 'sellerId=' + doc.sellerId;
                if (doc.listingTypeId) filters += (filters === '' ? '?' : '&') + 'listingTypeId=' + doc.listingTypeId;
                if (doc.variations.length > 1) filters += (filters === '' ? '?' : '&') + 'variants=true';

                return (

                    <div>
                        <IconButton 
                            size="small" 
                            title={auth.user.accessControl["ads-Edit"] === true ? t('Edit') : t('View')} 
                            aria-label={t('Edit')} 
                            href={'/ads/' + doc.parent + '/' + doc.marketplace + filters} 
                            color="primary"
                        >
                            {auth.user.accessControl["ads-Edit"] === true && <EditIcon fontSize="small" />}
                            {auth.user.accessControl["ads-Edit"] === false && <ViewIcon fontSize="small" />}
                        </IconButton>
                        <IconButton 
                            size="small" 
                            title={t('Delete')} 
                            aria-label={t('Delete')} 
                            onClick={e => handleClickDelete(e, doc.parent, doc.productTitle)} 
                            color="secondary"
                            disabled={doc.marketplaceStatus === 3 || auth.user.accessControl["ads-Delete"] === false}
                            >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </div>
                )
            }
        }
    ];

    const handleChangeActive = (e, product, active) => {
        setData({
            ...data,
            isLoading: true
        });

        let filters = '';
        filters += '&parent=' + product.parent;
        filters += '&marketplace=' + product.marketplace;
        filters += '&sellerId=' + product.sellerId;
        filters += '&listingTypeId=' + product.listingTypeId;

        AdModel.list(0, 1000, filters).then(ads => {
            const errors = [];

            for (let index = 0; index < ads.docs.length; index++) {
                const ad = ads.docs[index];

                AdModel.update(ad._id, {
                    active: active
                }).catch(error => {
                    setData({
                        ...data,
                        isLoading: false
                    });

                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: error.message
                    });

                    errors.push(error);
                });

                if (errors.length > 0) break;
            }

            if (errors.length <= 0) {
                setData({
                    ...data,
                    isLoading: false
                });

                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: t('Ad updated successfully')
                });
            }
        }).catch(error => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        })
    }

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id,
        });
    };

    const handleDeleteProduct = (event) => {
        AdModel.list(0, 1000, "&parent=" + remove.id).then(ads => {
            const errors = [];

            for (let index = 0; index < ads.docs.length; index++) {
                const ad = ads.docs[index];

                AdModel.delete(ad._id).catch(error => {
                    setRemove({
                        open: false,
                        name: "",
                        id: "",
                    });

                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: error.message
                    });

                    errors.push(error);
                });

                if (errors.length > 0) break;
            }

            if (errors.length <= 0) {
                setRemove({
                    open: false,
                    name: "",
                    id: "",
                });

                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: t('Ad removed successfully')
                });
            }
        }).catch(error => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        })
    };

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success",
        });

        productList(data.page, data.size, data.filters);
    };

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            page: 0,
            size: +event.target.value
        })
    }

    const Price = (ad, calcSalePrice = true) => {
        const now = moment();
        const saleDateStart = moment(ad.saleDateStart);
        const saleDateEnd = moment(ad.saleDateEnd);
        const marketplaceSettings = store?.marketplaceSettings?.find(x => x.marketplace === ad.marketplace && x.credentials.sellerId === Number(ad.sellerId));

        let price = ad.price;

        if (calcSalePrice) {
            if (now >= saleDateStart && now <= saleDateEnd) {
                price = ad.salePrice;
            }
        }
        price = price * (1 + ((ad.markup || 0) / 100));

        if (marketplaceSettings) {
            price = price * (1 + ((marketplaceSettings.markup || 0) / 100));
        }

        return price.toLocaleString(localStorage.getItem('i18nextLng'), { style: 'currency', minimumFractionDigits: 2, currency: 'BRL' });
    }

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={8} md={10} xl={11}>
                <FilterContainer
                    title={t('Filters')}
                    filters={filterOptions}
                    handleApplyFilters={handleApplyFilters}
                    handleDismissFilters={handleDismissFilters}
                />
            </Grid>
            <Grid item xs={6} sm={4} md={2} xl={1} style={{ alignSelf: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{
                        height: '48px'
                    }}
                    startIcon={<AddIcon />}
                    href="/ads/new"
                    disabled={auth.user.accessControl["ads-New"] === false}
                >
                    {t('New')}
                </Button>
            </Grid>
            {!isLoading && docs ?
                <Grid item xs={12}>
                    <DataTable
                        columns={columns}
                        docs={docs}
                        page={page}
                        size={size}
                        count={count}
                        error={error}
                        handleChangePage={handleChangePage}
                        handleChangeSize={handleChangeSize}
                    />
                </Grid>
                :
                <Loading open={isLoading} />
            }
            <ConfirmationDialog
                title={t('Are you sure to remove this product?')}
                confirmTitle={t('Yes')}
                confirm={handleDeleteProduct}
                cancelTitle={t('No')}
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
            >
                {remove.name}
            </ConfirmationDialog>
            <Errors
                open={dialogErrors.open}
                title={t('Sync errors')}
                onClose={() => setDialogErrors({
                    data: [],
                    open: false
                })}
                data={dialogErrors.data}
                confirm={() => setDialogErrors({
                    data: [],
                    open: false
                })}
            />
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={1000}
                onClose={handleCloseResponseStatus}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseResponseStatus}
                    severity={responseStatus.severity}
                >
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}

