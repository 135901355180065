import React, { useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Button,
    Grid
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import FilterListIcon from '@material-ui/icons/FilterList';
import Field from '../Field';
import { MarketplaceSelect } from '../DataSelect';

function FilterContainer(props) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    return (
        <Accordion
            expanded={expanded}
        >
            <AccordionSummary
                expandIcon={<FilterListIcon />}
                aria-controls="filter-content"
                id="filter-header"
                onClick={() => setExpanded(!expanded)}
            >
                {props.title}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    {props.filters.map((filter, index) => (
                        <Grid item key={'filter-' + index} xs={12} md={6} lg={4}>
                            {typeof (filter.dataSelect) !== 'undefined' && filter.dataSelect === 'marketplace' &&
                                <MarketplaceSelect
                                    {...filter}
                                />
                            }
                            {typeof (filter.dataSelect) === 'undefined' && <Field {...filter} />}
                        </Grid>
                    ))}
                </Grid>

            </AccordionDetails>
            <AccordionActions>
                <Button size="small" color="primary" onClick={props.handleApplyFilters}>{t('Apply')}</Button>
                <Button
                    size="small"
                    color="secondary"
                    onClick={(e) => {
                        props.handleDismissFilters(e);
                        setExpanded(false);
                    }}
                >
                    {t('Dismiss')}
                </Button>
            </AccordionActions>
        </Accordion>
    );
}

export default FilterContainer;