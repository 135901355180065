import React, { useEffect, useMemo, useCallback, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

import {
    Paper,
    Grid,
    Box,
    Snackbar,
    Tabs,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    FormGroup,
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { makeStyles } from '@material-ui/core/styles';

import { Button } from '../../components/CustomUI';
import Switch from '../../components/Switch';
import { Tab, TabPanel } from '../../components/Tabs';

import { Alert } from '@material-ui/lab';

import ConfirmationDialog from '../../components/ConfirmationDialog';
import Field from '../../components/Field';
import Loading from '../../components/Loading'

import UserService from '../../services/User';
import MenuService from '../../services/Menu';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '1rem'
    }
}))

export default function UserEdit() {
    const classes = useStyles();
    const auth = useAuth();
    const UserModel = useMemo(() => new UserService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const MenuModel = useMemo(() => new MenuService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const { t } = useTranslation();
    const userId = useParams().id;

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({
        accessControl: {}
    });
    const [menus, setMenus] = useState([]);
    const [tab, setTab] = useState(0);

    const [remove, setRemove] = useState({
        open: false,
        name: "",
        id: "",
    });

    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })

    const retrieveUser = useCallback((id) => {
        UserModel.retrieve(id).then(result => {
            if (!result.accessControl) result.accessControl = {};

            setUser(result);
            setLoading(false);
        })
    }, [UserModel])

    const listMenus = useCallback(() => {
        MenuModel.list(0, 1000, "&active=true").then(result => {
            setMenus(result.docs);
            setLoading(false);
        })
    }, [MenuModel])

    useEffect(() => {
        auth.appTitle(t('Edit user'));
        if (userId !== 'new') {
            retrieveUser(userId);
        } else {
            setLoading(false);
        }

        listMenus();
    }, [t, retrieveUser, listMenus, userId, auth]);

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const handleChangeField = (event) => {
        const updateUser = { ...user };
        updateUser[event.target.name] = event.target.dataset?.value ? event.target.dataset?.value : event.target.value;
        setUser(updateUser);
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleDeleteUser = (event) => {
        UserModel.delete(remove.id).then(() => {
            window.location.href = '/users';
        }).catch((error) => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        });
    };

    const handleSaveUser = (event) => {
        const htmlFields = document.getElementsByTagName('input');
        let isValid = true;
        for (let index = 0; index < htmlFields.length; index++) {
            const field = htmlFields[index];
            if (!!field.required && (field.value === '' || field.value === null)) {
                isValid = false;
                field.focus();
                field.blur();
                break;
            }
        }

        if (isValid) saveUser();
    }

    const saveUser = () => {
        setLoading(true);

        if (userId === 'new') {
            UserModel.create(user).then(result => {
                setLoading(false);
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                setTimeout(() => {
                    window.location.href = '/users';
                }, 500)
            }).catch(error => {
                setLoading(false);

                let message = '';

                if (error.errors) {
                    let prim = true;
                    Object.values(error.errors).forEach(field => {
                        message += (!prim ? ' | ' : '') + field.message;
                        prim = false;
                    });
                } else if (error.message) {
                    message = error.message;
                } else {
                    message = JSON.stringify(error);
                }
                
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: message
                });
            })
        } else {
            console.log(user);
            UserModel.update(userId, user).then(result => {
                setUser(result);
                setLoading(false);
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });
            }).catch(error => {
                setLoading(false);

                let message = '';

                if (error.errors) {
                    let prim = true;
                    Object.values(error.errors).forEach(field => {
                        message += (!prim ? ' | ' : '') + field.message;
                        prim = false;
                    });
                } else if (error.message) {
                    message = error.message;
                } else {
                    message = JSON.stringify(error);
                }

                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: message
                });
            })
        }
    }

    const handleChangePermit = (event) => {
        const updateUser = { ...user };
        updateUser.accessControl[event.target.name] = event.target.checked;

        console.log(updateUser);

        setUser(updateUser);
    }

    const handleChangeSelect = (event) => {
        const updateUser = { ...user };
        updateUser.accessControl[event.target.name] = event.target.value;
        setUser(updateUser);
    }

    const permit = (action, menu) => {
        if (action.type === 'select') {
            return (
                <Grid item sm={6} md={4} lg={3}>
                    <FormControl fullWidth size="small">
                        <Select
                            name={menu.id + '-' + action.label.replace(/[" "]/gi, "-")}
                            labelid={menu.id + '-' + action.label + "-label"}
                            size="small"
                            value={user.accessControl[menu.id + '-' + action.label.replace(/[" "]/gi, "-")]}
                            onChange={handleChangeSelect}
                            fullWidth
                            disabled={auth.user.accessControl["users-Edit"] === false}
                        >
                            {action.options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            )
        }

        if (action.type === 'checkbox') {
            return (
                <Grid item sm={6} md={4} lg={3}>
                    <FormControlLabel
                        checked={!!user.accessControl[menu.id + '-' + action.label.replace(/[" "]/gi, "-")]}
                        control={<Checkbox name={menu.id + '-' + action.label.replace(/[" "]/gi, "-")} color="primary" />}
                        label={t(action.label)}
                        onChange={handleChangePermit}
                        disabled={auth.user.accessControl["users-Edit"] === false}
                    />
                </Grid>
            )
        }
    }

    const active = (action, label, id) => {
        if (action.type === 'switch') {
            return (
                <Grid item xs={12} key={"action-" + id}>
                    <FormControlLabel
                        control={
                            <Switch
                                name={id}
                                color="primary"
                                checked={!!user.accessControl[id]}
                            />
                        }
                        label={t(action.label)}
                        onChange={handleChangePermit}
                        disabled={auth.user.accessControl["users-Edit"] === false}
                    />
                </Grid>
            )
        }
    }

    const subMenu = (parent) => (
        <Grid item xs={12}>
            <List style={{ width: '100%' }}>
                {parent?.accessControl?.map((acao, index) => permit(acao))}
                {menus?.filter(x => x.parent === parent.id).map((menu, index) => (
                    <Fragment key={"submenu-" + menu.id + "-" + index}>
                        <ListItem>
                            <ListItemText style={{ minWidth: '150px', paddingRight: '30px', overflowWrap: 'break-word' }}>{t(menu.label)}</ListItemText>
                            <ListItemText>
                                <FormGroup row>
                                    <Grid container spacing={1}>
                                        {menu.accessControl?.map(action => permit(action, menu))}
                                    </Grid>
                                </FormGroup>
                            </ListItemText>
                        </ListItem>
                        <Divider />
                    </Fragment>
                ))}
            </List>
        </Grid>
    )

    return (!loading ?
        <React.Fragment>
            <Paper square>
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label={t('Profile')} index={0} />
                    <Tab label={t('Access Control')} index={1} />
                </Tabs>
                <TabPanel value={tab} index={0}>
                    <Grid container spacing={1} style={{ padding: '0.5rem 1rem' }}>
                        <Grid item xs={12}>
                            <Field
                                type="select"
                                required
                                name="active"
                                label={t("Active")}
                                value={!!user.active}
                                onChange={handleChangeField}
                                options={[{
                                    label: t('Yes'),
                                    value: true
                                },
                                {
                                    label: t('No'),
                                    value: false
                                }]}
                                fullWidth={false}
                                readOnly={auth.user.accessControl["users-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                type="text"
                                required
                                name="name"
                                label={t("Name")}
                                defaultValue={user.name}
                                onChange={handleChangeField}
                                readOnly={auth.user.accessControl["users-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}></Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                type="text"
                                required
                                name="email"
                                label={t("Email")}
                                defaultValue={user.email}
                                onChange={handleChangeField}
                                readOnly={auth.user.accessControl["users-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}></Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                type="password"
                                required={userId === 'new'}
                                name="password"
                                label={t("Password")}
                                defaultValue={""}
                                onChange={handleChangeField}
                                disabled={auth.user.accessControl["users-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}></Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <div className={classes.container}>
                        {menus?.filter(x => x.parent === null).map((menu, index) => (
                            <Accordion key={"menu-" + menu.id + "-" + index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <FontAwesomeIcon icon={menu.icon} style={{ width: '40px' }} />
                                    {t(menu.label)}
                                </AccordionSummary>
                                <AccordionDetails style={{ overflow: 'auto' }}>
                                    <Grid container spacing={1}>
                                        {menu?.accessControl?.map((action, index) => active(action, menu.label, menu.id))}
                                        {subMenu(menu)}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </TabPanel>
            </Paper>
            <Box textAlign="center" mt={2}>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    width={100}
                    mr={5}
                    onClick={handleSaveUser}
                    disabled={auth.user.accessControl["users-Edit"] === false}
                >
                    {t('Save')}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    width={100}
                    onClick={() => {
                        setRemove({
                            open: true,
                            name: user.name,
                            id: user._id
                        });
                    }}
                    disabled={auth.user.accessControl["users-Delete"] === false}
                >
                    {t('Delete')}
                </Button>
            </Box>
            <ConfirmationDialog
                title={t('Are you sure to remove this user?')}
                confirmTitle={t('Yes')}
                confirm={handleDeleteUser}
                cancelTitle={t('No')}
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
            >
                {remove.name}
            </ConfirmationDialog>
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={3000}
                onClose={handleCloseResponseStatus}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
        : <Loading open={loading} />
    );
}