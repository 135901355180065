import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Divider
} from '@material-ui/core';

function Errors(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <List dense>
                    {props.data && props.data.length > 0 && props.data.map((item, index) => {
                        if (item.cause) {

                            return <React.Fragment key={'causes'}>
                                {item.cause.map((cause, index) => {
                                    return <React.Fragment key={index}>
                                        <ListItem>
                                            <ListItemText
                                                primary={cause.message}
                                            />
                                        </ListItem>
                                        <Divider component="li" />
                                    </React.Fragment>
                                })}
                            </React.Fragment>
                        } else {
                            return <React.Fragment key={index}>
                                <ListItem>
                                    <ListItemText
                                        primary={JSON.stringify(item)}
                                    />
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        }
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    size="small"
                    onClick={props.confirm}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Errors;