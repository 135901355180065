import React, { useState, useEffect, useMemo, useCallback } from 'react';

import {
    IconButton,
    Grid,
    Snackbar,
    Chip
} from '@material-ui/core'

import {
    Alert,
} from '@material-ui/lab'

import {
    Button
} from '../../components/CustomUI';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import Loading from '../../components/Loading'

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import UserService from '../../services/User';

export default function UserList() {
    const auth = useAuth();
    const UserModel = useMemo(() => new UserService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const { t } = useTranslation();
    const [filters, setFilters] = useState({
        search: ''
    });
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        sort: '&sort[name]=1',
        filters: ''
    });
    const [remove, setRemove] = useState({
        open: false,
        name: "",
        id: "",
    });

    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success",
    });

    const userList = useCallback((page = 0, size = 30, filters = '', sort = '') => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        UserModel.list(page, size, filters, sort).then(result => {
            setData({
                isLoading: false,
                error: null,
                docs: result.docs,
                page: result.page - 1,
                size: result.limit,
                count: result.totalDocs,
                sort: sort,
                filters: filters
            })
        })

    }, [UserModel])

    useEffect(() => {
        auth.appTitle(t('Users'));
        userList(data.page, data.size, data.filters, data.sort)
    }, [t, userList, data.page, data.size, data.filters, data.sort, auth]);

    const handleKeyUpSearch = (event) => {
        setFilters({
            ...filters,
            search: event.target.value,
        });

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = "";

        if (filters.search) {
            qs += "&$or[0][name][$regex]=" + filters.search;
            qs += "&$or[0][name][$options]=gi";
            qs += "&$or[1][email][$regex]=" + filters.search;
            qs += "&$or[1][email][$options]=gi";
        }

        setData({
            ...data,
            filters: qs
        });
    }

    const handleDismissFilters = () => {
        setFilters({
            search: ''
        })
        setData({
            ...data,
            filters: ''
        });
    }

    const filterOptions = [
        {
            type: "text",
            id: "search",
            name: "search",
            label: t('Search by name or email'),
            defaultValue: filters.search,
            onKeyUp: handleKeyUpSearch,
            onBlur: (e) => {
                setFilters({ ...filters, search: e.target.value });
            }
        }
    ];

    const columns = [
        {
            id: 'name',
            label: t('Name'),
            align: 'left'
        },
        {
            id: 'active',
            label: t('Active'),
            align: 'center',
            type: 'hybrid',
            format: (doc) => doc.active ? (
                <Chip clickable size="small" color="primary" onClick={e => handleChangeActive(e, doc, false)} icon={<DoneIcon />} label={t("Yes")} />
            ) : (
                <Chip clickable size="small" color="secondary" onClick={e => handleChangeActive(e, doc, true)} icon={<CloseIcon />} label={t("No")} />
            )
        },
        {
            id: 'id',
            label: t('Actions'),
            align: 'center',
            type: 'hybrid',
            format: (doc, index) => (
                <div>
                    <IconButton 
                        size="small" 
                        title={auth.user.accessControl["users-Edit"] === true ? t('Edit') : t('View')} 
                        aria-label={t('Edit')} 
                        href={'/users/' + doc._id} 
                        color="primary"
                    >
                        {auth.user.accessControl["users-Edit"] === true && <EditIcon fontSize="small" />}
                        {auth.user.accessControl["users-Edit"] === false && <ViewIcon fontSize="small" />}
                    </IconButton>
                    <IconButton 
                        size="small" 
                        title={t('Delete')} 
                        aria-label={t('Delete')} 
                        onClick={e => handleClickDelete(e, doc._id, doc.name)} 
                        color="secondary"
                        disabled={auth.user.accessControl["users-Delete"] === false}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            )
        }
    ];

    const handleChangeActive = (e, user, active) => {
        setData({
            ...data,
            isLoading: true
        });

        const updatedUser = { ...user }
        updatedUser.active = active;

        UserModel.update(user._id, updatedUser).then(result => {
            const updateData = { ...data };

            const index = updateData.docs.findIndex(x => x._id === result._id);
            updateData.docs[index] = result;
            updateData.isLoading = false;
            setData(updateData);
        }).catch((error) => {
            setData({
                ...data,
                isLoading: false
            });
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        });
    }

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id,
        });
    };

    const handleDeleteUser = (event) => {
        UserModel.delete(remove.id).then(() => {
            setRemove({
                open: false,
                name: "",
                id: "",
            });
            setResponseStatus({
                open: true,
                severity: "success",
                message: t('User removed successfully')
            });

            userList(data.page, data.size, data.filters, data.sort)
        }).catch((error) => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        });
    };

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success",
        });
    };

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            page: 0,
            size: +event.target.value
        })
    }

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={8} md={10} lg={11}>
                <FilterContainer
                    title={t('Filters')}
                    filters={filterOptions}
                    handleApplyFilters={handleApplyFilters}
                    handleDismissFilters={handleDismissFilters}
                />
            </Grid>
            <Grid item sm={4} md={2} lg={1} style={{ alignSelf: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{
                        height: '48px'
                    }}
                    startIcon={<AddIcon />}
                    href="/users/new"
                    disabled={auth.user.accessControl["users-New"] === false}
                >
                    {t('New')}
                </Button>
            </Grid>
            {!isLoading && docs ?
                <Grid item xs={12}>
                    <DataTable
                        columns={columns}
                        docs={docs}
                        page={page}
                        size={size}
                        count={count}
                        error={error}
                        handleChangePage={handleChangePage}
                        handleChangeSize={handleChangeSize}
                    />
                </Grid>
                :
                <Loading open={isLoading} />
            }
            <ConfirmationDialog
                title={t('Are you sure to remove this user?')}
                confirmTitle={t('Yes')}
                confirm={handleDeleteUser}
                cancelTitle={t('No')}
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
            >
                {remove.name}
            </ConfirmationDialog>
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={3000}
                onClose={handleCloseResponseStatus}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseResponseStatus}
                    severity={responseStatus.severity}
                >
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}