import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../AuthContext';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ListItem,
    ListItemText,
    CircularProgress
} from '@material-ui/core';

import { TreeView, TreeItem } from '@material-ui/lab';

import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';

import CategoryService from '../../services/Category';

export default function Categories(props) {
    const { t } = useTranslation();
    const auth = useAuth();
    const CategoryModel = useMemo(() => new CategoryService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);

    const { open, onClose, confirm, cancel, marketplace, handleSelectCategory } = props;

    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const handleSelectedNode = (event, nodeId) => {
        const newCategories = [...categories];
        const newExpanded = [...expanded];

        const aux = newCategories.findIndex(x => x.id === nodeId);
        newCategories[aux].children_categories = categories.filter(x => x.parent === nodeId);

        if (newCategories[aux].children_categories.length > 0) {
            setCategories(newCategories);

            let nodeIndex = newExpanded.findIndex(x => x === nodeId);

            if (nodeIndex >= 0) {
                newExpanded.splice(nodeIndex, 1);
            } else {
                newExpanded.push(nodeId);
            }

            setExpanded(newExpanded);
        } else {
            const selectedNode = categories.find(x => x.id === nodeId);
            handleSelectCategory(selectedNode);
        }
    }

    const listCategories = useCallback(() => {
        if (marketplace) {
            CategoryModel.list(0, 50000, "&marketplace=" + marketplace).then(result => {
                setCategories(result.docs);
                setLoading(false);
            }).catch(error => {
                console.log(error)
            })
        }
    }, [CategoryModel, marketplace])

    const renderTree = (category) => (
        <TreeItem key={category._id} nodeId={category.id} label={category.name}>
            {Array.isArray(category.children_categories) ? category.children_categories.map((children) => renderTree(children)) : ''}
        </TreeItem>
    );

    useEffect(() => {
        listCategories();
    }, [listCategories]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">{t('Select category')}</DialogTitle>
            <DialogContent>
                <TreeView
                    defaultCollapseIcon={<FolderOpenIcon color="primary" />}
                    defaultEndIcon={<FolderIcon color="primary" />}
                    defaultExpandIcon={<FolderIcon color="primary" />}
                    style={{
                        height: 'calc(100vh - 316px)',
                        overflow: 'auto',
                        padding: '11px'
                    }}
                    onNodeSelect={handleSelectedNode}
                    expanded={expanded}
                >
                    {!loading && categories?.filter(x => typeof (x.parent) === 'undefined').map((category, index) =>
                        renderTree(category)
                    )}
                    {loading && (
                        <ListItem>
                            <ListItemText style={{ textAlign: 'center', margin: '0 auto !important' }}>
                                <CircularProgress />
                            </ListItemText>
                        </ListItem>
                    )}
                </TreeView>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={confirm}
                >
                    {t('Confirm')}
                </Button>
                <Button onClick={cancel}>{t('Cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}