import React from 'react';

function MarketplaceLogo(props) {
    const imageUrl = process.env.PUBLIC_URL;
    
    let marketplaceTitle;
    let marketplaceLogo;
    let sellerName;
    let listingType;

    switch (props.marketplace) {
        case 7:
            marketplaceTitle = 'Mercado Livre';
            marketplaceLogo = imageUrl + '/mercado_livre.png';
            sellerName = props.sellerName;
            listingType = props.listingType ? (props.listingType === 'gold_special' ? 'Clássico' : 'Premium') : '';
            break;

        case 11:
            marketplaceTitle = 'Magento';
            marketplaceLogo = imageUrl + '/magento.png';
            break;

        case 12:
            marketplaceTitle = 'Empório Bud';
            marketplaceLogo = imageUrl + '/emporio_bud.png';
            break;

        case 13:
            marketplaceTitle = 'GPA';
            marketplaceLogo = imageUrl + '/gpa.png';
            break;

        case 14:
            marketplaceTitle = 'OmniChat';
            marketplaceLogo = imageUrl + '/omnichat.png';
            break;
    
        case 15:
            marketplaceTitle = 'Shopee';
            marketplaceLogo = imageUrl + '/shopee.png';
            break;
    
        default:
            marketplaceTitle = props.marketplace;
            marketplaceLogo = imageUrl + '/no-image.png';
            break;
    }

    return (
        <div style={{ textAlign: props.align, lineHeight: '0.8rem' }}>
            <img alt={marketplaceTitle} src={marketplaceLogo} height="30" />
            {sellerName && <><br /><small>{sellerName}</small></>}
            {listingType && <><br /><small>{listingType}</small></>}
        </div>
    )
}

export default MarketplaceLogo;