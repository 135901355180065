import { withStyles } from "@material-ui/styles";
import MuiButton from "@material-ui/core/Button";
import MuiChip from "@material-ui/core/Chip";

const Button = withStyles(theme => ({
    root: props => {
        const format = {};

        if (props.width) {
            format.width = props.width;
        }

        if (props.mr) {
            format.marginRight = props.mr;
        }

        if (props.mt) {
            format.marginTop = props.mt;
        }

        if (props.m) {
            format.margin = props.m;
        }

        return format;
    }
}))(MuiButton);

const CustomChip = withStyles(theme => ({
    root: props => {
        const format = {}

        format.borderRadius = 0;
        format.color = theme.palette[props.customcolor].contrastText;
        format.backgroundColor = theme.palette[props.customcolor].main;
        format.minWidth = '100px';
        format.fontSize = '0.7rem';
        if (props.onClick) {
            format['&:hover'] = {
                backgroundColor: theme.palette[props.customcolor].dark
            };
            format['&:active'] = {
                backgroundColor: theme.palette[props.customcolor].dark
            }
        }
        return format;
    },
    clickable: props => {
        const format = {}

        if (props.onClick) {
            format['&:focus'] = {
                backgroundColor: theme.palette[props.customcolor].dark
            }
        }
        return format;
    }
}))(MuiChip);

export { Button, CustomChip };