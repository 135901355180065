import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    IconButton,
    Grid,
    Snackbar
} from '@material-ui/core'

import {
    Alert,
} from '@material-ui/lab'

import MarketplaceLogo from '../../components/MarketplaceLogo';

import {
    Button
} from '../../components/CustomUI';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import Loading from '../../components/Loading'

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';

import AttributeMappingService from '../../services/AttributeMapping';

export default function AttributeMappingList() {
    const auth = useAuth();
    const AttributeMappingModel = useMemo(() => new AttributeMappingService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const { t } = useTranslation();
    const [filters, setFilters] = useState({
        search: ''
    });
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: ''
    });
    const [remove, setRemove] = useState({
        open: false,
        name: "",
        id: "",
    });

    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success",
    });

    const categoryList = useCallback((page = 0, size = 30, filters = '') => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        AttributeMappingModel.listCategories(page, size, filters).then(result => {
            setData({
                isLoading: false,
                error: null,
                docs: result.docs,
                page: result.page - 1,
                size: result.limit,
                count: result.totalDocs,
                filters: filters
            })
        })

    }, [AttributeMappingModel])

    useEffect(() => {
        auth.appTitle(t('Attribute Mapping'));
        categoryList(data.page, data.size, data.filters)
    }, [t, categoryList, data.page, data.size, data.filters, auth]);

    const handleKeyUpSearch = (event) => {
        setFilters({
            ...filters,
            search: event.target.value,
        });

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = "";

        if (filters.search) {
            qs += "&$or[0][categoryName][$regex]=" + filters.search;
            qs += "&$or[0][categoryName][$options]=gi";
        }

        setData({
            ...data,
            filters: qs
        });
    }

    const handleDismissFilters = () => {
        setFilters({
            search: ''
        })
        setData({
            ...data,
            filters: ''
        });
    }

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id,
        });
    };

    const handleDeleteMapping = (event) => {
        AttributeMappingModel.delete(remove.id).then(() => {
            setRemove({
                open: false,
                name: "",
                id: "",
            });
            setResponseStatus({
                open: true,
                severity: "success",
                message: t('Product removed successfully')
            });

            categoryList(data.page, data.size, data.filters)
        }).catch((error) => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        });
    };

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success",
        });
    };

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            page: 0,
            size: +event.target.value
        })
    }

    const filterOptions = [
        {
            type: "text",
            id: "search",
            name: "search",
            label: t('Search'),
            defaultValue: filters.search,
            onKeyUp: handleKeyUpSearch,
            onBlur: (e) => {
                setFilters({ ...filters, search: e.target.value });
            }
        }
    ];

    const columns = [
        {
            id: 'marketplace',
            label: t('Marketplace'),
            align: 'center',
            type: 'hybrid',
            format: (attributeMapping) => <MarketplaceLogo align="center" marketplace={attributeMapping.marketplace} />
        },
        {
            id: 'category',
            label: t('Category'),
            align: 'left'
        },
        {
            id: 'categoryName',
            label: t('Name'),
            align: 'left'
        },
        {
            id: 'id',
            label: t('Actions'),
            align: 'center',
            type: 'hybrid',
            format: (category) => (
                <div style={{ minWidth: '100px' }}>
                    <IconButton size="small" title={auth.user.accessControl["attribute_mappings-Edit"] === true ? t('Edit') : t('View')} aria-label={t('Edit')} href={'/attribute_mappings/' + category.marketplace+'/'+category.category} color="primary">
                        {auth.user.accessControl["attribute_mappings-Edit"] === true && <EditIcon fontSize="small" />}
                        {auth.user.accessControl["attribute_mappings-Edit"] === false && <ViewIcon fontSize="small" />}
                    </IconButton>
                    <IconButton size="small" title={t('Delete')} aria-label={t('Delete')} onClick={handleClickDelete} color="secondary" disabled={auth.user.accessControl["attribute_mappings-Delete"] === false}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            )
        }
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={8} md={10} lg={11}>
                <FilterContainer
                    title={t('Filters')}
                    filters={filterOptions}
                    handleApplyFilters={handleApplyFilters}
                    handleDismissFilters={handleDismissFilters}
                />
            </Grid>
            <Grid item sm={4} md={2} lg={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        height: '48px'
                    }}
                    fullWidth
                    href="/attribute_mappings/new"
                    disabled={auth.user.accessControl["attribute_mappings-New"] === false}
                >
                    <AddIcon /> {t('New')}
                </Button>
            </Grid>
            {!isLoading && docs ?
                <Grid item xs={12}>
                    <DataTable
                        columns={columns}
                        docs={docs}
                        page={page}
                        size={size}
                        count={count}
                        error={error}
                        handleChangePage={handleChangePage}
                        handleChangeSize={handleChangeSize}
                    />
                </Grid>
                :
                <Loading open={isLoading} />
            }
            <ConfirmationDialog
                title={t('Are you sure to remove this mapping?')}
                confirmTitle={t('Yes')}
                confirm={handleDeleteMapping}
                cancelTitle={t('No')}
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
            >
                {remove.name}
            </ConfirmationDialog>
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={3000}
                onClose={handleCloseResponseStatus}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseResponseStatus}
                    severity={responseStatus.severity}
                >
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}

