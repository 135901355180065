import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useAuth } from "./AuthContext";

import Home from "./pages/Home";
import TermsAndConditions from "./pages/TermsAndConditions";
import Dashboard from "./pages/Dashboard";
import UserList from "./pages/Users/List";
import UserEdit from "./pages/Users/Edit";
import ProductList from "./pages/Products/List";
import ProductEdit from "./pages/Products/Edit";
import AdList from "./pages/Ads/List";
import AdEdit from "./pages/Ads/Edit";
import OrderList from "./pages/Orders/List";
import OrderEdit from "./pages/Orders/Edit";
import StoreEdit from "./pages/Stores/Edit";
import Categorization from "./pages/Categorization";
import AttributeMappingList from "./pages/AttributeMapping/List";
import AttributeMappingEdit from "./pages/AttributeMapping/Edit";

import MainContainer from './components/MainContainer';

const Routes = () => {
	const auth = useAuth();
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/dashboard">
					{auth.user?.accessControl["dashboard"] === false && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><Dashboard /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/products">
					{(auth.user?.accessControl["products-View"] === false || auth.user?.accessControl["catalog"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><ProductList /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/products/:id">
					{(auth.user?.accessControl["products-View"] === false || auth.user?.accessControl["catalog"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><ProductEdit /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/ads">
					{(auth.user?.accessControl["ads-View"] === false || auth.user?.accessControl["catalog"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><AdList /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/ads/new">
					{(auth.user?.accessControl["ads-View"] === false || auth.user?.accessControl["catalog"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><AdEdit /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/ads/:sku/:marketplace">
					{(auth.user?.accessControl["ads-View"] === false || auth.user?.accessControl["catalog"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><AdEdit /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/categorization">
					{(auth.user?.accessControl["categorizaion-View"] === false || auth.user?.accessControl["categorization"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><Categorization /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/orders">
					{(auth.user?.accessControl["orders-View"] === false || auth.user?.accessControl["sales"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><OrderList /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/orders/:id">
					{(auth.user?.accessControl["orders-View"] === false || auth.user?.accessControl["sales"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><OrderEdit /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/store">
					{(auth.user?.accessControl["store-View"] === false || auth.user?.accessControl["settings"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><StoreEdit /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/users">
					{(auth.user?.accessControl["users-View"] === false || auth.user?.accessControl["settings"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><UserList /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/users/:id">
					{(auth.user?.accessControl["users-View"] === false || auth.user?.accessControl["settings"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><UserEdit /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/attribute_mappings">
					{(auth.user?.accessControl["attribute_mappings-View"] === false || auth.user?.accessControl["catalog"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><AttributeMappingList /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/attribute_mappings/new">
					{(auth.user?.accessControl["attribute_mappings-View"] === false || auth.user?.accessControl["catalog"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><AttributeMappingEdit /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/attribute_mappings/:marketplace/:category">
					{(auth.user?.accessControl["attribute_mappings-View"] === false || auth.user?.accessControl["catalog"] === false) && <Redirect to="/" />}
					{auth.authenticated === true && <MainContainer><AttributeMappingEdit /></MainContainer>}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route exact path="/terms-and-conditions">
					<TermsAndConditions />
				</Route>
				<Route exact path="/">
					{auth.authenticated === true && <Redirect to="/dashboard" />}
					{auth.authenticated === false && <Home />}
				</Route>
				<Route path="*">
					<Redirect to="/dashboard" />
				</Route>
			</Switch>
		</BrowserRouter >
	)
};
export default Routes;