import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
} from '@material-ui/core';

import Field from '../../components/Field';

function EditAddress(props) {
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});

    const fieldValidation = (event) => {
        const validation = event.target.dataset.validation ? JSON.parse(event.target.dataset.validation) : null;
        const updatedErrors = { ...errors };
        delete updatedErrors[event.target.name];

        if (event.target.required && event.target.value.trim() === '') {
            updatedErrors[event.target.name] = t('Required field');
        }

        if (validation) {
            switch (validation.type) {
                case "pattern":
                    const match = new RegExp(validation.match);
                    if (!match.test(event.target.value)) updatedErrors[event.target.name] = validation.message;
                    break;

                default:
                    break;
            }
        }

        setErrors(updatedErrors);
    }

    const handleSubmit = (event) => {
        const htmlFields = document.getElementsByTagName('input');
        let isValid = true;
        for (let index = 0; index < htmlFields.length; index++) {
            const field = htmlFields[index];
            if (!!field.required && (field.value === '' || field.value === null)) {
                isValid = false;
                field.focus();
                field.blur();
                break;
            }
        }

        if (isValid) props.confirm();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle id="form-dialog-title">{props.type === 'billing' ? t('Billing Address') : t('Shipping Address')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {props.type === 'billing' &&
                        <Grid item xs={12}>
                            <Field
                                type="text"
                                required
                                name="vatNumber"
                                label={t("VAT Number")}
                                defaultValue={props.data.vatNumber}
                                onChange={props.handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.vatNumber}
                                helperText={errors?.vatNumber}
                                fullWidth={false}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} md={5}>
                        <Field
                            type="text"
                            required
                            name="name"
                            label={t("Name")}
                            defaultValue={props.data.name}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.name}
                            helperText={errors?.name}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            type="text"
                            required
                            name="email"
                            label={t("Email")}
                            defaultValue={props.data.email}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.email}
                            helperText={errors?.email}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field
                            type="text"
                            required
                            name="phone"
                            label={t("Phone Number")}
                            defaultValue={props.data.phone}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.phone}
                            helperText={errors?.phone}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            type="text"
                            required
                            name="zipCode"
                            label={t("Zip Code")}
                            defaultValue={props.data.zipCode}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.zipCode}
                            helperText={errors?.zipCode}
                            fullWidth={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            type="text"
                            required
                            name="streetName"
                            label={t("Street Name")}
                            defaultValue={props.data.streetName}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.streetName}
                            helperText={errors?.streetName}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Field
                            type="text"
                            required
                            name="streetNumber"
                            label={t("Street Number")}
                            defaultValue={props.data.streetNumber}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.streetNumber}
                            helperText={errors?.streetNumber}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Field
                            type="text"
                            name="streetComplement"
                            label={t("Street Complement")}
                            defaultValue={props.data.streetComplement}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.streetComplement}
                            helperText={errors?.streetComplement}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            type="text"
                            required
                            name="district"
                            label={t("District")}
                            defaultValue={props.data.district}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.district}
                            helperText={errors?.district}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            type="text"
                            required
                            name="city"
                            label={t("City")}
                            defaultValue={props.data.city}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.city}
                            helperText={errors?.city}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            type="text"
                            required
                            name="state"
                            label={t("State")}
                            defaultValue={props.data.state}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.state}
                            helperText={errors?.state}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    {t('Confirm')}
                </Button>
                <Button onClick={props.cancel}>{t('Cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditAddress;