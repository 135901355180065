import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Card,
    CardHeader,
    Divider,
    Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { ProductSelect } from '../../components/DataSelect';

import ProductService from '../../services/Product';

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 400,
        height: 400,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

export default function AddProducts(props) {
    const auth = useAuth();
    const ProductModel = useMemo(() => new ProductService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const { t } = useTranslation();
    const classes = useStyles();
    const { parentSku, sku, products, setProducts, variants, setVariants } = props;

    const handleSelectVariant = (event, variant) => {
        if (variant) {
            const updateVariants = [...variants ];

            updateVariants.push({
                id: variant.id,
                active: variant.active,
                sku: variant.sku,
                title: variant.title,
                description: variant.description,
                thumbnail: variant.thumbnail,
                images: variant.images,
                attributes: variant.attributes,
                width: variant.width,
                height: variant.height,
                length: variant.length,
                weight: variant.weight,
                stock: variant.stock,
                price: variant.price,
                salePrice: variant.salePrice > 0 ? variant.salePrice : null,
                saleDateStart: variant.saleDateStart,
                saleDateEnd: variant.saleDateEnd,
                marketplaceStatus: 1
            })

            setVariants(updateVariants);
        }
    }

    const customList = (title, products) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={props.handleToggleAll(products)}
                        checked={props.numberOfChecked(products) === products.length && products.length !== 0}
                        indeterminate={props.numberOfChecked(products) !== products.length && props.numberOfChecked(products) !== 0}
                        disabled={products.length === 0}
                        inputProps={{ 'aria-label': t('all items selected') }}
                        color="primary"
                    />
                }
                title={title}
                subheader={`${props.numberOfChecked(products)}/${products.length} ` + t('selected')}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {products.map((product) => {
                    const labelId = `transfer-list-all-item-${product.id}-label`;

                    return (
                        <ListItem key={product.id} role="listitem" button onClick={props.handleToggle(product)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={props.checked.indexOf(product) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    color="primary"
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={product.title} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    useEffect(() => {
        async function listMatches() {
            if (parentSku) {
                const matches = await ProductModel.listMatches(parentSku).then(res => {
                    return res.docs;
                }).catch(error => {
                    console.log(error);
                })

                if (matches) {
                    const products = [];

                    for (let index = 0; index < matches.length; index++) {
                        const match = matches[index];
                        if (!variants.find(x => x.sku === match.sku)) {
                            products.push({
                                id: match._id,
                                active: match.active,
                                sku: match.sku,
                                title: match.title,
                                description: match.description,
                                thumbnail: match.thumbnail,
                                images: match.images,
                                attributes: match.attributes,
                                width: match.width,
                                height: match.height,
                                length: match.length,
                                weight: match.weight,
                                stock: match.stock,
                                price: match.price,
                                salePrice: match.salePrice > 0 ? match.salePrice : null,
                                saleDateStart: match.saleDateStart,
                                saleDateEnd: match.saleDateEnd,
                                marketplaceStatus: 1
                            })
                        }
                    }

                    setProducts(products, variants);
                }

            }
        }
        listMatches();
    }, [ProductModel, parentSku, sku, setProducts, variants, setVariants])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.root}
                >
                    <Grid item xs={12}>
                        <ProductSelect
                            required
                            id="product"
                            name="product"
                            label={t('Product')}
                            onChange={handleSelectVariant}
                        />
                    </Grid>
                    <Grid item xs={5}>{customList(t('Similar products'), products)}</Grid>
                    <Grid item xs={2}>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={props.handleCheckedRight}
                                disabled={props.productsChecked.length === 0}
                            >
                                &gt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={props.handleCheckedLeft}
                                disabled={props.variantsChecked.length === 0}
                            >
                                &lt;
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>{customList(t('Selected variantes'), variants)}</Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={props.confirm}
                >
                    {t('Confirm')}
                </Button>
                <Button onClick={props.cancel}>{t('Cancel')}</Button>

            </DialogActions>
        </Dialog>
    )
}