import React from 'react';
import { CustomChip } from '../CustomUI';
import { useTranslation } from 'react-i18next';

export default function AdStatus(props) {
    const { t } = useTranslation();
    let color = 'secondary';
    let label = 'Pending'

    switch (props.status) {
        case 1: color = 'primary'; label = 'Pending'; break;
        case 2: color = 'warning'; label = 'Analysing'; break;
        case 3: color = 'success'; label = 'Selling'; break;
        case 9: color = 'secondary'; label = 'Error'; break;
        default: break;
    }

    return <CustomChip {...props} size="small" customcolor={color} label={t(label)} />;
}