export default class Label {

    constructor(apiKey, accessToken) {
        this.apiKey = apiKey;
        this.accessToken = accessToken;
    }

    list(page = 0, size = 30, filters = '') {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/labels?page=' + (page + 1) + '&size=' + size + '&sort=DESC' + filters;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    findBySellerOrderId(sellerOrderId) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/labels?sellerOrderId=' + sellerOrderId;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data.docs);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    create(label) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/labels';
            const options = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(label)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    retrieve(id) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/labels/' + id;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    update(id, label) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/labels/' + id;
            const options = {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(label)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("x-api-key", this.apiKey);
            headers.append("x-access-token", this.accessToken);

            const url = '/api/labels/' + id;
            const options = {
                method: 'DELETE',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }
}