import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth } from '../../AuthContext';

import {
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useTranslation } from 'react-i18next';

import MenuService from '../../services/Menu';

const useStyles = makeStyles((theme) => ({
    menu: {
        fontSize: '0.8rem',
        color: 'white'
    },
    icon: {
        color: 'white',
        minWidth: '40px',
        fontSize: '1rem',
        justifyContent: 'center'
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
}));

export default function MainMenu(props) {
    const classes = useStyles();
    const auth = useAuth();
    const MenuModel = useMemo(() => new MenuService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState({
        catalog: false,
        sales: false,
        settings: false
    });
    const [menus, setMenus] = useState([]);

    const handleOpenMenu = (event, menu) => {
        const updateMenuOpen = { ...menuOpen };
        updateMenuOpen[menu] = !menuOpen[menu];
        setMenuOpen(updateMenuOpen);
    }
    
    const listMenus = useCallback(() => {
        MenuModel.list(0, 1000, "&active=true").then(result => {
            const menus = [];
            for (let index = 0; index < result.docs.length; index++) {
                const menu = result.docs[index];
                if (auth.user.accessControl[menu.id] === true || auth.user.accessControl[menu.id+'-View'] === true){
                    menus.push(menu);
                }
            }
            
            setMenus(menus);
        })
    }, [MenuModel, auth.user.accessControl])

    useEffect(() => {
        listMenus();
    }, [listMenus]);

    const mountMenu = (menu) => {
        return (
            <React.Fragment key={menu.id}>
                {menu.hasChildren &&
                    <>
                        <ListItem button onClick={event => handleOpenMenu(event, menu.id)}>
                            <ListItemIcon className={classes.icon}><FontAwesomeIcon icon={menu.icon} /></ListItemIcon>
                            <ListItemText
                                primary={t(menu.label)}
                                disableTypography
                            />
                            {menuOpen[menu.id] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={menuOpen[menu.id]} timeout="auto" unmountOnExit className={classes.nested} >
                            {menus.filter(x => x.parent === menu.id).map((submenu, index) => (
                                <ListItem button key={submenu.label} component={Link} href={submenu.url} color="inherit">
                                    <ListItemIcon className={classes.icon}><FontAwesomeIcon icon={submenu.icon} /></ListItemIcon>
                                    <ListItemText
                                        primary={t(submenu.label)}
                                        disableTypography
                                    />
                                </ListItem>
                            ))}
                        </Collapse>
                    </>
                }
                {(!menu.hasChildren && !menu.parent) &&
                    <ListItem button key={menu.id} component={Link} href={menu.url} color="inherit">
                        <ListItemIcon className={classes.icon}><FontAwesomeIcon icon={menu.icon} /></ListItemIcon>
                        <ListItemText
                            primary={t(menu.label)}
                            disableTypography
                        />
                    </ListItem>
                }
            </React.Fragment>
        )
    }

    return (
        <List className={classes.menu}>
            {menus && menus.map((menu, index) => mountMenu(menu))}
        </List>
    )
}