import React from 'react';
import Login from './Login';
import Register from './Register';
import Authorize from './Authorize';

function Home() {
    return (
        window.location.pathname === '/register' ? <Register /> : (window.location.pathname === '/authorize' ? <Authorize /> : <Login />)
    )
}

export default Home;