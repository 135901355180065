import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    TextField,
    CircularProgress,
    Button,
    Typography
} from "@material-ui/core";

import {
    Autocomplete
} from "@material-ui/lab";

import StoreService from "../../services/Store";

import { useAuth } from "../../AuthContext";

export default function StoreSelect() {
    const auth = useAuth();
    const StoreModel = useMemo(() => new StoreService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);

    const { t } = useTranslation();

    const [stores, setStores] = useState({
        open: false,
        options: [],
        loading: false
    });

    const [openStoreSelect, setOpenStoreSelect] = useState(false);

    const handleListStores = (event) => {
        let filters = "";

        if (event.target.value && event.target.value !== '') {
            filters += "&nickname[$regex]=" + event.target.value;
            filters += "&nickname[$options]=gi";
        }

        setStores({ ...stores, loading: true });

        StoreModel.list(0, 30, filters).then((response) => {
            setStores({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleSelectStore = (event, store) => {
        const storedUser = JSON.parse(localStorage.getItem("@marketplacesCompassio/user"));
        if (store) {
            storedUser.selectedStore = storedUser.stores.findIndex(x => x.storeId === store.id);
            localStorage.setItem("@marketplacesCompassio/user", JSON.stringify(storedUser));
            window.location.reload();
        }
    }

    return (
        openStoreSelect ?
            <Autocomplete
                id="selectStore"
                fullWidth
                noOptionsText={t("No stores found")}
                open={stores.open}
                onOpen={handleListStores}
                onClose={() => {
                    setStores({ ...stores, open: false });
                    setOpenStoreSelect(false);
                }}
                onChange={handleSelectStore}
                onKeyUp={handleListStores}
                getOptionSelected={(option, value) => option.id === value.storeId}
                getOptionLabel={(option) => option.nickname}
                filterOptions={x => x}
                options={stores.options}
                loading={stores.loading}
                defaultValue={auth.user?.stores[auth.user?.selectedStore]}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name="selectStore"
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {stores.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            : (
                auth.user?.stores.length > 1 ?
                    <Button color="inherit" aria-controls="storeSelect" style={{ height: '100%' }} onClick={(event) => { handleListStores(event); setOpenStoreSelect(true) }}>
                        <Typography variant="h6">
                            {auth.user?.stores[auth.user?.selectedStore].nickname}
                        </Typography>
                    </Button>
                    : <Typography variant="h6">
                        {auth.user?.stores[auth.user?.selectedStore].nickname}
                    </Typography>
            )
    )
}