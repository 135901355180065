import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import moment from 'moment';
import { useAuth } from '../../AuthContext';

import {
    Grid,
    Box,
    Snackbar,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Typography,
    Chip
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';

import {
    Alert
} from '@material-ui/lab'

import ConfirmationDialog from '../../components/ConfirmationDialog';
import Switch from '../../components/Switch';
import Field from '../../components/Field';
import Loading from '../../components/Loading';
import MarketplaceLogo from '../../components/MarketplaceLogo';
import { MarketplaceSelect, ProductSelect, SellerSelect } from '../../components/DataSelect';
import DataTable from '../../components/DataTable';
import AdStatus from '../../components/AdStatus';

import {
    Button
} from '../../components/CustomUI';

import NewVariant from './NewVariant';
import EditVariant from './EditVariant';
import Categories from './Categories';
import Errors from './Errors';

import AdService from '../../services/Ad';
import CategoryService from '../../services/Category';
import AttributeMappingService from '../../services/AttributeMapping';
import StoreService from '../../services/Store';

const useStyles = makeStyles({
    textBold: {
        fontWeight: 'bold'
    },
    active: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
    }
});

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((product) => b.indexOf(product) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function EditAd() {
    const classes = useStyles();
    const marketplace = useParams().marketplace;
    const parentId = useParams().sku;
    const queryString = window.location.search;
    const { t } = useTranslation();
    const auth = useAuth();
    const storeId = auth.user.stores[auth.user.selectedStore].storeId;
    const AdModel = useMemo(() => new AdService(storeId, auth.user.token), [storeId, auth]);
    const CategoryModel = useMemo(() => new CategoryService(storeId, auth.user.token), [storeId, auth]);
    const AttributteMappingModel = useMemo(() => new AttributeMappingService(storeId, auth.user.token), [storeId, auth]);
    const StoreModel = useMemo(() => new StoreService(storeId, auth.user.token), [storeId, auth]);
    const [loading, setLoading] = useState(true);
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const [ad, setAd] = useState({});
    const [store, setStore] = useState({});

    //const [productType, setProductType] = useState('unique');
    const [errors, setErrors] = useState({});

    const [dialogVariant, setDialogVariant] = useState({
        title: t('Add new variant'),
        open: false,
        parentSku: '',
        sku: ''
    });

    const [remove, setRemove] = useState({
        open: false,
        name: "",
        id: "",
    });

    const [dialogErrors, setDialogErrors] = useState({
        open: false,
        data: []
    })

    const [category, setCategory] = useState({});
    const [dialogCategory, setDialogCategory] = useState(false);

    const [newAdExpanded, setNewAdExpanded] = useState(true);
    const [categoryExpanded, setCategoryExpanded] = useState(true);
    const [adInfoExpanded, setAdInfoExpanded] = useState(true);
    const [variantsExpanded, setVariantsExpanded] = useState(true);
    const [imagesExpanded, setImagesExpanded] = useState(true);
    const [attributesExpanded, setAttributesExpanded] = useState(true);
    //const [specificationsExpanded, setSpecificationsExpanded] = useState(true);

    const [openFileUpload, setOpenFileUpload] = useState(false);

    //controle das variantes
    const [checked, setChecked] = useState([]);
    const [products, setProducts] = useState([]);
    const [variants, setVariants] = useState([]);
    const [attributeMappings, setAttributeMappings] = useState([]);
    const [dialogEditVariant, setDialogEditVariant] = useState({
        open: false,
        data: {
            imagesToUpload: []
        },
        canEdit: auth.user.accessControl["ads-Edit"]
    });
    const [variantErrors, setVariantErrors] = useState([]);

    const productsChecked = intersection(checked, products);
    const variantsChecked = intersection(checked, variants);

    const handleToggle = (product) => () => {
        const currentIndex = checked.indexOf(product);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(product);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (products) => intersection(checked, products).length;

    const handleToggleAll = (products) => () => {
        if (numberOfChecked(products) === products.length) {
            setChecked(not(checked, products));
        } else {
            setChecked(union(checked, products));
        }
    };

    const handleCheckedRight = () => {
        setVariants(variants.concat(productsChecked));
        setProducts(not(products, productsChecked));
        setChecked(not(checked, productsChecked));
    };

    const handleCheckedLeft = () => {
        setProducts(products.concat(variantsChecked));
        setVariants(not(variants, variantsChecked));
        setChecked(not(checked, variantsChecked));
    };

    const retrieveCategory = useCallback((parentId, marketplace, category) => {
        if (marketplace && category) {
            CategoryModel.findMarketplaceById(marketplace, category).then(result => {
                if (!parentId && result.allowVariations === true) {
                    setAd(ad => ({ ...ad, variations: [] }))
                } else {
                    //setAd(ad => ({ ...ad, variations: null }))
                }

                setCategory(result);
            }).catch(error => {
                console.log(error);
            })
        }
    }, [CategoryModel])

    const retrieveAttributeMappings = useCallback((marketplace, category, updateAd) => {
        if (marketplace && category) {
            AttributteMappingModel.read(marketplace, category).then(result => {
                for (let nX = 0; nX < result.length; nX++) {
                    const mapping = result[nX];
                    let value = '';
                    
                    if(!updateAd.attributes){
                        updateAd.attributes = [];
                    }

                    if (updateAd.attributes?.find(x => x.key === mapping.attribute)?.value) {
                        value = updateAd.attributes.find(x => x.key === mapping.attribute)?.value
                    } else if (updateAd[mapping.attribute]) {
                        value = updateAd[mapping.attribute];
                    } else if (updateAd.variations[0][mapping.attribute]) {
                        value = updateAd.variations[0][mapping.attribute]
                    }

                    if (mapping.attribute !== '') {
                        updateAd.attributes.push({
                            key: mapping.marketplaceAttribute,
                            value: value
                        })
                    }
                }
                setAttributeMappings(result);
                console.log(updateAd);
                setAd(updateAd);
            }).catch(error => {
                console.log(error);
            })
        }
    }, [AttributteMappingModel])

    const retrieveStore = useCallback((storeId) => {
        StoreModel.retrieve(storeId).then(result => {
            setStore(result);
        }).catch(error => {
            setLoading(false);

            let prim = true;
            let message = '';

            Object.values(error.errors).forEach(field => {
                message += (!prim ? ' | ' : '') + field.message;
                prim = false;
            });

            setResponseStatus({
                open: true,
                severity: "error",
                message: message
            });
        })
    }, [StoreModel])

    const retrieveAd = useCallback((parentId, marketplace, queryString) => {
        AdModel.retrieve(parentId, marketplace, queryString).then(async result => {
            result.imagesToUpload = [];
            result.images = result.adImages?.length > 0 ? result.adImages : result.images;

            if (result.variations) {
                for (let index = 0; index < result.variations.length; index++) {
                    result.variations[index].images = result.variations[index].adImages?.length > 0 ? result.variations[index].adImages : result.variations[index].images;
                }
            }

            await retrieveCategory(parentId, result.marketplace, result.category);
            await retrieveStore(storeId);
            await setAd(result);
            await retrieveAttributeMappings(result.marketplace, result.category, result);
            setLoading(false);
        }).catch(error => {
            setLoading(false);

            let prim = true;
            let message = '';

            Object.values(error.errors).forEach(field => {
                message += (!prim ? ' | ' : '') + field.message;
                prim = false;
            });

            setResponseStatus({
                open: true,
                severity: "error",
                message: message
            });
        })
    }, [storeId, AdModel, retrieveCategory, retrieveStore,retrieveAttributeMappings])

    useEffect(() => {
        if (parentId) {
            auth.appTitle(t('Edit Ad'));
            retrieveAd(parentId, marketplace, queryString);
        } else {
            auth.appTitle(t('New Ad'));
            setAd({
                active: true,
                marketplaceStatus: 1,
                category: '',
                images: [],
                crossdocking: 0,
                specifications: {},
                productDescription: '',
                parent: '',
                productTitle: '',
                price: 0,
                salePrice: '',
                saleDateStart: null,
                saleDateEnd: null,
                sku: '',
                ean: '',
                brand: '',
                title: '',
                description: '',
                relatedProductId: '',
                stock: 0,
                stockBooking: 0,
                width: 0,
                height: 0,
                length: 0,
                weight: 0,
                updatedInfo: true,
                updatedStock: true,
                updatedPrice: true,
                updatedStatus: true,
                attributes: [],
                imagesToUpload: [],
                thumbnail: '',
                sellerId: '',
                sellerName: '',
                listingTypeId: '',
                customId: '',
                isKit: 'N'
            })
            setLoading(false);
        }

    }, [t, retrieveAd, parentId, marketplace, queryString, auth]);

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })

        window.location.href = '/ads';
    }

    const handleSelectCategory = async (category) => {
        const updateAd = { ...ad };
        updateAd.category = category.id;
        await retrieveCategory(null, updateAd.marketplace, updateAd.category);
        await retrieveAttributeMappings(updateAd.marketplace, updateAd.category, updateAd);
    }

    const variantsValidation = () => {
        if (ad.variations) {
            setVariantErrors([]);
            const updateVariantErrors = [];

            for (let index = 0; index < ad.variations.length; index++) {
                const variant = ad.variations[index];

                if (variant.attributes.length <= 0) {
                    updateVariantErrors.push({
                        sku: variant.sku,
                        message: t('Attributes are mandatory for ads with variants')
                    });
                }
            }

            setVariantErrors(updateVariantErrors);
        }
    }

    const handleChangeField = (event) => {
        const updateAd = { ...ad };

        if (event.target.name.includes("[")) {
            const prop = event.target.name.split('[')[0];
            const index = parseInt(event.target.name.split('[')[1].substring(0, 1));
            const attribute = event.target.name.split('.')[1];

            if (prop && index >= 0 && attribute) {
                if (typeof (updateAd[prop][index]) === 'undefined') updateAd[prop][index] = {};
                updateAd[prop][index][attribute] = event.target.dataset?.value ? event.target.dataset?.value : event.target.value;
            }
        } else {
            updateAd[event.target.name] = event.target.dataset?.value ? event.target.dataset?.value : event.target.value;
        }

        setAd(updateAd);
    }

    const handleChangeMarketplace = (event, marketplace) => {
        if (marketplace) {
            const updateAd = { ...ad };
            updateAd.marketplace = marketplace.marketplace;
            setAd(updateAd);
        }
    }

    const handleChangeSeller = (event, seller) => {
        if (seller) {
            const updateAd = { ...ad };
            updateAd.sellerId = seller.credentials.sellerId;
            updateAd.sellerName = seller.credentials.sellerName;
            updateAd.listingTypeId = 'gold_special';
            setAd(updateAd);
        }
    }

    const handleChangeProduct = (event, product) => {
        if (product) {
            const updateAd = { ...ad };

            updateAd.active = product.active;
            updateAd.marketplaceStatus = 1;
            updateAd.stock = product.stock;
            updateAd.images = product.images;
            updateAd.crossdocking = product.crossdocking;
            updateAd.updatedInfo = true;
            updateAd.updatedStock = true;
            updateAd.updatedPrice = true;
            updateAd.updatedStatus = true;
            updateAd.specifications = product.specifications
            updateAd.productDescription = product.description;
            updateAd.parent = product.sku;
            updateAd.productTitle = product.title;
            updateAd.price = product.price;
            updateAd.salePrice = product.salePrice > 0 ? product.salePrice : '';
            updateAd.saleDateStart = product.saleDateStart;
            updateAd.saleDateEnd = product.saleDateEnd;
            updateAd.sku = product.sku;
            updateAd.ean = product.ean;
            updateAd.brand = product.brand;
            updateAd.title = product.title;
            updateAd.width = product.width;
            updateAd.height = product.height;
            updateAd.length = product.length;
            updateAd.weight = product.weight;
            updateAd.attributes = product.attributes;
            updateAd.description = product.description;
            updateAd.relatedProductId = product.id;
            updateAd.stockBooking = product.stockBooking;
            updateAd.thumbnail = product.thumbnail;
            updateAd.imagesToUpload = [];
            updateAd.sellerId = ad.sellerId;
            updateAd.sellerName = ad.sellerName;
            updateAd.listingTypeId = ad.listingTypeId;
            updateAd.customId = product.customId;
            updateAd.isKit = product.isKit;
            updateAd.kitProducts = product.kitProducts;


            for (let nX = 0; nX < attributeMappings.length; nX++) {
                const mapping = attributeMappings[nX];
                let value = '';

                if (product.attributes.find(x => x.key === mapping.attribute)?.value) {
                    value = product.attributes.find(x => x.key === mapping.attribute)?.value
                } else if (product[mapping.attribute]) {
                    value = product[mapping.attribute];
                }

                if (mapping.attribute !== '') {
                    updateAd.attributes.push({
                        key: mapping.marketplaceAttribute,
                        value: value
                    })
                }
            }

            setAd(updateAd);
            variantsValidation();
        }
    }

    const handleChangeDateField = (date, field) => {
        const updateAd = { ...ad };
        updateAd[field] = date?.toISOString();
        setAd(updateAd);
    }

    const handleChangeAttribute = (event) => {
        const updateAd = { ...ad };
        const index = updateAd.attributes.findIndex(x => x.key === event.target.name);

        if (index > 0) {
            updateAd.attributes[index].value_name = event.target.value;
        } else {
            updateAd.attributes.push({
                key: event.target.name,
                value: event.target.value
            })
        }

        setAd(updateAd);
    }

    const formatAttributes = (attributes) => {
        const formattedAttributes = [];

        for (let index = 0; index < attributes.length; index++) {
            const attribute = attributes[index];

            formattedAttributes.push({
                label: attribute.name,
                value: attribute.name
            })
        }

        return formattedAttributes;
    }

    // const handleChangeSpecification = (event) => {
    //     const updateAd = { ...ad };
    //     updateAd.specifications[event.target.name] = event.target.value;
    //     setAd(updateAd);
    // }

    const handleUploadFiles = (newFiles) => {
        const updateAd = { ...ad };
        updateAd.imagesToUpload = updateAd.imagesToUpload.concat(newFiles);
        setAd(updateAd);
    }

    const handleDeleteFile = (file) => {
        const updateAd = { ...ad };
        updateAd.imagesToUpload.splice(updateAd.imagesToUpload.findIndex(x => x.file.name === file.file.name), 1);
        setAd(updateAd);
    }

    const handleClickDeleteImage = (event, variantIndex, imageIndex) => {
        const updateAd = { ...ad };
        updateAd.images.splice(imageIndex, 1);
        setAd(updateAd);
    }

    const handleChangeVariantField = (event) => {
        const updateVariant = { ...dialogEditVariant.data }
        updateVariant[event.target.name] = event.target.value;
        setDialogEditVariant({
            ...dialogEditVariant,
            data: updateVariant,
            canEdit: auth.user.accessControl["ads-Edit"]
        });
    }

    const handleChangeVariantDateField = (date, field) => {
        const updateVariant = { ...dialogEditVariant.data }
        updateVariant[field] = date.toISOString();
        setDialogEditVariant({
            ...dialogEditVariant,
            data: updateVariant,
            canEdit: auth.user.accessControl["ads-Edit"]
        });
    }

    const handleChangeVariantAttribute = (event) => {
        const updateVariant = { ...dialogEditVariant.data };
        const index = updateVariant.attributes.findIndex(x => x.key === event.target.name);

        if (index > 0) {
            updateVariant.attributes[index].value = event.target.value;
        } else {
            updateVariant.attributes.push({
                key: event.target.name,
                value: event.target.value
            })
        }

        setDialogEditVariant({
            ...dialogEditVariant,
            data: updateVariant,
            canEdit: auth.user.accessControl["ads-Edit"]
        });
    }

    const handleConfirmVariant = (event) => {
        const updateAd = { ...ad }
        updateAd.variations = [];

        for (let index = 0; index < variants.length; index++) {
            const variant = variants[index];

            const updateVariant = {
                active: variant.active,
                category: ad.category,
                ean: variant.ean,
                sku: variant.sku,
                title: variant.title,
                description: variant.description,
                thumbnail: variant.thumbnail,
                images: variant.images,
                attributes: [],
                width: variant.width,
                height: variant.height,
                length: variant.length,
                weight: variant.weight,
                stock: variant.stock,
                stockPercentage: variant.stockPercentage,
                crossdocking: variant.crossdocking,
                price: variant.price,
                salePrice: variant.salePrice > 0 ? variant.salePrice : '',
                saleDateStart: variant.saleDateStart,
                saleDateEnd: variant.saleDateEnd,
                markup: variant.markup,
                marketplaceStatus: ad.marketplaceStatus,
                specifications: ad.specifications,
                imagesToUpload: [],
                sellerId: ad.sellerId,
                sellerName: ad.sellerName,
                listingTypeId: ad.listingTypeId,
                customId: variant.customId
            }

            for (let index = 0; index < variant.attributes.length; index++) {
                const attribute = variant.attributes[index];
                updateVariant.attributes.push({
                    key: attribute.key,
                    value: attribute.value
                })
            }

            for (let nX = 0; nX < attributeMappings.length; nX++) {
                const mapping = attributeMappings[nX];
                let value = '';

                if (variant.attributes.find(x => x.key === mapping.attribute)?.value) {
                    value = variant.attributes.find(x => x.key === mapping.attribute)?.value
                } else if (variant[mapping.attribute]) {
                    value = variant[mapping.attribute]
                } else if (ad[mapping.attribute]) {
                    value = ad[mapping.attribute]
                }

                if (mapping.attribute !== '') {
                    updateVariant.attributes.push({
                        key: mapping.marketplaceAttribute,
                        value: value
                    })
                }
            }

            updateAd.variations.push(updateVariant);
        }

        setAd(updateAd);

        setDialogVariant({
            title: t('Add new variant'),
            open: false,
            parentSku: '',
            sku: ''
        })
    }

    const handleConfirmEditVariant = (event) => {
        const updateAd = { ...ad };
        updateAd.variations[dialogEditVariant.index] = dialogEditVariant.data;
        setAd(updateAd);
        setDialogEditVariant({
            open: false,
            data: {
                imagesToUpload: []
            },
            canEdit: auth.user.accessControl["ads-Edit"]
        })
        variantsValidation();
    }

    const fieldValidation = (event) => {
        const validation = event.target.dataset?.validation ? JSON.parse(event.target.dataset.validation) : null;
        const updatedErrors = { ...errors };
        delete updatedErrors[event.target.name];

        if (event.target.required && event.target.value.trim() === '') {
            updatedErrors[event.target.name] = t('Required field');
        }

        if (validation) {
            switch (validation.type) {
                case "pattern":
                    const match = new RegExp(validation.match);
                    if (!match.test(event.target.value)) updatedErrors[event.target.name] = validation.message;
                    break;

                case "minValue":
                    if (event.target.value.replace(/[.]/g, '').replace(/[,]/g, '.') < validation.match) updatedErrors[event.target.name] = validation.message;
                    break;

                case "maxValue":
                    if (event.target.value.replace(/[.]/g, '').replace(/[,]/g, '.') > validation.match) updatedErrors[event.target.name] = validation.message;
                    break;

                default:
                    break;
            }
        }

        setErrors(updatedErrors);
    }

    const handleChangeActive = (event, product, active) => {

    }

    const handleSaveAd = (event) => {
        const htmlFields = document.getElementsByTagName('input');
        let isValid = true;
        for (let index = 0; index < htmlFields.length; index++) {
            const field = htmlFields[index];
            if (!!field.required && (field.value === '' || field.value === null)) {
                isValid = false;
                field.focus();
                field.blur();
                break;
            }
        }

        if (isValid) saveAd();
    }

    const saveAd = async () => {
        setLoading(true);

        const updateAd = { ...ad };

        if (!parentId) {
            if (!ad.variations) {
                AdModel.create(updateAd).then(result => {
                    setLoading(false);
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: "Cadastro atualizado com sucesso"
                    });

                    window.location.href = '/ads/' + result.parent + '/' + result.marketplace;
                }).catch(error => {
                    setLoading(false);

                    let prim = true;
                    let message = '';

                    if (error.errors) {
                        Object.values(error.errors).forEach(field => {
                            message += (!prim ? ' | ' : '') + field.message;
                            prim = false;
                        });
                    } else {
                        message = JSON.stringify(error);
                    }

                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: message
                    });
                })
            } else {
                const createAds = [];
                const parent = moment().unix();

                for (let index = 0; index < ad.variations.length; index++) {
                    const variant = ad.variations[index];

                    const updateAd = {
                        active: variant.active,
                        parent: parent,
                        marketplace: ad.marketplace,
                        marketplaceStatus: 1,
                        category: ad.category,
                        stock: variant.stock,
                        stockPercentage: variant.stockPercentage,
                        images: variant.images,
                        crossdocking: variant.crossdocking,
                        updatedInfo: true,
                        updatedStock: true,
                        updatedPrice: true,
                        updatedStatus: true,
                        specifications: variant.specifications,
                        productTitle: ad.title,
                        productDescription: ad.description,
                        price: variant.price,
                        salePrice: variant.salePrice > 0 ? variant.salePrice : '',
                        saleDateStart: variant.saleDateStart,
                        saleDateEnd: variant.saleDateEnd,
                        markup: variant.markup,
                        sku: variant.sku,
                        ean: variant.ean,
                        brand: ad.brand,
                        title: variant.title,
                        width: variant.width,
                        height: variant.height,
                        length: variant.length,
                        weight: variant.weight,
                        attributes: variant.attributes,
                        description: variant.description,
                        relatedProductId: variant.id,
                        stockBooking: variant.stockBooking,
                        thumbnail: variant.thumbnail,
                        imagesToUpload: variant.imagesToUpload,
                        sellerId: ad.sellerId,
                        sellerName: ad.sellerName,
                        listingTypeId: ad.listingTypeId,
                        customId: variant.customId
                    }

                    createAds.push(AdModel.create(updateAd));
                }

                Promise.all(createAds).then(result => {
                    setLoading(false);
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: "Cadastro atualizado com sucesso"
                    });

                    window.location.href = '/ads/' + result[0].parent + '/' + result[0].marketplace + '?variants=true';
                }).catch(error => {
                    console.log(error);
                    setLoading(false);

                    let prim = true;
                    let message = '';

                    if (typeof (error) === 'object') {
                        Object.values(error.errors).forEach(field => {
                            message += (!prim ? ' | ' : '') + field.message;
                            prim = false;
                        });
                    } else {
                        message = error;
                    }

                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: message
                    });
                });
            }
        } else {
            if (!ad.variations) {

                if (updateAd.marketplaceStatus === 9) {
                    updateAd.marketplaceStatus = 1;
                    updateAd.marketplaceErrors = [];
                }

                AdModel.update(ad._id, updateAd).then(result => {
                    result.imagesToUpload = [];
                    setAd(result);
                    setLoading(false);
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: t('Record updated successfully')
                    });
                }).catch(error => {
                    setLoading(false);

                    let prim = true;
                    let message = '';

                    if (typeof (error) === 'object') {
                        Object.values(error.errors).forEach(field => {
                            message += (!prim ? ' | ' : '') + field.message;
                            prim = false;
                        });
                    } else {
                        message = error;
                    }

                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: message
                    });
                })
            } else {
                const updateAds = [];

                for (let index = 0; index < ad.variations.length; index++) {
                    const variant = ad.variations[index];

                    const updateAd = {
                        active: variant.active,
                        parent: variant.parent,
                        marketplace: ad.marketplace,
                        marketplaceStatus: ad.marketplaceStatus === 9 ? 1 : ad.marketplaceStatus,
                        category: ad.category,
                        stock: variant.stock,
                        stockPercentage: variant.stockPercentage,
                        images: variant.images,
                        crossdocking: variant.crossdocking,
                        updatedInfo: true,
                        updatedStock: true,
                        updatedPrice: true,
                        updatedStatus: true,
                        specifications: variant.specifications,
                        productTitle: ad.productTitle,
                        productDescription: ad.productDescription,
                        price: variant.price,
                        salePrice: variant.salePrice > 0 ? variant.salePrice : '',
                        saleDateStart: variant.saleDateStart,
                        saleDateEnd: variant.saleDateEnd,
                        markup: variant.markup,
                        sku: variant.sku,
                        ean: variant.ean,
                        brand: ad.brand,
                        title: variant.title,
                        width: variant.width,
                        height: variant.height,
                        length: variant.length,
                        weight: variant.weight,
                        attributes: variant.attributes,
                        description: variant.description,
                        relatedProductId: variant.id,
                        stockBooking: variant.stockBooking,
                        thumbnail: variant.thumbnail,
                        imagesToUpload: variant.imagesToUpload,
                        sellerId: ad.sellerId,
                        sellerName: ad.sellerName,
                        listingTypeId: ad.listingTypeId,
                        customId: variant.customId
                    }

                    updateAds.push(AdModel.update(variant._id, updateAd));
                }

                Promise.all(updateAds).then(result => {
                    setLoading(false);
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: "Cadastro atualizado com sucesso"
                    });

                    window.location.href = '/ads/' + result[0].parent + '/' + result[0].marketplace + '?variants=true';
                }).catch(error => {
                    console.log(error);
                    setLoading(false);

                    let prim = true;
                    let message = '';

                    if (error.errors) {
                        Object.values(error.errors).forEach(field => {
                            message += (!prim ? ' | ' : '') + field.message;
                            prim = false;
                        });
                    } else {
                        message = JSON.stringify(error);
                    }

                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: message
                    });
                });
            }
        }
    }

    const handleDeleteVariant = (event, index) => {
        const updateAd = { ...ad };
        const updateVariants = [...variants];
        updateVariants.splice(index, 1);
        updateAd.variations = updateVariants;
        setVariants(updateVariants);
        setAd(updateAd);
    }

    const handleDeleteAd = (event) => {
        AdModel.list(0, 1000, "&parent=" + remove.id).then(ads => {
            const errors = [];

            for (let index = 0; index < ads.docs.length; index++) {
                const ad = ads.docs[index];

                AdModel.delete(ad._id).catch(error => {
                    setRemove({
                        open: false,
                        name: "",
                        id: "",
                    });

                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: error.message
                    });

                    errors.push(error);
                });

                if (errors.length > 0) break;
            }

            if (errors.length <= 0) {
                setRemove({
                    open: false,
                    name: "",
                    id: "",
                });

                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: t('Ad removed successfully')
                });
            }
        }).catch(error => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        })
    };

    const columnsVariants = [
        {
            id: 'thumbnail',
            label: t('Image'),
            align: 'center',
            format: (thumbnail) => thumbnail ? <img src={thumbnail} alt="Miniatura" height={40} /> : <img src={process.env.PUBLIC_URL + '/no-image.png'} alt="Miniatura" height={40} />
        },
        {
            id: 'title',
            label: t('Title'),
            align: 'left',
            type: 'hybrid',
            format: (variant) => variantErrors.filter(x => x.sku === variant.sku).length > 0 && (variant.marketplaceStatus === 1 || variant.marketplaceStatus === 9) ? <Typography variant="body1" color="secondary"><b>{variant.title}</b></Typography> : variant.title
        },
        {
            id: 'dimensions',
            label: t('Dimensions'),
            align: 'center',
            type: 'hybrid',
            width: 130,
            format: (variant) => (
                <div>
                    {variant.height} x {variant.width} x {variant.length}<br />
                    <small>{t('(H x W x L)')}</small>
                </div>
            )
        },
        {
            id: 'weight',
            label: t('Weight') + ' (g)',
            align: 'right',
            width: 100
        },
        {
            id: 'active',
            label: t('Active'),
            align: 'center',
            type: 'hybrid',
            format: (variant) => variant.active === true ? (
                <Chip
                    size="small"
                    color="primary"
                    icon={<DoneIcon />}
                    label={t("Yes")}
                    clickable={auth.user.accessControl["ads-Edit"] === true}
                    onClick={e => auth.user.accessControl["ads-Edit"] === true && handleChangeActive(e, variant, false)}
                />
            ) : (
                <Chip
                    size="small"
                    color="secondary"
                    icon={<CloseIcon />}
                    label={t("No")}
                    clickable={auth.user.accessControl["ads-Edit"] === true}
                    onClick={e => auth.user.accessControl["ads-Edit"] === true && handleChangeActive(e, variant, true)}
                />
            )
        },
        {
            id: 'sku',
            label: t('SKU'),
            align: 'center',
            type: 'hybrid',
            format: (variant) => <div>{variant.sku}<br /><small>{variant.marketplaceVariantId}</small></div>
        },
        {
            id: 'stock',
            label: t('Stock'),
            align: 'right',
            type: 'hybrid',
            format: (variant) => Math.ceil(variant.stock * ((variant.stockPercentage || 100) / 100))
        },
        {
            id: 'price',
            label: t('Price'),
            align: 'right',
            type: 'hybrid',
            format: (variant) =>
                <>
                    {moment(variant.saleDateStart) < moment() && moment(variant.saleDateEnd) > moment() && variant.salePrice !== '' && variant.salePrice !== null ?
                        <div>
                            <span style={{ textDecoration: 'line-through' }}>{Price(variant, false)}</span><br />
                            {Price(variant)}
                        </div>
                        :
                        <div>{Price(variant, false)}<br /></div>
                    }
                </>
        },
        {
            id: 'marketplaceStatus',
            label: t('Sync'),
            align: 'center',
            type: 'hybrid',
            format: (ad) => ad.marketplaceStatus !== 9 ? <AdStatus status={ad.marketplaceStatus} /> : <AdStatus status={ad.marketplaceStatus} onClick={() => setDialogErrors({
                open: true,
                data: ad.marketplaceErrors
            })} />
        },
        {
            id: 'id',
            label: t('Actions'),
            align: 'center',
            type: 'hybrid',
            width: 90,
            format: (variant, index) => (
                <div>
                    <IconButton
                        size="small"
                        title={auth.user.accessControl["ads-Edit"] === true ? t('Edit') : t('View')}
                        aria-label={t('Edit')}
                        color="primary"
                        onClick={() => setDialogEditVariant({
                            open: true,
                            data: { ...variant, category: category },
                            index: index,
                            canEdit: auth.user.accessControl["ads-Edit"]
                        })}>
                        {auth.user.accessControl["ads-Edit"] === true && <EditIcon fontSize="small" />}
                        {auth.user.accessControl["ads-Edit"] === false && <ViewIcon fontSize="small" />}
                    </IconButton>
                    {(variant.marketplaceStatus === 1 || variant.marketplaceStatus === 9) &&
                        <IconButton size="small" title={t('Delfete')} aria-label={t('Delete')} onClick={e => handleDeleteVariant(e, index)} color="secondary">
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    }
                </div>
            )
        }
    ]

    const Price = (ad, calcSalePrice = true) => {
        const now = moment();
        const saleDateStart = moment(ad.saleDateStart);
        const saleDateEnd = moment(ad.saleDateEnd);
        const marketplaceSettings = store?.marketplaceSettings?.find(x => x.marketplace === ad.marketplace && x.credentials.sellerId === Number(ad.sellerId));

        let price = ad.price;

        if (calcSalePrice) {
            if (now >= saleDateStart && now <= saleDateEnd) {
                price = ad.salePrice;
            }
        }
        price = price * (1 + ((ad.markup || 0) / 100));

        if (marketplaceSettings) {
            price = price * (1 + ((marketplaceSettings.markup || 0) / 100));
        }

        return price.toLocaleString(localStorage.getItem('i18nextLng'), { style: 'currency', minimumFractionDigits: 2, currency: 'BRL' });
    }

    return (!loading ?
        <React.Fragment>
            <Accordion expanded={newAdExpanded}>
                <AccordionSummary
                    expandIcon={<ArrowDownIcon />}
                    onClick={() => setNewAdExpanded(!newAdExpanded)}
                    className={classes.textBold}
                >
                    {t('Ad')}
                </AccordionSummary>
                <AccordionDetails>
                    {!parentId &&
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={8}>
                                <MarketplaceSelect
                                    required
                                    id="marketplace"
                                    name="marketplace"
                                    label={t('Marketplace')}
                                    onChange={handleChangeMarketplace}
                                    error={!!errors?.marketplace}
                                    helperText={errors?.marketplace}
                                />
                            </Grid>
                            {ad.marketplace &&
                                <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <MarketplaceLogo marketplace={ad.marketplace} sellerName={ad.sellerName} listingType={ad.listingTypeId} />
                                </Grid>
                            }
                            {ad.marketplace === 7 &&
                                <Grid item xs={12} sm={8}>
                                    <SellerSelect
                                        required
                                        id="sellerId"
                                        name="sellerId"
                                        marketplace={ad.marketplace}
                                        label={t('Seller')}
                                        onChange={handleChangeSeller}
                                        error={!!errors?.sellerId}
                                        helperText={errors?.sellerId}
                                    />
                                </Grid>
                            }
                            {ad.marketplace === 7 && ad.sellerId &&
                                <Grid item xs={12} sm={8}>
                                    <Field
                                        type="select"
                                        options={[{ label: t('Gold Special'), value: 'gold_special' }, { label: t('Gold Pro'), value: 'gold_pro' }]}
                                        required
                                        id="listingTypeId"
                                        name="listingTypeId"
                                        label={t('Listing Type')}
                                        value={ad.listingTypeId}
                                        onChange={handleChangeField}
                                    />
                                </Grid>
                            }
                            {((ad.marketplace && ad.marketplace !== 7) || (ad.marketplace === 7 && ad.sellerId && ad.listingTypeId)) &&
                                <>
                                    <Grid item xs={12} sm={8}>
                                        <ProductSelect
                                            required
                                            id="product"
                                            name="product"
                                            label={t('Product')}
                                            onChange={handleChangeProduct}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    }
                    {parentId &&
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={10}>
                                <Typography variant="h6">
                                    {ad.productTitle}
                                </Typography>
                                <Typography variant="body1">
                                    {ad.marketplaceProductId}{ad.marketplaceProductId && ad.variations?.length > 1 ? ' - ' : ''}{ad.variations?.length > 1 ? t('Variants') + ': ' + ad.variations?.length : ''}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <MarketplaceLogo marketplace={ad.marketplace} sellerName={ad.sellerName} listingType={ad.listingTypeId} />
                            </Grid>
                            {!ad.variations &&
                                <>
                                    <Grid item xs={12} md={5}>
                                        <Typography variant="h6">
                                            {t('Stock')}: {Math.ceil(ad.stock * ((ad.stockPercentage || 100) / 100))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Typography variant="h6">
                                            {t('Price')}: {Price(ad)}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            {ad.variations &&
                                <Grid item xs={12} md={10}></Grid>
                            }
                            <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
                                {ad.marketplaceStatus !== 9 ? <AdStatus status={ad.marketplaceStatus} /> : <AdStatus status={ad.marketplaceStatus} onClick={() => setDialogErrors({
                                    open: true,
                                    data: ad.marketplaceErrors
                                })} />}
                            </Grid>
                        </Grid>
                    }
                </AccordionDetails>
            </Accordion>
            {ad.sku &&
                <>
                    <Accordion expanded={categoryExpanded}>
                        <AccordionSummary
                            expandIcon={<ArrowDownIcon />}
                            onClick={() => setCategoryExpanded(!categoryExpanded)}
                            className={classes.textBold}
                        >
                            {t('Category')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="primary">
                                        {category.path}
                                    </Typography>
                                </Grid>
                                {(ad.marketplaceStatus === 1 || ad.marketplaceStatus === 9) &&
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => setDialogCategory(true)}
                                        >
                                            {t('Edit')}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={adInfoExpanded}>
                        <AccordionSummary
                            expandIcon={<ArrowDownIcon />}
                            onClick={() => setAdInfoExpanded(!adInfoExpanded)}
                            className={classes.textBold}
                        >
                            {t('Product')}
                        </AccordionSummary>
                        <AccordionDetails>
                            {ad.variations &&
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Field
                                            type="text"
                                            required
                                            name="productTitle"
                                            label={t("Product Title")}
                                            value={ad.productTitle}
                                            onChange={handleChangeField}
                                            onBlur={fieldValidation}
                                            error={!!errors?.productTitle}
                                            helperText={errors?.productTitle}
                                            readOnly={ad.marketplaceStatus === 2}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            type="text"
                                            required
                                            name="productDescription"
                                            label={t("Product Description")}
                                            value={ad.productDescription}
                                            onChange={handleChangeField}
                                            multiline
                                            rows={10}
                                            readOnly={ad.marketplaceStatus === 2}
                                            error={!!errors?.productDescription}
                                            helperText={errors?.productDescription}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Field
                                            type="text"
                                            required
                                            name="brand"
                                            label={t("Brand")}
                                            value={ad.brand}
                                            onChange={handleChangeField}
                                            onBlur={fieldValidation}
                                            error={!!errors?.brand}
                                            helperText={errors?.brand}
                                            readOnly={ad.marketplaceStatus === 2}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {!ad.variations &&
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8}>
                                        <Field
                                            type="text"
                                            required
                                            name="sku"
                                            label={t("SKU")}
                                            value={ad.sku}
                                            onChange={handleChangeField}
                                            onBlur={fieldValidation}
                                            error={!!errors?.sku}
                                            helperText={errors?.sku}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={classes.active}>
                                        <FormControlLabel
                                            control={<Switch checked={ad.active} onChange={() => setAd({ ...ad, active: !ad.active })} name="active" />}
                                            label={t('Active')}
                                            disabled={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            type="text"
                                            required
                                            name="title"
                                            label={t("Title")}
                                            value={ad.title}
                                            onChange={handleChangeField}
                                            onBlur={fieldValidation}
                                            error={!!errors?.title}
                                            helperText={errors?.title}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            type="text"
                                            required
                                            name="description"
                                            label={t("Description")}
                                            value={ad.description}
                                            onChange={handleChangeField}
                                            multiline
                                            rows={10}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="text"
                                            required
                                            name="brand"
                                            label={t("Brand")}
                                            value={ad.brand}
                                            onChange={handleChangeField}
                                            onBlur={fieldValidation}
                                            error={!!errors?.brand}
                                            helperText={errors?.brand}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="text"
                                            required
                                            name="ean"
                                            label={t("EAN")}
                                            value={ad.ean}
                                            onChange={handleChangeField}
                                            onBlur={fieldValidation}
                                            error={!!errors?.ean}
                                            helperText={errors?.ean}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="number"
                                            format="integer"
                                            required
                                            name="stock"
                                            label={t("Stock")}
                                            value={ad.stock}
                                            onChange={handleChangeField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="number"
                                            format="integer"
                                            name="stockPercentage"
                                            label={t("Stock Percentage")}
                                            value={ad.stockPercentage}
                                            onChange={handleChangeField}
                                            onBlur={fieldValidation}
                                            error={!!errors?.stockPercentage}
                                            helperText={errors?.stockPercentage}
                                            inputProps={{
                                                "data-validation": JSON.stringify({
                                                    type: "maxValue",
                                                    match: 100,
                                                    message: t("Maximum value exceeded")
                                                })
                                            }}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="number"
                                            format="integer"
                                            required
                                            name="height"
                                            label={t("Height")}
                                            value={ad.height}
                                            onChange={handleChangeField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="number"
                                            format="integer"
                                            required
                                            name="width"
                                            label={t("Width")}
                                            value={ad.width}
                                            onChange={handleChangeField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="number"
                                            format="integer"
                                            required
                                            name="length"
                                            label={t("Length")}
                                            value={ad.length}
                                            onChange={handleChangeField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="number"
                                            format="integer"
                                            required
                                            name="weight"
                                            label={t("Weight")}
                                            value={ad.weight}
                                            onChange={handleChangeField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="number"
                                            format="float"
                                            required
                                            name="price"
                                            label={t("Price")}
                                            value={ad.price}
                                            onChange={handleChangeField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="number"
                                            format="float"
                                            name="salePrice"
                                            label={t("Sale Price")}
                                            value={ad.salePrice || ''}
                                            onChange={handleChangeField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="datetime"
                                            name="saleDateStart"
                                            label={t("Sale Start Date")}
                                            value={ad.saleDateStart}
                                            onChange={handleChangeDateField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="datetime"
                                            name="saleDateEnd"
                                            label={t("Sale End Date")}
                                            value={ad.saleDateEnd}
                                            onChange={handleChangeDateField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="number"
                                            format="float"
                                            name="markup"
                                            label={t("Markup")}
                                            value={ad.markup}
                                            onChange={handleChangeField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Field
                                            type="number"
                                            format="integer"
                                            name="crossdocking"
                                            label={t("Crossdocking Days")}
                                            value={ad.crossdocking}
                                            onChange={handleChangeField}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </AccordionDetails>
                    </Accordion>
                </>
            }
            {
                ad.sku && ad.variations &&
                <Accordion expanded={variantsExpanded}>
                    <AccordionSummary
                        expandIcon={<ArrowDownIcon />}
                        onClick={() => setVariantsExpanded(!variantsExpanded)}
                        className={classes.textBold}
                    >
                        {t('Variants')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <DataTable
                                    columns={columnsVariants}
                                    docs={ad.variations}
                                    page={1}
                                    size={1000}
                                    count={-1}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ul style={{ listStyle: 'disc' }}>
                                    <Typography variant="body1" color="secondary">
                                        {variantErrors.map(error => <li>{t(error.message)}</li>)}
                                    </Typography>
                                </ul>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ width: '100%', marginTop: '10px' }}
                                    onClick={() => setDialogVariant({
                                        title: t('Add new variant'),
                                        open: true,
                                        parentSku: ad.relatedProductId,
                                        sku: ad.sku
                                    })}
                                    disabled={parentId && (ad.marketplaceStatus !== 3 || ad.marketplaceStatus !== 2 || auth.user.accessControl["ads-Edit"] === false)}
                                >
                                    {t('Add new variant')}
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {
                ad.sku && !ad.variations &&
                <>
                    <Accordion expanded={imagesExpanded}>
                        <AccordionSummary
                            expandIcon={<ArrowDownIcon />}
                            onClick={() => setImagesExpanded(!imagesExpanded)}
                            className={classes.textBold}
                        >
                            {t('Images')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: '100%'
                            }}>
                                {ad.images?.length > 0 && ad.images?.map((image, index) => (
                                    <div key={index} style={{
                                        flex: '0 1 300px',
                                        margin: '5px',
                                        alignSelf: 'center'
                                    }}>
                                        <img src={image} alt={t('Image')} style={{ maxWidth: '100%', display: 'block' }} />
                                        {ad.marketplaceStatus !== 3 && ad.marketplaceStatus !== 2 && auth.user.accessControl["ads-Edit"] === true &&
                                            <IconButton
                                                size="small"
                                                style={{
                                                    position: 'relative',
                                                    top: '-30px',
                                                    right: '3px',
                                                    float: 'right',
                                                    backgroundColor: '#f53b57',
                                                    color: 'rgb(232 254 250)',
                                                    borderRadius: 0
                                                }}
                                                onClick={handleClickDeleteImage}
                                            ><DeleteIcon fontSize="small" /></IconButton>
                                        }
                                    </div>
                                ))}
                                {ad.imagesToUpload?.length > 0 && ad.imagesToUpload?.map((image, index) => (
                                    <div key={index} style={{
                                        flex: '0 1 300px',
                                        margin: '5px',
                                        alignSelf: 'center'
                                    }}>
                                        <img src={image.data} alt={t('Image')} style={{ maxWidth: '100%', display: 'block' }} />
                                    </div>
                                ))}
                                {ad.marketplaceStatus !== 3 && ad.marketplaceStatus !== 2 && auth.user.accessControl["ads-Edit"] === true &&
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ width: '100%', marginTop: '10px' }}
                                        onClick={() => setOpenFileUpload(true)}
                                    >
                                        {t('Add new image')}
                                    </Button>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    {/* <Accordion expanded={specificationsExpanded}>
                        <AccordionSummary
                            expandIcon={<ArrowDownIcon />}
                            onClick={() => setSpecificationsExpanded(!specificationsExpanded)}
                            className={classes.textBold}
                        >
                            {t('Specifications')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                {ad?.specifications && Object.keys(ad?.specifications).map(spec => (
                                    <Grid item xs={12} key={spec}>
                                        <Field
                                            type="text"
                                            multiline={ad.specifications[spec].length > 60}
                                            rows={5}
                                            name={spec}
                                            label={spec.charAt(0).toUpperCase() + spec.replace(/_/g, ' ').slice(1)}
                                            defaultValue={ad.specifications[spec]}
                                            onChange={handleChangeSpecification}
                                            onBlur={fieldValidation}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion> */}
                </>
            }
            {
                ad.sku &&
                <Accordion expanded={attributesExpanded}>
                    <AccordionSummary
                        expandIcon={<ArrowDownIcon />}
                        onClick={() => setAttributesExpanded(!attributesExpanded)}
                        className={classes.textBold}
                    >
                        {t('Attributes')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            {category && category.attributes && Array.isArray(category.attributes) && category.attributes.filter(x => !x.tags.variation_attribute && !x.tags?.read_only && typeof (x.tags.allow_variations) === 'undefined').map((attribute, index) => (
                                <Grid item xs={12} md={6} key={attribute.id}>
                                    {!attribute.values &&
                                        <Field
                                            type="text"
                                            required={!!attribute.tags?.required}
                                            name={attribute.id}
                                            label={t(attribute.name)}
                                            defaultValue={(ad.attributes && ad.attributes.find(x => x.key === attribute.id)?.value) || ''}
                                            onChange={handleChangeAttribute}
                                            onBlur={fieldValidation}
                                            error={!!errors[attribute.id]}
                                            helperText={errors[attribute.id]}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    }
                                    {attribute.values &&
                                        <Field
                                            type="select"
                                            required={!!attribute.tags?.required}
                                            name={attribute.id}
                                            label={t(attribute.name)}
                                            options={formatAttributes(attribute.values)}
                                            value={(ad.attributes && ad.attributes.find(x => x.key === attribute.id)?.value) || ''}
                                            onChange={handleChangeAttribute}
                                            onBlur={fieldValidation}
                                            error={!!errors[attribute.id]}
                                            helperText={errors[attribute.id]}
                                            readOnly={auth.user.accessControl["ads-Edit"] === false}
                                        />
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            <Box textAlign="center" mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    width={100}
                    mr={5}
                    onClick={handleSaveAd}
                    disabled={JSON.stringify(errors) !== '{}' || variantErrors.length > 0 || auth.user.accessControl["ads-Edit"] === false}
                >
                    {t('Save')}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    width={100}
                    onClick={() => {
                        setRemove({
                            open: true,
                            name: ad.title,
                            id: ad.parent
                        });
                    }}
                    disabled={ad.marketplaceStatus !== 2 && ad.marketplaceStatus !== 3 && auth.user.accessControl["ads-Delete"] === false}
                >
                    {t('Delete')}
                </Button>
            </Box>
            <DropzoneDialogBase
                acceptedFiles={['image/png', 'image/jpeg']}
                filesLimit={10}
                fileObjects={ad.imagesToUpload}
                submitButtonText={t('Ok')}
                cancelButtonText={t('Close')}
                dialogTitle={t('Import Images')}
                dropzoneText={t('Click or drag images')}
                maxFileSize={200000000}
                open={openFileUpload}
                onAdd={handleUploadFiles}
                onDelete={handleDeleteFile}
                onClose={() => {
                    setOpenFileUpload(false);
                }}
                onSave={() => {
                    setOpenFileUpload(false);
                }}
                showAlerts={['error']}
                showPreviews={false}
                showPreviewsInDropzone
                maxWidth="lg"
                fullWidth
                previewGridClasses={{
                    item: 'MuiGrid-grid-xs-12 MuiGrid-grid-md-4'
                }}
            />
            <NewVariant
                open={dialogVariant.open}
                title={dialogVariant.title}
                parentSku={dialogVariant.parentSku}
                sku={dialogVariant.sku}
                handleChangeField={handleChangeVariantField}
                handleToggle={handleToggle}
                handleToggleAll={handleToggleAll}
                handleCheckedLeft={handleCheckedLeft}
                handleCheckedRight={handleCheckedRight}
                numberOfChecked={numberOfChecked}
                setVariants={setVariants}
                setProducts={setProducts}
                setChecked={setProducts}
                productsChecked={productsChecked}
                variantsChecked={variantsChecked}
                products={products}
                variants={variants}
                checked={checked}
                confirm={handleConfirmVariant}
                cancel={() => setDialogVariant({
                    title: t('Add new variant'),
                    open: false,
                    parentSku: '',
                    sku: ''
                })}
                onClose={() => setDialogVariant({
                    title: t('Add new variant'),
                    open: false,
                    parentSku: '',
                    sku: ''
                })}

            />
            <EditVariant
                open={dialogEditVariant.open}
                data={dialogEditVariant.data}
                canEdit={dialogEditVariant.canEdit}
                handleChangeAttribute={handleChangeVariantAttribute}
                handleChangeField={handleChangeVariantField}
                handleChangeDateField={handleChangeVariantDateField}
                confirm={handleConfirmEditVariant}
                cancel={() => setDialogEditVariant({
                    open: false,
                    data: {
                        imagesToUpload: []
                    },
                    canEdit: auth.user.accessControl["ads-Edit"]
                })}
            />
            <Categories
                open={dialogCategory}
                onClose={() => setDialogCategory(false)}
                handleSelectCategory={handleSelectCategory}
                confirm={() => setDialogCategory(false)}
                cancel={() => setDialogCategory(false)}
                marketplace={ad.marketplace}
                selectedCategory={ad.category}
            />
            <ConfirmationDialog
                title={t('Are you sure to remove this ad?')}
                confirmTitle={t('Yes')}
                confirm={handleDeleteAd}
                cancelTitle={t('No')}
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
            >
                {remove.name}
            </ConfirmationDialog>
            <Errors
                open={dialogErrors.open}
                title={t('Sync errors')}
                onClose={() => setDialogErrors({
                    data: [],
                    open: false
                })}
                data={dialogErrors.data}
                confirm={() => setDialogErrors({
                    data: [],
                    open: false
                })}
            />
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={1000}
                onClose={handleCloseResponseStatus}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </React.Fragment >
        : <Loading open={loading} />
    );
}