import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import moment from 'moment';

import { useAuth } from '../../AuthContext';

import {
    Grid,
    Box,
    Snackbar,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Chip,
    ButtonGroup
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add'
import MinusIcon from '@material-ui/icons/Remove'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'

import {
    Alert
} from '@material-ui/lab'

import ConfirmationDialog from '../../components/ConfirmationDialog';
import Switch from '../../components/Switch';
import Field from '../../components/Field';
import Loading from '../../components/Loading';
import DataTable from '../../components/DataTable';

import {
    Button
} from '../../components/CustomUI';

import AddProducts from './AddProducts';
import DialogAttributes from './Attributes';

import ProductService from '../../services/Product';

const useStyles = makeStyles({
    textBold: {
        fontWeight: 'bold'
    },
    active: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
    }
});

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((product) => b.indexOf(product) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function EditProduct() {
    const classes = useStyles();
    const productId = useParams().id;
    const auth = useAuth();
    const { t } = useTranslation();
    const ProductModel = useMemo(() => new ProductService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const [loading, setLoading] = useState(true);
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const [product, setProduct] = useState({});
    const [errors, setErrors] = useState({});
    const [remove, setRemove] = useState({
        open: false,
        name: "",
        id: "",
    });

    const [dialogAttributes, setDialogAttributes] = useState({
        open: false,
        data: {}
    })

    const [productInfoExpanded, setProductInfoExpanded] = useState(true);
    const [productsExpanded, setProductsExpanded] = useState(true);
    const [imagesExpanded, setImagesExpanded] = useState(true);
    const [attributesExpanded, setAttributesExpanded] = useState(true);
    //const [specificationsExpanded, setSpecificationsExpanded] = useState(true);

    const [openFileUpload, setOpenFileUpload] = useState(false);

    const [dialogVariant, setDialogVariant] = useState({
        title: t('Add new product'),
        open: false,
        parentSku: '',
        sku: ''
    });

    //controle das variantes
    const [checked, setChecked] = useState([]);
    const [products, setProducts] = useState([]);
    const [variants, setVariants] = useState([]);

    const productsChecked = intersection(checked, products);
    const variantsChecked = intersection(checked, variants);

    const handleToggle = (product) => () => {
        const currentIndex = checked.indexOf(product);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(product);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (products) => intersection(checked, products).length;

    const handleToggleAll = (products) => () => {
        if (numberOfChecked(products) === products.length) {
            setChecked(not(checked, products));
        } else {
            setChecked(union(checked, products));
        }
    };

    const handleCheckedRight = () => {
        setVariants(variants.concat(productsChecked));
        setProducts(not(products, productsChecked));
        setChecked(not(checked, productsChecked));
    };

    const handleCheckedLeft = () => {
        setProducts(products.concat(variantsChecked));
        setVariants(not(variants, variantsChecked));
        setChecked(not(checked, variantsChecked));
    };

    const handleConfirmProducts = () => {
        const updateProduct = { ...product }
        updateProduct.kitProducts = [];

        if (!updateProduct.images) updateProduct.images = [];

        for (let index = 0; index < variants.length; index++) {
            const variant = variants[index];

            const updateVariant = {
                id: variant.id,
                active: variant.active,
                sku: variant.sku,
                title: variant.title,
                description: variant.description,
                thumbnail: variant.thumbnail,
                images: variant.images,
                attributes: variant.attributes,
                width: variant.width,
                height: variant.height,
                length: variant.length,
                weight: variant.weight,
                stock: variant.stock,
                stockPercentage: variant.stockPercentage,
                crossdocking: variant.crossdocking,
                price: variant.price,
                salePrice: variant.salePrice > 0 ? variant.salePrice : '',
                saleDateStart: variant.saleDateStart,
                saleDateEnd: variant.saleDateEnd,
                markup: variant.markup,
                imagesToUpload: [],
                customId: variant.customId,
                relatedProductId: variant.id,
                quantity: 1
            }

            updateProduct.images = updateProduct.images.concat(variant.images);
            updateProduct.kitProducts.push(updateVariant);
        }

        updateProduct.thumbnail = updateProduct.images[0];
        setProduct(updateProduct);

        setDialogVariant({
            title: t('Add new products'),
            open: false
        })
    }

    const retrieveProduct = useCallback((id) => {
        ProductModel.retrieve(id).then(async product => {
            const kitProducts = product.kitProducts ? [...product.kitProducts] : [];

            product.imagesToUpload = [];
            product.kitProducts = [];

            for (let index = 0; index < kitProducts.length; index++) {
                const productId = kitProducts[index].relatedProductId;
                await ProductModel.retrieve(productId).then(variant => product.kitProducts.push({ ...variant, id: variant._id, quantity: kitProducts[index].quantity })).catch(error => { console.log(error) });
            }

            setProduct(product);
            setLoading(false);
        })
    }, [ProductModel])

    useEffect(() => {
        auth.appTitle(t('Edit Product'));
        if (productId !== 'new') {
            retrieveProduct(productId);
        } else {
            setProduct({
                isKit: 'N',
                imagesToUpload: []
            })
            setLoading(false);
        }
    }, [t, retrieveProduct, productId, auth]);

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleChangeField = (event) => {
        const updateProduct = { ...product };

        if (event.target.name.includes("[")) {
            const prop = event.target.name.split('[')[0];
            const index = parseInt(event.target.name.split('[')[1].substring(0, 1));
            const attribute = event.target.name.split('.')[1];

            if (prop && index >= 0 && attribute) {
                if (typeof (updateProduct[prop][index]) === 'undefined') updateProduct[prop][index] = {};
                updateProduct[prop][index][attribute] = event.target.dataset?.value ? event.target.dataset?.value : event.target.value;
            }
        } else {
            updateProduct[event.target.name] = event.target.dataset?.value ? event.target.dataset?.value : event.target.value;
        }

        setProduct(updateProduct);
    }

    const handleChangeDateField = (date, field) => {
        const updateProduct = { ...product };
        updateProduct[field] = date?.toISOString();
        setProduct(updateProduct);
    }

    const handleChangeAttribute = (event, index) => {
        const updateProduct = { ...product };
        updateProduct.attributes[index].value = event.target.value;
        setProduct(updateProduct);
    }

    const handleDeleteAttribute = (event, index) => {
        const updateProduct = { ...product };
        updateProduct.attributes.splice(index, 1);
        setProduct(updateProduct);
    }

    // const handleChangeSpecification = (event) => {
    //     const updateProduct = { ...product };
    //     updateProduct.specifications[event.target.name] = event.target.value;
    //     setProduct(updateProduct);
    // }

    const handleUploadFiles = (newFiles) => {
        const updateProduct = { ...product };
        updateProduct.imagesToUpload = updateProduct.imagesToUpload.concat(newFiles);
        setProduct(updateProduct);
    }

    const handleDeleteFile = (file) => {
        const updateProduct = { ...product };
        updateProduct.imagesToUpload.splice(updateProduct.imagesToUpload.findIndex(x => x.file.name === file.file.name), 1);
        setProduct(updateProduct);
    }

    const handleClickDeleteImage = (event, imageIndex) => {
        const updateProduct = { ...product };
        updateProduct.images.splice(imageIndex, 1);
        setProduct(updateProduct);
    }

    const handleChangeAttributeField = (event) => {
        const updateAttribute = { ...dialogAttributes.data };
        updateAttribute[event.target.name] = event.target.value;
        setDialogAttributes({
            ...dialogAttributes,
            data: updateAttribute
        })
    }

    const handleConfirmAttributes = (event) => {
        const updateProduct = { ...product }

        if (!updateProduct.attributes) {
            updateProduct.attributes = [];
        }

        updateProduct.attributes.push(dialogAttributes.data);
        setProduct(updateProduct);
        setDialogAttributes({
            open: false,
            data: {}
        })
    }

    const fieldValidation = (event) => {
        const validation = event.target.dataset.validation ? JSON.parse(event.target.dataset.validation) : null;
        const updatedErrors = { ...errors };
        delete updatedErrors[event.target.name];

        if (event.target.required && event.target.value.trim() === '') {
            updatedErrors[event.target.name] = t('Required field');
        }

        if (validation) {
            switch (validation.type) {
                case "pattern":
                    const match = new RegExp(validation.match);
                    if (!match.test(event.target.value)) updatedErrors[event.target.name] = validation.message;
                    break;

                default:
                    break;
            }
        }

        setErrors(updatedErrors);
    }

    const handleSaveProduct = (event) => {
        const htmlFields = document.getElementsByTagName('input');
        let isValid = true;
        for (let index = 0; index < htmlFields.length; index++) {
            const field = htmlFields[index];
            if (!!field.required && (field.value === '' || field.value === null)) {
                isValid = false;
                field.focus();
                field.blur();
                break;
            }
        }

        if (isValid) saveProduct();
    }

    const handleDeleteKitProduct = (event, index) => {
        const updateProduct = { ...product };
        updateProduct.kitProducts.splice(index, 1);
        setProduct(updateProduct);
    }

    const saveProduct = () => {
        setLoading(true);

        const updateProduct = { ...product };

        if (productId === 'new') {
            ProductModel.create(updateProduct).then(result => {
                setLoading(false);
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                window.location.href = '/products';
            }).catch(error => {
                setLoading(false);

                let prim = true;
                let message = '';

                Object.values(error.errors).forEach(field => {
                    message += (!prim ? ' | ' : '') + field.message;
                    prim = false;
                });

                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: message
                });
            })
        } else {
            ProductModel.update(productId, updateProduct).then(result => {
                retrieveProduct(result._id);
                setLoading(false);
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: t('Record updated successfully')
                });
            }).catch(error => {
                setLoading(false);

                let prim = true;
                let message = '';

                Object.values(error?.errors).forEach(field => {
                    message += (!prim ? ' | ' : '') + field.message;
                    prim = false;
                });

                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: message
                });
            })
        }
    }

    const handleDeleteProduct = (event) => {
        ProductModel.delete(remove.id).then(() => {
            window.location.href = '/products';
        }).catch((error) => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        });
    };

    const handleChangeQuantity = (event, index, operator) => {
        const updateProduct = { ...product };

        if (operator === '+') {
            updateProduct.kitProducts[index].quantity += 1;
        } else {
            updateProduct.kitProducts[index].quantity -= 1;
        }

        setProduct(updateProduct);
    }

    const columnsProducts = [
        {
            id: 'thumbnail',
            label: t('Image'),
            align: 'center',
            format: (thumbnail) => thumbnail ? <img src={thumbnail} alt="Miniatura" height={40} /> : <img src={process.env.PUBLIC_URL + '/no-image.png'} alt="Miniatura" height={40} />
        },
        {
            id: 'title',
            label: t('Title'),
            align: 'left'
        },
        {
            id: 'dimensions',
            label: t('Dimensions'),
            align: 'center',
            type: 'hybrid',
            width: 130,
            format: (product) => (
                <div>
                    {product.height} x {product.width} x {product.length}<br />
                    <small>{t('(H x W x L)')}</small>
                </div>
            )
        },
        {
            id: 'weight',
            label: t('Weight') + ' (g)',
            align: 'right',
            width: 100
        },
        {
            id: 'active',
            label: t('Active'),
            align: 'center',
            type: 'hybrid',
            format: (product) => product.active === true ? (
                <Chip
                    size="small"
                    color="primary"
                    icon={<DoneIcon />}
                    label={t("Yes")}
                />
            ) : (
                <Chip
                    size="small"
                    color="secondary"
                    icon={<CloseIcon />}
                    label={t("No")}
                />
            )
        },
        {
            id: 'sku',
            label: t('SKU'),
            align: 'center'
        },
        {
            id: 'quantity',
            label: t('Quantity'),
            align: 'center',
            format: (quantity, index) =>
                <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                    <Button size="small" onClick={e => handleChangeQuantity(e, index, '-')}>
                        <MinusIcon style={{ fontSize: 13 }} />
                    </Button>
                    <Button style={{ fontSize: 13, color: '#000' }} >
                        {quantity}
                    </Button>
                    <Button size="small" onClick={e => handleChangeQuantity(e, index, '+')} >
                        <AddIcon style={{ fontSize: 13 }} />
                    </Button>
                </ButtonGroup>
        },
        {
            id: 'price',
            label: t('Price'),
            align: 'right',
            type: 'hybrid',
            format: (product) =>
                <>
                    {moment(product.saleDateStart) < moment() && moment(product.saleDateEnd) > moment() && product.salePrice !== '' && product.salePrice !== null ?
                        <div>
                            <span style={{ textDecoration: 'line-through' }}>{product.price?.toLocaleString(localStorage.getItem('i18nextLng'), { style: 'currency', minimumFractionDigits: 2, currency: 'BRL' })}</span><br />
                            {product.salePrice?.toLocaleString(localStorage.getItem('i18nextLng'), { style: 'currency', minimumFractionDigits: 2, currency: 'BRL' })}
                        </div>
                        :
                        <div>{product.price?.toLocaleString(localStorage.getItem('i18nextLng'), { style: 'currency', minimumFractionDigits: 2, currency: 'BRL' })}<br /></div>
                    }
                </>
        },
        {
            id: 'id',
            label: t('Actions'),
            align: 'center',
            type: 'hybrid',
            width: 90,
            format: (variant, index) => (
                <IconButton size="small" title={t('Delete')} aria-label={t('Delete')} onClick={e => handleDeleteKitProduct(e, index)} color="secondary">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            )
        }
    ]


    return (!loading ?
        <React.Fragment>
            <Accordion expanded={productInfoExpanded}>
                <AccordionSummary
                    expandIcon={<ArrowDownIcon />}
                    onClick={() => setProductInfoExpanded(!productInfoExpanded)}
                    className={classes.textBold}
                >
                    {t('Product Info')}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.active}>
                            <FormControlLabel
                                control={<Switch checked={product.active} onChange={() => setProduct({ ...product, active: !product.active })} name="active" />}
                                label={t('Active')}
                                disabled={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                required
                                name="sku"
                                label={t("SKU")}
                                defaultValue={product.sku}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.sku}
                                helperText={errors?.sku}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                required
                                name="customId"
                                label={t("ERP Id")}
                                defaultValue={product.customId}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.customId}
                                helperText={errors?.customId}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                type="text"
                                required
                                name="title"
                                label={t("Title")}
                                defaultValue={product.title}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.title}
                                helperText={errors?.title}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                type="text"
                                required
                                name="description"
                                label={t("Description")}
                                defaultValue={product.description}
                                onChange={handleChangeField}
                                multiline
                                rows={5}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Field
                                type="text"
                                required
                                name="brand"
                                label={t("Brand")}
                                defaultValue={product.brand}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.brand}
                                helperText={errors?.brand}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Field
                                type="text"
                                required
                                name="ean"
                                label={t("EAN")}
                                defaultValue={product.ean}
                                onChange={handleChangeField}
                                onBlur={fieldValidation}
                                error={!!errors?.ean}
                                helperText={errors?.ean}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Field
                                type="number"
                                format="integer"
                                required
                                name="stock"
                                label={t("Stock")}
                                defaultValue={product.stock}
                                onChange={handleChangeField}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                required
                                name="height"
                                label={t("Height")}
                                defaultValue={product.height}
                                onChange={handleChangeField}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                required
                                name="width"
                                label={t("Width")}
                                defaultValue={product.width}
                                onChange={handleChangeField}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                required
                                name="length"
                                label={t("Length")}
                                defaultValue={product.length}
                                onChange={handleChangeField}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="integer"
                                required
                                name="weight"
                                label={t("Weight")}
                                defaultValue={product.weight}
                                onChange={handleChangeField}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="float"
                                required
                                name="price"
                                label={t("Price")}
                                defaultValue={product.price}
                                onChange={handleChangeField}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="number"
                                format="float"
                                name="salePrice"
                                label={t("Sale Price")}
                                defaultValue={product.salePrice}
                                onChange={handleChangeField}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="datetime"
                                name="saleDateStart"
                                label={t("Sale Start Date")}
                                value={product.saleDateStart}
                                onChange={handleChangeDateField}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="datetime"
                                name="saleDateEnd"
                                label={t("Sale End Date")}
                                value={product.saleDateEnd}
                                onChange={handleChangeDateField}
                                readOnly={auth.user.accessControl["products-Edit"] === false}
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={6} lg={3}>
                            <Field
                                type="select"
                                options={[
                                    { label: t('Yes'), value: 'Y' },
                                    { label: t('No'), value: 'N' }
                                ]}
                                required
                                id="isKit"
                                name="isKit"
                                label={t('Kit')}
                                value={product.isKit}
                                onChange={handleChangeField}
                            />
                        </Grid> */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {product.isKit === 'Y' &&
                <Accordion expanded={productsExpanded}>
                    <AccordionSummary
                        expandIcon={<ArrowDownIcon />}
                        onClick={() => setProductsExpanded(!productsExpanded)}
                        className={classes.textBold}
                    >
                        {t('Products')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <DataTable
                                    columns={columnsProducts}
                                    docs={product.kitProducts}
                                    page={1}
                                    size={1000}
                                    count={-1}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ width: '100%', marginTop: '10px' }}
                                    onClick={() => setDialogVariant({
                                        title: t('Add new product'),
                                        open: true
                                    })}
                                >
                                    {t('Add new product')}
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            <Accordion expanded={imagesExpanded}>
                <AccordionSummary
                    expandIcon={<ArrowDownIcon />}
                    onClick={() => setImagesExpanded(!imagesExpanded)}
                    className={classes.textBold}
                >
                    {t('Images')}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%'
                    }}>
                        {product.images?.length > 0 && product.images?.map((image, index) => (
                            <div key={index} style={{
                                flex: '0 1 300px',
                                margin: '5px',
                                alignSelf: 'center'
                            }}>
                                <img src={image} alt={t('Image')} style={{ maxWidth: '100%', display: 'block' }} />
                                <IconButton
                                    size="small"
                                    style={{
                                        position: 'relative',
                                        top: '-30px',
                                        right: '3px',
                                        float: 'right',
                                        backgroundColor: '#f53b57',
                                        color: 'rgb(232 254 250)',
                                        borderRadius: 0
                                    }}
                                    onClick={e => handleClickDeleteImage(e, index)}
                                    disabled={auth.user.accessControl["products-Edit"] === false}
                                ><DeleteIcon fontSize="small" /></IconButton>
                            </div>
                        ))}
                        {product.imagesToUpload?.length > 0 && product.imagesToUpload?.map((image, index) => (
                            <div key={index} style={{
                                flex: '0 1 300px',
                                margin: '5px',
                                alignSelf: 'center'
                            }}>
                                <img src={image.data} alt={t('Image')} style={{ maxWidth: '100%', display: 'block' }} />
                            </div>
                        ))}

                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ width: '100%', marginTop: '10px' }}
                            onClick={() => setOpenFileUpload(true)}
                            disabled={auth.user.accessControl["products-Edit"] === false}
                        >
                            {t('Add new image')}
                        </Button>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={attributesExpanded}>
                <AccordionSummary
                    expandIcon={<ArrowDownIcon />}
                    onClick={() => setAttributesExpanded(!attributesExpanded)}
                    className={classes.textBold}
                >
                    {t('Attributes')}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        {product?.attributes?.map((attribute, index) => (
                            <Grid item xs={12} md={6} key={attribute.key}>
                                <Field
                                    type="text"
                                    name={attribute.key}
                                    label={t(attribute.key)}
                                    defaultValue={attribute.value}
                                    onChange={e => handleChangeAttribute(e, index)}
                                    onBlur={fieldValidation}
                                    InputProps={{
                                        endAdornment: <IconButton size="small" onClick={e => handleDeleteAttribute(e, index)} disabled={auth.user.accessControl["products-Edit"] === false}><DeleteIcon color="secondary" /></IconButton>
                                    }}
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ width: '100%', marginTop: '10px' }}
                                onClick={() => setDialogAttributes({
                                    open: true,
                                    data: {}
                                })}
                                disabled={auth.user.accessControl["products-Edit"] === false}
                            >
                                {t('Add new attribute')}
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            {/* <Accordion expanded={specificationsExpanded}>
                <AccordionSummary
                    expandIcon={<ArrowDownIcon />}
                    onClick={() => setSpecificationsExpanded(!specificationsExpanded)}
                    className={classes.textBold}
                >
                    {t('Specifications')}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        {product?.specifications && Object.keys(product?.specifications).map(spec => (
                            <Grid item xs={12} key={spec}>
                                <Field
                                    type="text"
                                    multiline={product.specifications[spec].length > 60}
                                    rows={5}
                                    name={spec}
                                    label={spec.charAt(0).toUpperCase() + spec.replace(/_/g, ' ').slice(1)}
                                    defaultValue={product.specifications[spec]}
                                    onChange={handleChangeSpecification}
                                    onBlur={fieldValidation}
                                    readOnly={auth.user.accessControl["products-Edit"] === false}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion> */}
            <Box textAlign="center" mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    width={100}
                    mr={5}
                    onClick={handleSaveProduct}
                    disabled={auth.user.accessControl["products-Edit"] === false}
                >
                    {t('Save')}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    width={100}
                    onClick={() => {
                        setRemove({
                            open: true,
                            name: product.title,
                            id: product._id
                        });
                    }}
                    disabled={auth.user.accessControl["products-Delete"] === false}
                >
                    {t('Delete')}
                </Button>
            </Box>
            <DropzoneDialogBase
                acceptedFiles={['image/png', 'image/jpeg']}
                filesLimit={10}
                fileObjects={product.imagesToUpload}
                submitButtonText={t('Ok')}
                cancelButtonText={t('Close')}
                dialogTitle={t('Import Images')}
                dropzoneText={t('Click or drag images')}
                maxFileSize={200000000}
                open={openFileUpload}
                onAdd={handleUploadFiles}
                onDelete={handleDeleteFile}
                onClose={() => {
                    setOpenFileUpload(false);
                }}
                onSave={() => {
                    setOpenFileUpload(false);
                }}
                showAlerts={['error']}
                showPreviews={false}
                showPreviewsInDropzone
                maxWidth="lg"
                fullWidth
                previewGridClasses={{
                    item: 'MuiGrid-grid-xs-12 MuiGrid-grid-md-4'
                }}
            />
            <DialogAttributes
                open={dialogAttributes.open}
                data={dialogAttributes.data}
                handleChangeField={handleChangeAttributeField}
                confirm={handleConfirmAttributes}
                cancel={() => setDialogAttributes({
                    open: false,
                    data: {}
                })}
            />
            <AddProducts
                open={dialogVariant.open}
                title={dialogVariant.title}
                parentSku={dialogVariant.parentSku}
                sku={dialogVariant.sku}
                handleToggle={handleToggle}
                handleToggleAll={handleToggleAll}
                handleCheckedLeft={handleCheckedLeft}
                handleCheckedRight={handleCheckedRight}
                numberOfChecked={numberOfChecked}
                setVariants={setVariants}
                setProducts={setProducts}
                setChecked={setProducts}
                productsChecked={productsChecked}
                variantsChecked={variantsChecked}
                products={products}
                variants={variants}
                checked={checked}
                confirm={handleConfirmProducts}
                cancel={() => setDialogVariant({
                    title: t('Add new variant'),
                    open: false,
                    parentSku: '',
                    sku: ''
                })}
                onClose={() => setDialogVariant({
                    title: t('Add new variant'),
                    open: false,
                    parentSku: '',
                    sku: ''
                })}

            />
            <ConfirmationDialog
                title={t('Are you sure to remove this product?')}
                confirmTitle={t('Yes')}
                confirm={handleDeleteProduct}
                cancelTitle={t('No')}
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
            >
                {remove.name}
            </ConfirmationDialog>
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={3000}
                onClose={handleCloseResponseStatus}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
        : <Loading open={loading} />
    );
};