import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext';

import {
    Button,
    Menu,
    Divider,
    ListItem,
    ListItemIcon
} from '@material-ui/core';

import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { MenuItem, Avatar } from './styles';

function UserMenu() {
    const { t } = useTranslation();
    const { user, logout } = useAuth();
    const [userMenu, setUserMenu] = useState(null);

    const handleOpenUserMenu = (event) => {
        setUserMenu(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setUserMenu(null);
    };

    const handleLogout = () => {
        setUserMenu(null);
        logout();
    }

    return (
        <>
            <Button aria-controls="userSettings" aria-haspopup="true" style={{ height: '100%' }} onClick={handleOpenUserMenu}>
                <Avatar alt={user?.name} src="/broken-image.jpg" />
            </Button>
            <Menu
                id="userMenu"
                anchorEl={userMenu}
                keepMounted
                open={Boolean(userMenu)}
                onClose={handleCloseUserMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                style={{ fontSize: '0.9rem' }}
            >
                <ListItem style={{ justifyContent: 'space-around' }} >
                    <Avatar alt={user?.fullname} src="/broken-image.jpg" />
                </ListItem>
                <ListItem style={{ justifyContent: 'space-around' }} >
                    {user?.name}
                </ListItem>

                <Divider style={{ margin: '10px' }} />
                <MenuItem onClick={() => { window.location.href = '/users/' + user._id} }>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    {t('Edit Profile')}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    {t('Logout')}
                </MenuItem>
            </Menu>
        </>
    )
}

export default UserMenu;