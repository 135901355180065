import React, { useState, useEffect, useMemo, Fragment } from 'react';

import {
    Grid,
    Snackbar,
    Paper,
    Card,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    CardContent,
    Typography,
    Divider,
    CircularProgress,
    TextField
} from '@material-ui/core'

import {
    Alert,
    TreeView,
    TreeItem
} from '@material-ui/lab'

import {
    Button
} from '../../components/CustomUI';

import Loading from '../../components/Loading';

import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import { MarketplaceSelect } from '../../components/DataSelect';

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext';

import AdService from '../../services/Ad';
import CategoryService from '../../services/Category';

export default function Categorization() {
    const auth = useAuth();
    const storeId = auth.user.stores[auth.user.selectedStore].storeId;
    const AdModel = useMemo(() => new AdService(storeId, auth.user.token), [storeId, auth]);
    const CategoryModel = useMemo(() => new CategoryService(storeId, auth.user.token), [storeId, auth]);
    const { t } = useTranslation();

    useEffect(() => {
        auth.appTitle(t('Categorization'));
    }, [t, auth]);

    const [adFilters, setAdFilters] = useState({
        marketplace: 0,
        query: '&categorized=false',
    });
    const [ads, setAds] = useState({
        isLoading: false,
        data: []
    });
    const [checkedAds, setCheckedAds] = React.useState([]);
    const [categoryFilters, setCategoryFilters] = useState({
        marketplace: 0,
        query: '',
    });
    const [categories, setCategories] = useState({
        isLoading: false,
        data: []
    });
    const [selectedCategory, setSelectedCategory] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const [loading, setLoading] = useState(false);

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleChangeMarketplaces = (event, marketplace) => {
        if (marketplace) {
            setAdFilters({
                marketplace: marketplace.marketplace,
                query: "&categorized=false&marketplace=" + marketplace.marketplace
            });
            setCategoryFilters({
                marketplace: marketplace.marketplace,
                query: "&marketplace=" + marketplace.marketplace
            });
        } else {
            setAdFilters({
                marketplace: 0,
                query: '&categorized=false',
            })
            setCategoryFilters({
                marketplace: 0,
                query: '',
            })
        }
    }

    const handleToggleAds = (value) => () => {
        if (value === 'all') {
            if (checkedAds.length > 0) {
                const newChecked = [];
                setCheckedAds(newChecked);
            } else {
                const newChecked = ads.data.map(x => x._id);
                setCheckedAds(newChecked);
            }
        } else {
            const currentIndex = checkedAds.indexOf(value);
            const newChecked = [...checkedAds];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            setCheckedAds(newChecked);
        }
    };

    const handleKeyUpAdSearch = (event) => {
        if (event.keyCode === 13) {
            let query = "&categorized=false&marketplace=" + adFilters.marketplace

            if (event.target.value) {
                query += "&$or[0][title][$regex]=" + event.target.value;
                query += "&$or[0][title][$options]=gi";
                query += "&$or[1][sku][$regex]=" + event.target.value;
                query += "&$or[1][sku][$options]=gi";
            }
            setAdFilters({
                ...adFilters,
                query: query
            })
        }
    }

    const handleKeyUpCategorySearch = (event) => {
        if (event.keyCode === 13) {
            const regex = new RegExp(event.target.value, 'gi');
            let nodeIds = categories.data.filter(x => x.name.match(regex));
            let newExpanded = [...expanded];

            for (let index = 0; index < nodeIds.length; index++) {
                const node = nodeIds[index];
                newExpanded.push(node.id);
                for (let nX = 0; nX < node.path_from_root.length; nX++) {
                    const nodeId = node.path_from_root[nX].id;
                    newExpanded.push(nodeId);
                }
            }

            setExpanded(newExpanded);
        }
    }

    const handleSelectedNode = (event, nodeId) => {
        let newCategories = { ...categories };
        let newExpanded = [...expanded];

        setSelectedCategory(false);

        let aux = newCategories.data.findIndex(x => x.id === nodeId);
        newCategories.data[aux].children_categories = categories.data.filter(x => x.parent === nodeId);

        if (newCategories.data[aux].children_categories.length > 0) {
            setCategories(newCategories);

            let nodeIndex = newExpanded.findIndex(x => x === nodeId);

            if (nodeIndex >= 0) {
                newExpanded.splice(nodeIndex, 1);
            } else {
                newExpanded.push(nodeId);
            }

            setExpanded(newExpanded);
        } else {
            let selectedNode = categories.data.find(x => x.id === nodeId);
            setSelectedCategory(selectedNode);
        }
    }

    const handleClickSaveCategory = async (event) => {
        const categoryId = selectedCategory.id;
        let error = false;

        setLoading(true);

        for (let index = 0; index < checkedAds.length; index++) {
            const ad = checkedAds[index];

            await AdModel.update(ad, { category: categoryId }).catch(err => {
                console.log(error);
            })
        }

        if (!error) {
            setResponseStatus({
                open: true,
                severity: "success",
                message: "Categorias vinculadas com sucesso"
            });
        } else {
            setResponseStatus({
                open: true,
                severity: "error",
                message: "Erro ao vincular categorias. Tente novamente."
            });
        }

        setAds({
            isLoading: true,
            data: []
        })

        if (adFilters.marketplace > 0) {
            AdModel.list(0, 30, adFilters.query).then(result => {
                setAds({
                    isLoading: false,
                    data: result.docs
                })
                setCheckedAds([]);
                setLoading(false);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    const renderTree = (category) => (
        <TreeItem key={category._id} nodeId={category.id} label={category.name}>
            {Array.isArray(category.children_categories) ? category.children_categories.map((category) => renderTree(category)) : ''}
        </TreeItem>
    );

    useEffect(() => {
        if (adFilters.marketplace > 0) {
            setAds({
                isLoading: true,
                data: []
            })
            AdModel.list(0, 30, adFilters.query).then(result => {
                setAds({
                    isLoading: false,
                    data: result.docs
                })
            }).catch(error => {
                console.log(error);
            });
        }
    }, [AdModel, adFilters])

    useEffect(() => {
        if (categoryFilters.marketplace > 0) {
            setCategories({
                isLoading: true,
                data: []
            })

            let categories = [];

            CategoryModel.list(0, 50000, "&marketplace=" + categoryFilters.marketplace).then(result => {
                for (let index = 0; index < result.docs.length; index++) {
                    const category = result.docs[index];
                    categories.push(category);
                }
                setCategories({
                    isLoading: false,
                    data: categories
                })
            }).catch(error => {
                console.log(error);
            });
        }
    }, [CategoryModel, categoryFilters])

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography align="center">
                                {t('Marketplace')}
                            </Typography>
                            <MarketplaceSelect onChange={handleChangeMarketplaces} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography align="center">
                                {t('Ads')}
                            </Typography>
                            <Paper square elevation={0}>
                                <ListItem dense>
                                    <ListItemIcon>
                                        <Checkbox edge="start" color="primary" onClick={handleToggleAds('all')} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <TextField
                                            placeholder="Pesquisar"
                                            size="small"
                                            fullWidth
                                            onKeyUp={handleKeyUpAdSearch}
                                        />
                                    </ListItemText>
                                </ListItem>
                            </Paper>
                            <List
                                style={{
                                    height: 'calc(100vh - 365px)',
                                    overflow: 'auto',
                                    paddingTop: '0px'
                                }}
                            >
                                {!ads.isLoading && ads.data.map((ad, index) => (
                                    <Fragment key={'ads-' + index}>
                                        <ListItem dense button onClick={handleToggleAds(ad._id)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    color="primary"
                                                    edge="start"
                                                    checked={checkedAds.indexOf(ad._id) !== -1}
                                                    tabIndex={-1}
                                                    inputProps={{ 'aria-labelledby': 'ad-checkbox-' + ad._id }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={ad.sku + ' - ' + ad.title} />
                                        </ListItem>
                                        <Divider />
                                    </Fragment>
                                ))}
                                {ads.isLoading && (
                                    <ListItem>
                                        <ListItemText style={{ textAlign: 'center', margin: '0 auto !important' }}>
                                            <CircularProgress />
                                        </ListItemText>
                                    </ListItem>
                                )}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography align="center">
                                {t('Categories')}
                            </Typography>
                            <Paper square elevation={0} style={{ padding: '11px', display: 'none' }}>
                                <TextField
                                    placeholder={t('Search')}
                                    size="small"
                                    fullWidth
                                    onKeyUp={handleKeyUpCategorySearch}
                                />
                            </Paper>
                            <TreeView
                                style={{
                                    height: 'calc(100vh - 316px)',
                                    overflow: 'auto',
                                    padding: '11px'
                                }}
                                defaultCollapseIcon={<FolderOpenIcon color="primary" />}
                                defaultEndIcon={<FolderIcon color="primary" />}
                                defaultExpandIcon={<FolderIcon color="primary" />}
                                onNodeSelect={handleSelectedNode}
                                expanded={expanded}
                            >
                                {!categories.isLoading && categories.data.filter(x => typeof (x.parent) === 'undefined').map((category, index) =>
                                    renderTree(category)
                                )}
                                {categories.isLoading && (
                                    <ListItem>
                                        <ListItemText style={{ textAlign: 'center', margin: '0 auto !important' }}>
                                            <CircularProgress />
                                        </ListItemText>
                                    </ListItem>
                                )}
                            </TreeView>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    {checkedAds.length > 0 && selectedCategory &&
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleClickSaveCategory}
                        >
                            Vincular categoria - {selectedCategory.name}
                        </Button>
                    }
                </Grid>
            </Grid>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <Loading open={loading} />
        </>
    );
}