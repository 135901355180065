import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

import {
    Grid,
    Box,
    Snackbar,
    Card,
    CardHeader,
    CardContent,
    Typography,
} from '@material-ui/core';

import {
    Button
} from '../../components/CustomUI';

import {
    Alert
} from '@material-ui/lab'

import ConfirmationDialog from '../../components/ConfirmationDialog';
import Loading from '../../components/Loading';
import DataTable from '../../components/DataTable';
import MarketplaceLogo from '../../components/MarketplaceLogo';
import { MarketplaceSelect, AttributeSelect } from '../../components/DataSelect';

import Categories from '../Ads/Categories';

import AttributeMappingService from '../../services/AttributeMapping';
import CategoryService from '../../services/Category';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
        '& .MuiCardHeader-title': {
            fontSize: '1rem',
            fontWeight: 'bold'
        }
    },
    cardAttributes: {
        padding: theme.spacing(1, 2),
        textAlign: 'center',
        backgroundColor: theme.palette.grey[200],
        '& .MuiCardHeader-title': {
            fontSize: '1rem',
            fontWeight: 'bold'
        }
    }
}));

export default function EditAttributeMapping() {
    const classes = useStyles();
    const auth = useAuth();
    const marketplaceId = useParams().marketplace;
    const categoryId = useParams().category;
    const storeId = auth.user.stores[auth.user.selectedStore].storeId;
    const AttributeMappingModel = useMemo(() => new AttributeMappingService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const CategoryModel = useMemo(() => new CategoryService(storeId, auth.user.token), [storeId, auth]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [dialogCategory, setDialogCategory] = useState(false);
    const [data, setData] = useState({
        mappings: []
    });
    const [category, setCategory] = useState(null);
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const [remove, setRemove] = useState({
        open: false,
        name: ''
    });

    const readCategory = useCallback((parentId, marketplace, category) => {
        if (marketplace && category) {
            CategoryModel.findMarketplaceById(marketplace, category).then(result => {
                setCategory(result);
                setData(data => {
                    return {
                        ...data,
                        category: result.id,
                        categoryName: result.name
                    }
                })
            }).catch(error => {
                console.log(error);
            })
        }
    }, [CategoryModel])


    const handleSaveMapping = () => {
        setLoading(true);
        const mappings = [];

        for (let index = 0; index < data.mappings.length; index++) {
            const attribute = data.mappings[index];
            const mapping = {
                _id: attribute.id,
                marketplace: data.marketplace,
                category: data.category,
                categoryName: data.categoryName,
                marketplaceAttribute: attribute.marketplaceAttribute,
                attributeTitle: attribute.attributeTitle,
                attribute: attribute.attribute
            }

            if (mapping._id) {
                mappings.push(mapping);
            } else {
                AttributeMappingModel.create(mapping).then(() => {
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: t('Attribute mapping successfully saved')
                    });
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: error.message ? error.message : JSON.stringify(error)
                    });
                    setLoading(false);
                })
            }

            if (mappings.length > 0) {
                AttributeMappingModel.update(mappings).then(() => {
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: t('Attribute mapping successfully saved')
                    });
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: error.message ? error.message : JSON.stringify(error)
                    });
                    setLoading(false);
                })
            }
        }
    }

    const handleDeleteMapping = () => {
        setLoading(true);
        setRemove({
            ...remove,
            open: false
        });
        const mappings = [];
        for (let index = 0; index < data.mappings.length; index++) {
            const attribute = data.mappings[index];
            mappings.push({
                marketplace: data.marketplace,
                category: data.category,
                categoryName: data.categoryName,
                _id: attribute.id,
                marketplaceAttribute: attribute.marketplaceAttribute,
                attributeTitle: attribute.title,
                attribute: attribute.attribute
            });
        }

        AttributeMappingModel.delete(mappings).then(() => {
            setResponseStatus({
                open: true,
                severity: "success",
                message: t('Attribute mapping successfully saved')
            });
            setLoading(false);
            window.location.href = '/attribute_mappings';
        }).catch(error => {
            console.log(error);
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message ? error.message : JSON.stringify(error)
            });
            setLoading(false);
            window.location.href = '/attribute_mappings';
        })
    }

    const handleChangeMarketplace = (event, marketplace) => {
        if (marketplace) {
            const updateData = { ...data };
            updateData.marketplace = marketplace.marketplace;
            setData(updateData);
        }
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success",
        });
    };

    const handleSelectCategory = (category) => {
        const updateData = { ...data };
        updateData.category = category.id;
        readCategory(null, updateData.marketplace, updateData.category);
        setData(updateData);
    }

    const handleChangeAttributeMapping = (event, attribute) => {
        const updateData = { ...data };
        const marketplaceAttribute = event.target.id.split('-')[0].replace('#', '');
        const index = updateData.mappings.findIndex(item => item.marketplaceAttribute === marketplaceAttribute);

        if (index >= 0) {
            updateData.mappings[index].attribute = attribute?.name || '';
            updateData.mappings[index].attributeTitle = attribute?.title || 'Sem mapeamento';
        } else {
            updateData.mappings.push(
                {
                    marketplaceAttribute: marketplaceAttribute,
                    attributeTitle: attribute?.title || 'Sem mapeamento',
                    attribute: attribute?.name || ''
                }
            );
        }
        setData(updateData);
    }

    const readMapping = useCallback((marketplace, category) => {
        setLoading(true);
        readCategory(null, marketplace, category)

        AttributeMappingModel.read(marketplace, category).then(result => {
            const mapping = {
                marketplace: marketplace,
                category: category,
                categoryName: result[0].categoryName,
                mappings: []
            }

            for (let index = 0; index < result.length; index++) {
                mapping.mappings.push({
                    id: result[index]._id,
                    marketplaceAttribute: result[index].marketplaceAttribute,
                    attributeTitle: result[index].attributeTitle,
                    attribute: result[index].attribute
                })
            }

            setData(mapping);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [AttributeMappingModel, readCategory])


    useEffect(() => {
        auth.appTitle(t('Attribute Mapping'));
        if (marketplaceId && categoryId) {
            readMapping(marketplaceId, categoryId);
        } else {
            setLoading(false);
        }
    }, [auth, t, readMapping, marketplaceId, categoryId]);

    const columns = [
        {
            id: 'id',
            label: t('ID'),
            align: 'left'
        },
        {
            id: 'name',
            label: t('Name'),
            align: 'left',
            type: 'hybrid',
            format: (attribute) => <><div>{attribute.name}</div><small>{attribute.hint}</small></>
        },
        {
            id: 'required',
            label: t('Required'),
            align: 'center',
            type: 'hybrid',
            format: (attribute) => attribute.tags.required === true ? t('Required') : ''
        },
        {
            id: 'attribute',
            label: t('Attribute'),
            align: 'left',
            type: 'hybrid',
            width: 300,
            format: (attribute) => (
                <AttributeSelect
                    required
                    id={attribute.id}
                    defaultValue={() => {
                        const item = data.mappings.find(item => item.marketplaceAttribute === attribute.id);
                        const res = {
                            title: 'Sem mapeamento',
                            name: ''
                        }

                        if (item) {
                            res.title = item.attributeTitle;
                            res.name = item.attribute;
                        }

                        return res;
                    }}
                    onChange={handleChangeAttributeMapping}
                />
            )
        }
    ];

    return (!loading ?
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            {!data.marketplace &&
                                <MarketplaceSelect
                                    required
                                    id="marketplace"
                                    name="marketplace"
                                    label={t('Marketplace')}
                                    onChange={handleChangeMarketplace}
                                />
                            }
                            {data.marketplace &&
                                <MarketplaceLogo align="left" marketplace={Number(data.marketplace)} />
                            }
                        </CardContent>
                    </Card>
                </Grid>
                {data.marketplace &&
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={t('Category')} className={classes.cardHeader} />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" color="primary">
                                            {category?.path}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => setDialogCategory(true)}
                                        >
                                            {t('Edit')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                {data.marketplace && category?.id &&
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={t('Attribute Mapping')} className={classes.cardAttributes} />
                            <CardContent style={{ padding: 0 }}>
                                <DataTable
                                    columns={columns}
                                    docs={category.attributes && category.attributes.filter(x => !x.tags?.read_only)}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                }
            </Grid>
            <Box textAlign="center" mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    width={100}
                    mr={5}
                    onClick={handleSaveMapping}
                    disabled={auth.user.accessControl["products-Edit"] === false}
                >
                    {t('Save')}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    width={100}
                    onClick={() => {
                        setRemove({
                            open: true
                        });
                    }}
                    disabled={auth.user.accessControl["products-Delete"] === false}
                >
                    {t('Delete')}
                </Button>
            </Box>
            <Categories
                open={dialogCategory}
                onClose={() => setDialogCategory(false)}
                handleSelectCategory={handleSelectCategory}
                confirm={() => setDialogCategory(false)}
                cancel={() => setDialogCategory(false)}
                marketplace={data.marketplace}
                selectedCategory={data.category}
            />
            <ConfirmationDialog
                title={t('Are you sure to remove this product?')}
                confirmTitle={t('Yes')}
                confirm={handleDeleteMapping}
                cancelTitle={t('No')}
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
            >
                {remove.name}
            </ConfirmationDialog>
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={3000}
                onClose={handleCloseResponseStatus}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
        : <Loading open={loading} />
    );
};