import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import {
    IconButton,
    Grid,
    Snackbar,
    Chip,
    Menu,
    MenuItem,
} from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles';

import {
    Alert,
} from '@material-ui/lab'

import {
    Button
} from '../../components/CustomUI';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import Loading from '../../components/Loading'
import Field from '../../components/Field';

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext';

import AddIcon from '@material-ui/icons/Add';
import UploadIcon from '@material-ui/icons/Publish';
import DownloadIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import ProductService from '../../services/Product';

export default function ProductList() {
    const theme = useTheme();
    const auth = useAuth();
    const ProductModel = useMemo(() => new ProductService(auth.user.stores[auth.user.selectedStore].storeId, auth.user.token), [auth]);
    const { t } = useTranslation();
    const [filters, setFilters] = useState({
        search: ''
    });
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: ''
    });
    const [remove, setRemove] = useState({
        open: false,
        name: "",
        id: "",
    });

    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success",
    });

    const [menu, setMenu] = useState(null);

    const [importDialog, setImportDialog] = useState({
        open: false,
        file: null
    });

    const productList = useCallback((page = 0, size = 30, filters = '') => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        ProductModel.list(page, size, filters).then(result => {
            setData({
                isLoading: false,
                error: null,
                docs: result.docs,
                page: result.page - 1,
                size: result.limit,
                count: result.totalDocs,
                filters: filters
            })
        })

    }, [ProductModel])

    useEffect(() => {
        auth.appTitle(t('Products'));
        productList(data.page, data.size, data.filters)
    }, [t, productList, data.page, data.size, data.filters, auth]);

    const handleKeyUpSearch = (event) => {
        setFilters({
            ...filters,
            search: event.target.value,
        });

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = "";

        if (filters.search) {
            qs += "&$or[0][title][$regex]=" + filters.search;
            qs += "&$or[0][title][$options]=gi";
            qs += "&$or[1][sku][$regex]=" + filters.search;
            qs += "&$or[1][sku][$options]=gi";
        }

        setData({
            ...data,
            filters: qs
        });
    }

    const handleDismissFilters = () => {
        setFilters({
            search: ''
        })
        setData({
            ...data,
            filters: ''
        });
    }

    const filterOptions = [
        {
            type: "text",
            id: "search",
            name: "search",
            label: t('Search by title or SKU'),
            defaultValue: filters.search,
            onKeyUp: handleKeyUpSearch,
            onBlur: (e) => {
                setFilters({ ...filters, search: e.target.value });
            }
        }
    ];

    const columns = [
        {
            id: 'thumbnail',
            label: t('Image'),
            align: 'center',
            format: (thumbnail) => thumbnail ? <img src={thumbnail} alt="Miniatura" height={40} /> : <img src={process.env.PUBLIC_URL + '/no-image.png'} alt="Miniatura" height={40} />
        },
        {
            id: 'title',
            label: t('Title'),
            align: 'left'
        },
        {
            id: 'sku',
            label: t('SKU'),
            align: 'center'
        },
        {
            id: 'stock',
            label: t('Stock'),
            align: 'right',
        },
        {
            id: 'price',
            label: t('Price'),
            align: 'right',
            type: 'hybrid',
            format: (product) =>
                <>
                    {product.price?.toLocaleString(localStorage.getItem('i18nextLng'), { minimumFractionDigits: 2 })}<br />
                    {product.saleDateStart < moment().toISOString() && product.saleDateEnd > moment().toISOString() &&
                        <small style={{ color: 'gray' }}>{product.salePrice?.toLocaleString(localStorage.getItem('i18nextLng'), { minimumFractionDigits: 2 })}</small>
                    }
                </>
        },
        {
            id: 'active',
            label: t('Active'),
            align: 'center',
            type: 'hybrid',
            format: (doc) => doc.active ? (
                <Chip
                    size="small"
                    color="primary"
                    icon={<DoneIcon />}
                    label={t("Yes")}
                    onClick={e => auth.user.accessControl["products-Edit"] === true && handleChangeActive(e, doc, false)}
                    clickable={auth.user.accessControl["products-Edit"] === true}
                />
            ) : (
                <Chip
                    size="small"
                    color="secondary"
                    icon={<CloseIcon />}
                    label={t("No")}
                    onClick={e => auth.user.accessControl["products-Edit"] === true && handleChangeActive(e, doc, true)}
                    clickable={auth.user.accessControl["products-Edit"] === true} />
            )
        },
        {
            id: 'id',
            label: t('Actions'),
            align: 'center',
            type: 'hybrid',
            format: (doc, index) => (
                <div style={{ minWidth: '100px' }}>
                    <IconButton size="small" title={auth.user.accessControl["products-Edit"] === true ? t('Edit') : t('View')} aria-label={t('Edit')} href={'/products/' + doc._id} color="primary">
                        {auth.user.accessControl["products-Edit"] === true && <EditIcon fontSize="small" />}
                        {auth.user.accessControl["products-Edit"] === false && <ViewIcon fontSize="small" />}
                    </IconButton>
                    <IconButton size="small" title={t('Delete')} aria-label={t('Delete')} onClick={e => handleClickDelete(e, doc._id, doc.title)} color="secondary" disabled={auth.user.accessControl["products-Delete"] === false}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            )
        }
    ];

    const handleChangeActive = (e, product, active) => {

        setData({
            ...data,
            isLoading: true
        });

        const updatedProduct = { ...product }
        updatedProduct.active = active;

        ProductModel.update(product.id, updatedProduct).then(result => {
            const updateData = { ...data };
            const index = updateData.docs.findIndex(x => x._id === result._id);
            updateData.docs[index].active = active;
            updateData.isLoading = false;
            setData(updateData);
        }).catch((error) => {
            setData({
                ...data,
                isLoading: false
            });
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        });
    }

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id,
        });
    };

    const handleDeleteProduct = (event) => {
        ProductModel.delete(remove.id).then(() => {
            setRemove({
                open: false,
                name: "",
                id: "",
            });
            setResponseStatus({
                open: true,
                severity: "success",
                message: t('Product removed successfully')
            });

            productList(data.page, data.size, data.filters)
        }).catch((error) => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        });
    };

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success",
        });
    };

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            page: 0,
            size: +event.target.value
        })
    }

    const handleOpenMenu = (event) => {
        setMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setMenu(null);
    }

    const handleImportProducts = () => {
        setMenu(null);
        setImportDialog({
            open: true
        })
    }

    const handleImportSubmit = () => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        ProductModel.import(importDialog.file).then(() => {
            window.location.reload();
        }).catch((error) => {
            setData(prevData => {
                return {
                    ...prevData,
                    isLoading: false
                }
            })

            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message
            });
        })
    }

    const handleImportFileChange = async (e) => {
        await setImportDialog({
            ...importDialog,
            file: e.target.files[0]
        })
    }

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={8} md={10} lg={11}>
                <FilterContainer
                    title={t('Filters')}
                    filters={filterOptions}
                    handleApplyFilters={handleApplyFilters}
                    handleDismissFilters={handleDismissFilters}
                />
            </Grid>
            <Grid item sm={4} md={2} lg={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{
                        height: '48px',
                        width: '48px'
                    }}
                    onClick={handleOpenMenu}
                    disabled={auth.user.accessControl["products-New"] === false}
                >
                    <MoreVertIcon />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={menu}
                    keepMounted
                    open={Boolean(menu)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={() => { window.location.href = '/products/new' }} style={{ color: theme.palette.primary.main }}>
                        <AddIcon color="primary" style={{ marginRight: '10px' }} /> {t('Add')}
                    </MenuItem>
                    <MenuItem onClick={handleImportProducts} style={{ color: theme.palette.success.main }}>
                        <UploadIcon color="success" style={{ marginRight: '10px' }} /> {t('Import')}
                    </MenuItem>
                    {/* <MenuItem onClick={() => { window.location.href = '/products/new' }} style={{ color: theme.palette.secondary.main }}>
                        <DownloadIcon color="secondary" style={{ marginRight: '10px' }} /> {t('Export')}
                    </MenuItem> */}
                </Menu>
            </Grid>
            {!isLoading && docs ?
                <Grid item xs={12}>
                    <DataTable
                        columns={columns}
                        docs={docs}
                        page={page}
                        size={size}
                        count={count}
                        error={error}
                        handleChangePage={handleChangePage}
                        handleChangeSize={handleChangeSize}
                    />
                </Grid>
                :
                <Loading open={isLoading} />
            }
            <ConfirmationDialog
                title={t('Are you sure to remove this product?')}
                confirmTitle={t('Yes')}
                confirm={handleDeleteProduct}
                cancelTitle={t('No')}
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false,
                    });
                }}
            >
                {remove.name}
            </ConfirmationDialog>
            <ConfirmationDialog
                title={t('Import products')}
                confirmTitle={t('Import')}
                confirm={handleImportSubmit}
                cancelTitle={t('Cancel')}
                cancel={() => {
                    setImportDialog({
                        open: false,
                        file: null
                    });
                }}
                open={importDialog.open}
                onClose={() => {
                    setImportDialog({
                        open: true,
                        file: null
                    });
                }}
            >
                <div style={{ width: '500px' }}>
                    <p>Importe os produtos de uma planilha de Excel</p>

                    <Button color="primary" variant="outlined" type="link" href={process.env.PUBLIC_URL + '/importacao-produtos-marketplaces.xlsx'}>
                        <DownloadIcon style={{ marginRight: '1rem'}}/>Baixar modelo de planilha
                    </Button>

                    <p>O arquivo deve ser salvo no formato excel (<code>.xlsx</code>)</p>
                    <br />
                    <Field
                        type="file"
                        required
                        name="filename"
                        label={t("Select file")}
                        onChange={handleImportFileChange}
                        readOnly={auth.user.accessControl["products-Edit"] === false}
                    />
                </div>
            </ConfirmationDialog>
            <Snackbar
                open={responseStatus.open}
                autoHideDuration={3000}
                onClose={handleCloseResponseStatus}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseResponseStatus}
                    severity={responseStatus.severity}
                >
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}

