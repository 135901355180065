import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsENUS from './i18n/en-us.json';
import translationsPTBR from './i18n/pt-br.json';

i18n
    .use(LanguageDetector)
    .init({
        fallbackLng: "en-US",
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },

        resources: {
            'en-US': {
                translations: translationsENUS
            },
            'pt-BR': {
                translations: translationsPTBR
            }
        },
        ns: ["translations"],
        defaultNS: "translations"
    });

export default i18n;