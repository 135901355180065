import React from 'react';
import {
    Backdrop
} from '@material-ui/core'

function Loading(props) {
    return (
        <Backdrop open={props.open} style={{
            zIndex: 987349872489,
            background: 'rgba(255,255,255,0.5)',
            display: 'flex',
            flexGrow: '1',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Compassio" />
            <img src={process.env.PUBLIC_URL + '/loading.gif'} alt="Carregando..." />
        </Backdrop>
    )
}

export default Loading;