import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid
} from '@material-ui/core';

import Field from '../../components/Field';

function EditInvoice(props) {
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});

    const fieldValidation = (event) => {
        const validation = event.target.dataset.validation ? JSON.parse(event.target.dataset.validation) : null;
        const updatedErrors = { ...errors };
        delete updatedErrors[event.target.name];

        if (event.target.required && event.target.value.trim() === '') {
            updatedErrors[event.target.name] = t('Required field');
        }

        if (validation) {
            switch (validation.type) {
                case "pattern":
                    const match = new RegExp(validation.match);
                    if (!match.test(event.target.value)) updatedErrors[event.target.name] = validation.message;
                    break;

                default:
                    break;
            }
        }

        setErrors(updatedErrors);
    }

    const handleSubmit = (event) => {
        const htmlFields = document.getElementsByTagName('input');
        let isValid = true;
        for (let index = 0; index < htmlFields.length; index++) {
            const field = htmlFields[index];
            if (!!field.required && (field.value === '' || field.value === null)) {
                isValid = false;
                field.focus();
                field.blur();
                break;
            }
        }

        if (isValid) props.confirm();
    }
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">{t('Invoice')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Field
                            type="text"
                            required
                            name="serial"
                            label={t("Serial")}
                            defaultValue={props.data?.serial}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.serial}
                            helperText={errors?.serial}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            type="text"
                            required
                            name="number"
                            label={t("Number")}
                            defaultValue={props.data?.number}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.number}
                            helperText={errors?.number}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            type="text"
                            required
                            name="key"
                            label={t("Key")}
                            defaultValue={props.data?.key}
                            onChange={props.handleChangeField}
                            onBlur={fieldValidation}
                            error={!!errors?.key}
                            helperText={errors?.key}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    {t('Confirm')}
                </Button>
                <Button onClick={props.cancel}>{t('Cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditInvoice;